<div class="content">
  <div class="message-wrapper">
    <div class="message">
      <div class="message-content">
        <span [ngStyle]="styleService.getStyleRule([data.styles.messageText,
          null,
          'heading3'
        ])">{{'You are about to redeem' | conditionalUppercase
        : styleService.getUppercaseValue(
          data.styles.messageText
        )}}</span>
        <span class="points"
          [ngStyle]="styleService.getStyleRule([data.styles.accentMessageText,null,'heading3'])">{{data.pointToReedem | number | conditionalUppercase
          : styleService.getUppercaseValue(
            data.styles.accentMessageText
          )}}</span>
        <span [ngStyle]="
        styleService.getStyleRule([
        data.styles.messageText
        ,
          null,
          'heading3'
        ])">{{'points for' | conditionalUppercase
        : styleService.getUppercaseValue(
          data.styles.messageText
        )}}</span>
        <span class="rate" [ngStyle]="
        styleService.getStyleRule([data.styles.accentMessageText,null,'heading3'])">
          {{data.redeemValue | currency:'USD':'symbol' | conditionalUppercase
          : styleService.getUppercaseValue(
            data.styles.accentMessageText
          )}}
        </span>
        <span [ngStyle]="
        styleService.getStyleRule([
        data.styles.messageText
        ,
          null,
          'heading3'
        ])">{{ data.conversionType | conditionalUppercase
        : styleService.getUppercaseValue(
          data.styles.messageText
        )}}</span>
      </div>
    </div>
  </div>

  <div class="control-block">
    <button class="button-confirm button" (click)="confirmRedemption()" [ngStyle]="
          styleService.getStyleRule(
          [data.styles.buttonConfirm.text],
          [
            data.styles.buttonConfirm.background,
            { color: data.mainTemplate.globalVariables.buttonsColor.primaryColor }
          ],
          [data.styles.buttonConfirm.border])">
      {{'Confirm' | conditionalUppercase
      : styleService.getUppercaseValue(
        data.styles.buttonConfirm.text
      )}}
    </button>
    <button class="button-cancel button" (click)="closeDialog()" [ngStyle]="
    styleService.getStyleRule(
    [data.styles.buttonCancel
    .text],
    [
      data.styles.buttonCancel
      .background,
      { color: data.mainTemplate.globalVariables.buttonsColor.secondaryColor }
    ],
    [data.styles.buttonCancel
    .border])">
      {{'Cancel' | conditionalUppercase
      : styleService.getUppercaseValue(
        data.styles.buttonCancel.text
      )}}
    </button>
  </div>
</div>