<ng-container *ngIf="mainTemplate">
  <div class="logout-timer" *ngIf="logoutTimerConfig?.numberOfSeconds"
    [ngStyle]="
          styleService.getStyleRule(null,[logoutTimerConfig?.configurations?.background],[logoutTimerConfig?.configurations?.border])"
    [ngStyle]="{'border-color': logoutTimerConfig?.configurations?.border?.color}">
    <h3 class="logout-value" [ngStyle]="
            styleService.getStyleRule([
            logoutTimerConfig?.configurations?.text,
              'heading3'
            ])
          ">{{numberOfSeconds$ | async}}</h3>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
      <circle [style.stroke]="logoutTimerConfig?.configurations?.timerBorderColor" [style.strokeDashoffset]="0" cx="16"
        cy="16" r="15.9155" class="progress-bar__progress 
       js-progress-bar" />
    </svg>
  </div>
  <button class="header-btn" (click)="logout()" *ngIf="mainTemplate.template.signOutButton" [ngStyle]="
      styleService.getStyleRule(
        undefined,
        [
          mainTemplate.template.signOutButton?.configurations?.background,
          mainTemplate.components.signOutButton?.configurations?.background,
          { color: mainTemplate.globalVariables.buttonsColor?.primaryColor }
        ],
        [
          mainTemplate.template.signOutButton?.configurations?.border,
          mainTemplate.components.signOutButton?.configurations?.border,
          mainTemplate.globalVariables.borders
        ]
      )
    ">
    <mat-icon aria-hidden="false"
      *ngIf="mainTemplate.template.signOutButton?.iconName && mainTemplate.template.signOutButton.iconName.length || mainTemplate.components?.signOutButton?.iconName && mainTemplate.components.signOutButton.iconName.length"
      [fontIcon]="mainTemplate.template.signOutButton?.iconName?.length ? mainTemplate.template.signOutButton.iconName : mainTemplate.components.signOutButton.iconName"
      [ngStyle]="
        styleService.getIconStyleRule(
          [
            mainTemplate.template?.signOutButton?.iconSize,
            mainTemplate.components?.signOutButton?.iconSize
          ],
          [
            mainTemplate.template?.signOutButton?.iconColor,
            mainTemplate.components?.signOutButton?.iconColor
          ]
        )
      "></mat-icon>
    <span [ngStyle]="
        styleService.getStyleRule([
          mainTemplate.template.signOutButton?.configurations?.text,
          mainTemplate.components.signOutButton?.configurations?.text,
          'heading3'
        ])
      ">
      {{
      mainTemplate.template?.signOutButton?.title
      | conditionalUppercase
      : styleService.getUppercaseValue(
      mainTemplate.template.signOutButton?.configurations?.text,
      mainTemplate.components.signOutButton?.configurations?.text
      )
      }}</span>
  </button>
</ng-container>