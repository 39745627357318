import { Directive, ElementRef, Input, ViewChild } from '@angular/core';

@Directive({
  selector: '[appInputStyles]'
})
export class InputStylesDirective {

  @ViewChild('input')
  public hcild: any;
  /**Values inputBox, inputText, inputPlaceholder,inputTitle */
  @Input('inputStyle')
  values = {
    inputBox: {
      backgroundColor: "",
      borderColor: "",
      borderRadius: "",
      borderWidth: ""
    },
    inputText: {
      fontColor: "",
      fontFamily: "",
      fontSize: "",
      fontWeight: "",
      isUpperCaseEnabled: "",
    },

    inputTitle: {
      fontColor: "",
      fontFamily: "",
      fontSize: "",
      fontWeight: "",
      isUpperCaseEnabled: "",
    }
  };

  public labelHtmlElement!: HTMLElement;
  public inputHtmlElement!: HTMLElement;
  public placeholderStyle: any = {};
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.mapMeasurement();
    this.labelHtmlElement = (this.elementRef.nativeElement as HTMLElement).getElementsByTagName('label')[0];
    this.inputHtmlElement = (this.elementRef.nativeElement as HTMLElement).getElementsByTagName('input')[0];
    if (this.inputHtmlElement) {
      this.placeholderStyle = window.getComputedStyle(this.inputHtmlElement, '::placeholder');
    }
    if (Object.keys(this.values).length) {
      this.applyStyles();
    }
    console.log(this.hcild)
  }

  private applyStyles() {
    const { inputBox, inputText, inputTitle } = this.values;
    if (this.inputHtmlElement?.style) {
      Object.keys(inputBox).forEach((key: any) => {
        this.inputHtmlElement.style[key] = (inputBox as any)[key];
      });
      this.applyFontStyles(inputText, this.inputHtmlElement);
    }
    if (this.labelHtmlElement?.style) {
      this.applyFontStyles(inputTitle, this.labelHtmlElement);
    }
  }

  private applyFontStyles(styles: any, obj: any) {
    Object.keys(styles).forEach((key: any) => {
      if (styles.isUpperCaseEnabled) {
        obj.innerHTML = obj.innerHTML.toUpperCase();
      }
      if (key === 'fontColor') {
        obj.style.color = styles[key];
      }
      if (obj.style.hasOwnProperty(key)) {
        obj.style[key] = (styles as any)[key];
      }
    });
  }

  private mapMeasurement() {
    const addDemensionUnit = (obj: any, key: string) => {
      if (key === 'fontSize' ||
        key === 'borderRadius' || key === 'borderWidth') {
        obj[key] = `${obj[key]}px`
      }
    }
    Object.keys(this.values).forEach(key => {
      Object.keys((this.values as any)[key]).forEach((valueKey: any) => {
        addDemensionUnit((this.values as any)[key], valueKey);
      })
    });
    console.log(this.values)
  }
}
