import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService, TemplateService } from '../services';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {

  constructor(private readonly authService: AuthService, private templateService: TemplateService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /**TODO: Remove when authorization will be fixed */
    const mockedToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjlHbW55RlBraGMzaE91UjIybXZTdmduTG83WSIsImtpZCI6IjlHbW55RlBraGMzaE91UjIybXZTdmduTG83WSJ9.eyJhdWQiOiJhcGk6Ly81YmQxN2RkOS0yNTgzLTRmYjctYjlmMS0wYzg1ZjMyNzg1NWYiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8wYjk3MjMxNS01YTcyLTRjNGQtOTg0Yy1kODFjMzE4YWU2YzcvIiwiaWF0IjoxNzAwMDY3MzY1LCJuYmYiOjE3MDAwNjczNjUsImV4cCI6MTcwMDA3MTI2NSwiYWlvIjoiRTJWZ1lMakI4MDlpV25lWHo0MjJGZXErUm51M0FnQT0iLCJhcHBpZCI6IjViZDE3ZGQ5LTI1ODMtNGZiNy1iOWYxLTBjODVmMzI3ODU1ZiIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzBiOTcyMzE1LTVhNzItNGM0ZC05ODRjLWQ4MWMzMThhZTZjNy8iLCJvaWQiOiI4NDYzY2U0OS04NTcyLTRkYmQtOGIzYS03NTkzZjk1MDM2Y2IiLCJyaCI6IjAuQVRjQUZTT1hDM0phVFV5WVROZ2NNWXJteDlsOTBWdURKYmRQdWZFTWhmTW5oVjgzQUFBLiIsInN1YiI6Ijg0NjNjZTQ5LTg1NzItNGRiZC04YjNhLTc1OTNmOTUwMzZjYiIsInRpZCI6IjBiOTcyMzE1LTVhNzItNGM0ZC05ODRjLWQ4MWMzMThhZTZjNyIsInV0aSI6Im5KbEtsMzFoTmstb2dNWFNaN1VTQVEiLCJ2ZXIiOiIxLjAifQ.QuvI4ODaA0EuqVQEpO794SrR3LoI2tMl3buNzZaFiKTYim49t1zvNPqr8WEKonPQpZlm54i5BkFb5VWg_BXkgll9baP2lfNYfv6rshP6QGzgAMgqmHozugoQNz0Fn3UK9qCG16Jn-Y0eGafjJ53vE5YSNlWjFud24rnstg_M0YbNGsWYgUX9GttZDcNmbJi0QHFHYadYVigu80sAUho0zh8J7m02S_N2M4oOEa5wK5kBuRFS_R-DcOys6JQXYnxtd9IuGP8Pd1KYHNFAVpAJXPHw4Cqc1uIhi1WLDwMLgZvUeGACKuADCHranI8WFXxmSYqaMDBXA-ZSEp795obEPQ';
    if (!!this.authService.kioskData?.access_token) {

      if (request.url.includes(this.templateService?.templateData?.advancedSettings?.universalConnector.host)) {
        request = request.clone({});
      } else {
        request = request.clone({
          setHeaders: { Authorization: `Bearer ${this.authService.kioskData?.access_token}` }
        });
      }

    }
    return next.handle(request);
  }
}
