import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StyleService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs';
import { CmsDrawingModalComponent } from '../cms-drawing-modal/cms-drawing-modal.component';

@Component({
  selector: 'app-cms-drawing-card',
  templateUrl: './cms-drawing-card.component.html',
  styleUrls: ['./cms-drawing-card.component.scss'],
})
export class CmsDrawingCardComponent {
  @Output() onDetailClose: EventEmitter<any> = new EventEmitter();

  @Input()
  public tab: any;

  @Input()
  public drawingCMS: any;

  @Input()
  public mainTemplate: any;

  @Input()
  public indexes: [any, any] = [] as any;

  @Input()
  public isCmsDrawingDisplayModalView: any; 

  public isLoading = false;

  constructor(public styleService: StyleService, public dialog: MatDialog) {}

  ngOnInit() {   
  }

  public isCmsDrawingDisplayModalVisible(): boolean {
    return !!this.mainTemplate.template.pageLinks[this.indexes[0]]
      .templateSettings[this.indexes[1]]?.templateConfig?.openDetailedView
      ?.isVisible;
  }

  public openDetail() {
    if (this.isCmsDrawingDisplayModalVisible()) {
      const dialogRef = this.dialog.open(CmsDrawingModalComponent, {
        data: {
          tab: this.tab,
          drawing: this.drawingCMS,
          mainTemplate: this.mainTemplate,
          indexes: this.indexes,
        },
      });
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.onDetailClose.emit();
        });
    }
  }
}
