<ng-container *ngIf="isLoaded; else loading">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #loading>
  <div class="loader" *ngIf="isShowLoader">
    <mat-spinner *ngIf="!mainTemplate.globalVariables.loader?.imageLink?.length"
      [color]="mainTemplate.globalVariables?.backgroundPrimary?.color" [diameter]="styleService.isQt3Kiosk ? 100 : 200"></mat-spinner>
    <img class="loader-image" *ngIf="mainTemplate.globalVariables.loader?.imageLink?.length"
      [src]="mainTemplate.globalVariables.loader.imageLink" />
  </div>
</ng-template>
