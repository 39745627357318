<div class="keyboard-row">
  <button keyBoardStyle appKeyboardKey="` ~ ~"></button>
  <button keyBoardStyle appKeyboardKey="1 ! !"></button>
  <button keyBoardStyle appKeyboardKey="2 @ @"></button>
  <button keyBoardStyle appKeyboardKey="3 # #"></button>
  <button keyBoardStyle appKeyboardKey="4 $ $"></button>
  <button keyBoardStyle appKeyboardKey="5 % %"></button>
  <button keyBoardStyle appKeyboardKey="6 ^ ^"></button>
  <button keyBoardStyle appKeyboardKey="7 & &"></button>
  <button keyBoardStyle appKeyboardKey="8 * *"></button>
  <button keyBoardStyle appKeyboardKey="9 ( ("></button>
  <button keyBoardStyle appKeyboardKey="0 ) )"></button>
  <button keyBoardStyle appKeyboardKey="- _ _"></button>
  <button keyBoardStyle appKeyboardKey="= + +"></button>
  <button keyBoardStyle [isSpecial]="true" class="special" (click)="onBackspace()">&larr;</button>
</div>
<div class="keyboard-row">
  <button keyBoardStyle appKeyboardKey="q Q Q"></button>
  <button keyBoardStyle appKeyboardKey="w W W"></button>
  <button keyBoardStyle appKeyboardKey="e E E"></button>
  <button keyBoardStyle appKeyboardKey="r R R"></button>
  <button keyBoardStyle appKeyboardKey="t T T"></button>
  <button keyBoardStyle appKeyboardKey="y Y Y"></button>
  <button keyBoardStyle appKeyboardKey="u U U"></button>
  <button keyBoardStyle appKeyboardKey="i I I"></button>
  <button keyBoardStyle appKeyboardKey="o O O"></button>
  <button keyBoardStyle appKeyboardKey="p P P"></button>
  <button keyBoardStyle appKeyboardKey="[ { {"></button>
  <button keyBoardStyle appKeyboardKey="] } }"></button>
  <button keyBoardStyle appKeyboardKey="\ | |"></button>
  <button keyBoardStyle  [isSpecial]="true" class="special" (click)="onClear()">clear</button>
</div>
<div class="keyboard-row">
  <button keyBoardStyle [isSpecial]="true" [isSelected]="keyboard.shift" class="special" (click)="onShift()"
    [class.toggled]="keyboard.shift">
    caps
  </button>
  <button keyBoardStyle appKeyboardKey="a A A"></button>
  <button keyBoardStyle appKeyboardKey="s S S"></button>
  <button keyBoardStyle appKeyboardKey="d D D"></button>
  <button keyBoardStyle appKeyboardKey="f F F"></button>
  <button keyBoardStyle appKeyboardKey="g G G"></button>
  <button keyBoardStyle appKeyboardKey="h H H"></button>
  <button keyBoardStyle appKeyboardKey="j J J"></button>
  <button keyBoardStyle appKeyboardKey="k K K"></button>
  <button keyBoardStyle appKeyboardKey="l L L"></button>
  <button keyBoardStyle appKeyboardKey="; : :"></button>
  <button keyBoardStyle appKeyboardKey='&apos; " "'></button>
  <button keyBoardStyle appKeyboardKey="@ @ @"></button>
</div>
<div class="keyboard-row">
  <button keyBoardStyle [isSpecial]="true" [isSelected]="keyboard.alt" class="special" (click)="onAlt()" [class.toggled]="keyboard.alt">
    shift
  </button>
  <button keyBoardStyle appKeyboardKey="z Z Z"></button>
  <button keyBoardStyle appKeyboardKey="x X X"></button>
  <button keyBoardStyle appKeyboardKey="c C C"></button>
  <button keyBoardStyle appKeyboardKey="v V V"></button>
  <button keyBoardStyle appKeyboardKey="b B B"></button>
  <button keyBoardStyle appKeyboardKey="n N N"></button>
  <button keyBoardStyle appKeyboardKey="m M M"></button>
  <button keyBoardStyle appKeyboardKey=", &lt; &lt;"></button>
  <button keyBoardStyle appKeyboardKey=". &gt; &gt;"></button>
  <button keyBoardStyle appKeyboardKey="/ ? ?"></button>
  <button keyBoardStyle [isSpecial]="true" class="special" appKeyboardKey=".com .com .com"></button>
  <button keyBoardStyle [isSpecial]="true" class="special" (click)="onSpace()">space</button>
</div>