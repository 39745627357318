<ng-container *ngIf="isLoaded">
  <div class="ksk-modal-wrapper">
    <div class="ksk-modal-close" (click)="closeModal()"></div>
    <div [ngStyle]="styleService.getCloseButtonStyles(tab.configurations.cardOfferDetails?.closeButton)"
      class="ksk-modal-close-button" (click)="closeModal()"></div>
    <div class="ksk-modal-content" *ngIf="isLoaded">
      <div class="offer" *ngIf="isInteraction">
        <iframe class="offer-iframe" [src]="getIframeUrl() | safeUrl" frameborder="0"></iframe>
      </div>

      <div class="offer" *ngIf="!isInteraction">
        <div class="offer-header">
          <div class="offer-img-wrapper">
            <img [src]="reward.imageUrl" class="offer-img" [ngStyle]="
                styleService.getStyleRule(undefined, undefined, [
                  tab.configurations.cardOfferDetails.imageBorder
                ])
              " />
          </div>
          <div class="offer-info" [ngClass]="{ hidden: !reward.hasOnScreenDeliveryOption }" [ngStyle]="
              styleService.getStyleRule(
                undefined,
                [mainTemplate.globalVariables.backgroundSecondary],
                [mainTemplate.globalVariables.borders]
              )
            ">
            <ng-container *ngIf="!!offerQr && (reward?.customProperties?.showQR ?? true)">
              <qrcode [qrdata]="offerQr" [width]="styleService.isQt3Kiosk ? 135 : 270" [margin]="0"
                [colorLight]="'#00000000'" [ngStyle]="
                styleService.getStyleRule(undefined, [
                  tab.configurations.cardOfferDetails.qrCodeBackground
                ])
              "></qrcode>
            </ng-container>
            <p class="offer-qr-text" [ngStyle]="
                styleService.getStyleRule([
                  tab.configurations.cardOfferDetails.printDescription,
                  undefined,
                  'heading4'
                ])
              ">
              <span *ngIf="reward?.customProperties?.rewardActionText?.length">
                {{
                reward.customProperties.rewardActionText
                | conditionalUppercase
                : styleService.getUppercaseValue(
                tab.configurations.cardOfferDetails.printDescription
                )
                }}</span>
            </p>
            <button *ngIf="rewardRedemptionButtonState() !== redemptionButtonState.None" class="offer-qr-btn"
            (click)="executeRewardAction()" [ngStyle]="
                styleService.getStyleRule(
                  [printRedeemButtonStyles?.text],
                  [
                  printRedeemButtonStyles
                      ?.background
                  ],
                  [printRedeemButtonStyles?.border]
                )
              ">
              {{
              printRedeemButtonTitle
              | conditionalUppercase
              : styleService.getUppercaseValue(
                printRedeemButtonStyles
              ?.text
              )
              }}
            </button>
            <button
              *ngIf="(rewardRedemptionButtonState() === redemptionButtonState.None && reward?.customProperties?.showPrintButton !== false)"
              class="offer-qr-btn" (click)="checkPrintPage()" [ngStyle]="
              styleService.getStyleRule(
                [printRedeemButtonStyles?.text],
                [
                printRedeemButtonStyles?.background
                ],
                [printRedeemButtonStyles?.border]
              )
            ">
              {{
              printButtonText
              | conditionalUppercase
              : styleService.getUppercaseValue(
                printRedeemButtonStyles
              ?.text
              )
              }}
            </button>
          </div>
        </div>
        <h1 class="offer-name" [ngStyle]="
            styleService.getStyleRule([
              tab.configurations.cardOfferDetails.title,
              undefined,
              'heading2'
            ])
          ">
          {{
          reward.name
          | conditionalUppercase
          : styleService.getUppercaseValue(
          tab.configurations.cardOfferDetails.title
          )
          }}
        </h1>
        <div class="offer-description" [ngStyle]="
            styleService.getStyleRule([
              tab.configurations.cardOfferDetails.text,
              undefined,
              'mainText'
            ])
          " [ngClass]="{
            uppercase: styleService.getUppercaseValue(
              tab.configurations.cardOfferDetails.text
            )
          }" [innerHtml]="reward.description"></div>
      </div>
    </div>
  </div>
</ng-container>