import { Component, OnInit } from '@angular/core';
import { PinService, StyleService, TemplateService } from '../../services';
import { take } from 'rxjs';
import { MainTemplate } from '../../models';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pin-modal',
  templateUrl: './pin-modal.component.html',
  styleUrls: ['./pin-modal.component.scss'],
})
export class PinModalComponent implements OnInit {
  public mainTemplate: MainTemplate;
  public pinCode = '';

  constructor(
    public styleService: StyleService,
    private readonly templateService: TemplateService,
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<any>,
    private readonly pinService: PinService
  ) {}

  ngOnInit(): void {
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.mainTemplate = template;
      });
  }

  public checkPin(): void {
    this.pinService
      .checkPinCode(this.pinCode)
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp) {
          this.dialogRef.close();
        } else {
          alert('Wrong pin code!');
          this.dialogRef.close();
          history.back();
        }
      });
  }
}
