import { Component, Injector, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent, ViewModalComponent } from 'projects/kiosk-app/src/app/components';

@Component({
  selector: 'app-view-phone',
  templateUrl: './view-phone.component.html',
  styleUrls: ['./view-phone.component.scss']
})
export class ViewPhoneComponent extends ViewModalComponent implements OnInit {
  public phones: any[] = [];

  constructor(
    private readonly snackBar: MatSnackBar,
    injector: Injector
    ) {
    super(injector);
  }

  ngOnInit(): void {
    this.currentViewModalType = 'phone';
    this.phones = this.profileService.isUcSecondVersion ? this.cloneProfile.phones : this.cloneProfile.phoneNumbers;
  }

  public getPhoneNumberIndex(phoneType: string) {
    if (this.profileService.isUcSecondVersion) {
      return this.phones.findIndex(n => n.type === phoneType);
    }
    return this.phones.findIndex(n => n.location === phoneType);
  }

  public setOptOutValue(phone: any) {
    if (this.profileService.isUcSecondVersion) {
      phone.sendText = 'false';
    } else {
      phone.permitTextMsg = false;
    }
  }

  override updateFields(): void {
    if (this.profileService.isUcSecondVersion) {
      this.cloneProfile.phones = this.phones;
      return;
    }
    this.cloneProfile.phoneNumbers = this.phones;
  }

  public checkValidate(): void {
    const regex = this.tabData?.templateConfig?.phoneNumber?.regex;

    if (regex && regex.length) {
      const value = [];

      const getPhoneNumberIndexHome = this.getPhoneNumberIndex('Home');
      if (getPhoneNumberIndexHome > -1 && this.phones[getPhoneNumberIndexHome]?.phoneNumber !== "0") {
        const homePhone = this.phones[getPhoneNumberIndexHome];
        value.push(homePhone.number ?? homePhone.phoneNumber);
      } 
      const getPhoneNumberIndexMobile = this.getPhoneNumberIndex('Mobile');
      if (getPhoneNumberIndexMobile > -1 && this.phones[getPhoneNumberIndexMobile]?.phoneNumber !== "0") {
        const mobilePhone = this.phones[getPhoneNumberIndexMobile];
        value.push(mobilePhone.number ?? mobilePhone.phoneNumber);
      }
      const getPhoneNumberIndexAlternate = this.getPhoneNumberIndex('Alternate');
      if (getPhoneNumberIndexAlternate > -1 && this.phones[getPhoneNumberIndexAlternate]?.phoneNumber !== "0") {
        const alternatePhone = this.phones[getPhoneNumberIndexAlternate];
        value.push(alternatePhone.number ?? alternatePhone.phoneNumber);
      }


      if (value.length) {
        const reg = new RegExp(eval(regex));

        if (value.some(x => !reg.test(x))) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            panelClass: 'error',
            duration: 5000,
            horizontalPosition: 'end',
            data: {
                type: 'error',
                message: 'Please enter a valid phone number'
            },
          });
          return;
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'error',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
              type: 'error',
              message: 'Please enter an phone number'
          },
        });
        return;
      }
    }

    this.closeModal(true);
  }
}
