<ng-container *ngIf="mainTemplate && mainTemplate.tierLevels?.layout">
  <ng-container [ngSwitch]="mainTemplate.tierLevels.layout">
    <div
      class="status-bar"
      [ngStyle]="
        styleService.getStyleRule(
          undefined,
          [
            mainTemplate.tierLevels.configurations?.background,
            undefined,
            mainTemplate.globalVariables.backgroundSecondary
          ],
          [mainTemplate.tierLevels.configurations?.border]
        )
      "
    >
      <h3
        *ngIf="mainTemplate.tierLevels.layout != 'Info tiers'"
        [ngStyle]="
          styleService.getStyleRule([
            mainTemplate.tierLevels.configurations?.title,
            undefined,
            'heading3'
          ])
        "
      >
        {{
          mainTemplate.tierLevels.barTitle
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  mainTemplate.tierLevels.configurations?.title
                )
        }}
      </h3>
      <ng-container *ngSwitchCase="'All tiers'">
        <ng-container
          *ngTemplateOutlet="
            layoutOne;
            context: { hasInfo: false, isLong: false }
          "
        ></ng-container>
      </ng-container>
      <ng-container
        *ngSwitchCase="'Two tiers'"
        [ngTemplateOutlet]="layoutTwo"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'Info tiers'"
        [ngTemplateOutlet]="infoTiers"
      ></ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #layoutOne let-info="hasInfo" let-isLong="isLong">
  <div
    class="status-bar-wrapper"
    [style.width.px]="
      (styleService.isQt3Kiosk ? 5 : 10) +
      (mainTemplate.tierLevels.tiers.length - 1) *
        (styleService.isQt3Kiosk ? (isLong ? 150 : 75) : isLong ? 300 : 150)
    "
    [ngStyle]="
      styleService.getStyleRule(undefined, [
        {
          color:
            mainTemplate.tierLevels.configurations.backgroundTierProgressColor
        }
      ])
    "
  >
    <div
      class="status-bar-dot"
      *ngFor="let tier of mainTemplate.tierLevels.tiers; let i = index"
      [id]="'tier-index-' + i"
      [class.active]="isActivePoint(i)"
      [style.left.px]="
        i * (styleService.isQt3Kiosk ? (isLong ? 150 : 75) : isLong ? 300 : 150)
      "
      [style.background]="getPointBackground(i)"
    >
      <p
        class="info-tier__credit-thresholds"
        *ngIf="mainTemplate.tierLevels?.isThresholdsEnabled"
        [ngStyle]="
          styleService.getStyleRule([
            mainTemplate.tierLevels.configurations?.creditThresholds,
            undefined,
            'helpText'
          ])
        "
      >
        <ng-container *ngIf="hasData(tier.to)">
          {{
            (tier.from | number:'':'en-US') +
              " - " +
              (tier.to | number:'':'en-US') +
              " " +
              mainTemplate.tierLevels.creditThresholds
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    mainTemplate.tierLevels.configurations?.creditThresholds
                  )
          }}
        </ng-container>
        <ng-container *ngIf="!hasData(tier.to)">
          {{
            (tier.from | number:'':'en-US') + "+ " + mainTemplate.tierLevels.creditThresholds
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    mainTemplate.tierLevels.configurations?.creditThresholds
                  )
          }}
        </ng-container>
      </p>
      <img
        class="status-card"
        [class.empty-card]="!tier?.background?.imageLink"
        [src]="
          !!tier?.background?.imageLink
            ? tier?.background?.imageLink
            : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
        "
      />
    </div>
    <div
      class="status-bar-level"
      [style.width.px]="
        styleService.isQt3Kiosk ? levelWidth(isLong) / 2 : levelWidth(isLong)
      "
      [style.background]="
        mainTemplate.tierLevels.configurations.backgroundTiersColor
      "
    ></div>
  </div>
</ng-template>

<ng-template #layoutTwo>
  <div
    class="status-bar-wrapper-two"
    *ngIf="hasTwoTiers(); else notEnoughTiers"
  >
    <ng-container *ngIf="getActiveTiers(); let activeTiers">
      <ng-container
        *ngIf="
          mainTemplate.tierLevels.tiers.length - 1 !==
          activeTiers.activeTierIndex
        "
      >
        <div class="separator-card">
          <img
            class="status-card"
            [class.empty-card]="
              !mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex]
                ?.background?.imageLink
            "
            [src]="
              mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex]
                ?.background?.imageLink
                ? mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex]
                    ?.background?.imageLink
                : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
            "
          />
        </div>
        <div class="separator">
          <ng-container *ngIf="mainTemplate.tierLevels?.isThresholdsEnabled">
            <ng-container
              *ngTemplateOutlet="
                twoTiersThresholdsTmp;
                context: { activeTiers: activeTiers }
              "
            ></ng-container>
          </ng-container>
          <p
            class="separator-title"
            [ngStyle]="
              styleService.getStyleRule([
                mainTemplate.tierLevels.configurations.title,
                mainTemplate.components.tierLevelCard.configurations.title,
                'heading3'
              ])
            "
            [style.color]="
              mainTemplate.tierLevels.configurations.backgroundTiersColor
            "
          >
            Your milestone points
          </p>
          <div
            class="separator-bar"
            [ngStyle]="
              styleService.getStyleRule(undefined, [
                {
                  color:
                    mainTemplate.tierLevels.configurations
                      .backgroundTierProgressColor
                }
              ])
            "
          >
            <div
              class="separator-bar-loading"
              [style.width.%]="getLoadingPercent(activeTiers.activeTier)"
              [style.background]="
                mainTemplate.tierLevels.configurations.backgroundTiersColor
              "
            ></div>
          </div>
          <p
            class="separator-points"
            [innerText]="points + ' POINTS'"
            [ngStyle]="
              styleService.getStyleRule([
                mainTemplate.tierLevels.configurations.title,
                mainTemplate.components.tierLevelCard.configurations.title,
                'heading3'
              ])
            "
          ></p>
        </div>
        <div class="separator-card">
          <img
            class="status-card"
            [class.empty-card]="
              !mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex + 1]
                ?.background?.imageLink
            "
            [src]="
              mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex + 1]
                ?.background?.imageLink
                ? mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex + 1]
                    ?.background?.imageLink
                : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
            "
          />
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          mainTemplate.tierLevels.tiers.length - 1 ===
          activeTiers.activeTierIndex
        "
      >
        <div class="separator-card">
          <img
            class="status-card"
            [class.empty-card]="!activeTiers.tiers[1]?.background?.imageLink"
            [src]="
              activeTiers.tiers[1]?.background?.imageLink
                ? activeTiers.tiers[1]?.background?.imageLink
                : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
            "
          />
        </div>
        <div class="separator">
          <ng-container *ngIf="mainTemplate.tierLevels?.isThresholdsEnabled">
            <ng-container
              *ngTemplateOutlet="
                twoTiersThresholdsTmp;
                context: { activeTiers: activeTiers }
              "
            ></ng-container>
          </ng-container>
          <p
            class="separator-title"
            [ngStyle]="
              styleService.getStyleRule([
                mainTemplate.tierLevels.configurations.title,
                mainTemplate.components.tierLevelCard.configurations.title,
                'heading3'
              ])
            "
            [style.color]="
              mainTemplate.tierLevels.configurations.backgroundTiersColor
            "
          >
            Your milestone points
          </p>
          <div
            class="separator-bar"
            [ngStyle]="
              styleService.getStyleRule(undefined, [
                {
                  color:
                    mainTemplate.tierLevels.configurations
                      .backgroundTierProgressColor
                }
              ])
            "
          >
            <div
              class="separator-bar-loading"
              [style.width.%]="10"
              [style.background]="
                mainTemplate.tierLevels.configurations.backgroundTiersColor
              "
            ></div>
          </div>
          <p
            class="separator-points"
            [innerText]="points + ' POINTS'"
            [ngStyle]="
              styleService.getStyleRule([
                mainTemplate.tierLevels.configurations.title,
                mainTemplate.components.tierLevelCard.configurations.title,
                'heading3'
              ])
            "
          ></p>
        </div>
        <div class="separator-card"></div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #twoTiersThresholdsTmp let-activeTiers="activeTiers">
  <div class="thresholds-container">
    <div>
      <p
        class="info-tier__credit-thresholds"
        [ngStyle]="
          styleService.getStyleRule([
            mainTemplate.tierLevels.configurations?.creditThresholds,
            undefined,
            'helpText'
          ])
        "
      >
        {{
          mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex].from +
            " - " +
            mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex].to +
            " " +
            mainTemplate.tierLevels.creditThresholds
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  mainTemplate.tierLevels.configurations?.creditThresholds
                )
        }}
      </p>
    </div>
    <div *ngIf="mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex + 1]">
      <p
        class="info-tier__credit-thresholds"
        [ngStyle]="
          styleService.getStyleRule([
            mainTemplate.tierLevels.configurations?.creditThresholds,
            undefined,
            'helpText'
          ])
        "
      >
        <ng-container
          *ngIf="
            hasData(
              mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex + 1].to
            )
          "
        >
          {{
            mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex + 1]
              .from +
              " - " +
              mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex + 1]
                .to +
              " " +
              mainTemplate.tierLevels.creditThresholds
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    mainTemplate.tierLevels.configurations?.creditThresholds
                  )
          }}
        </ng-container>
        <ng-container
          *ngIf="
            !hasData(
              mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex + 1].to
            )
          "
        >
          {{
            mainTemplate.tierLevels.tiers[activeTiers.activeTierIndex + 1]
              .from +
              "+ " +
              mainTemplate.tierLevels.creditThresholds
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    mainTemplate.tierLevels.configurations?.creditThresholds
                  )
          }}
        </ng-container>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #infoTiers>
  <div class="info-tiers">
    <div
      class="info-tiers__aside"
      *ngIf="mainTemplate.tierLevels?.isShowSideBar"
      [ngStyle]="
        styleService.getStyleRule(
          undefined,
          [
            mainTemplate.tierLevels.configurations?.additionalSection
              ?.background,
            undefined,
            undefined
          ],
          [
            mainTemplate.tierLevels.configurations?.additionalSection?.border,
            undefined,
            undefined
          ]
        )
      "
    >
      <h5
        class="info-tiers__status"
        *ngIf="getTierStatus; let status"
        [ngStyle]="
          styleService.getStyleRule([
            mainTemplate.tierLevels.configurations?.additionalSection
              ?.sectionTitle,
            undefined,
            'heading5'
          ])
        "
      >
        {{
          status
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  mainTemplate.tierLevels.configurations?.title
                )
        }}
      </h5>
      <p
        class="info-tiers__item"
        *ngFor="let item of mainTemplate.tierLevels.dataPoints"
      >
        <span
          class="info-tiers__item-value"
          [ngStyle]="
            styleService.getStyleRule([
              mainTemplate.tierLevels.configurations?.additionalSection
                ?.dataPoint,
              undefined,
              'heading6'
            ])
          "
          >{{
            getValueByDataPoint(item)
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    mainTemplate.tierLevels.configurations?.additionalSection
                      ?.dataPoint
                  )
          }}</span
        >
        <span
          class="info-tiers__item-title"
          [ngStyle]="
            styleService.getStyleRule([
              mainTemplate.tierLevels.configurations?.additionalSection
                ?.dataPointTitle,
              undefined,
              'helpText'
            ])
          "
          >{{
            item.dataPointTitle
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    mainTemplate.tierLevels.configurations?.additionalSection
                      ?.dataPointTitle
                  )
          }}</span
        >
      </p>
    </div>
    <div class="info-tiers__content" id="scroll-wrapper">
      <ng-container
        *ngTemplateOutlet="layoutOne; context: { hasInfo: true, isLong: true }"
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #notEnoughTiers>
  <h1>need to add more than 1 tier</h1>
</ng-template>
