import { Component, ElementRef, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';

import SwiperCore, { SwiperOptions, Autoplay } from 'swiper';
import { CarouselItem } from '../../models';
import { TemplateService } from '../../services';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs';

SwiperCore.use([Autoplay]);

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent extends BaseComponent implements OnInit {
  public slides: CarouselItem[] = [];
  @ViewChildren('video') videoElements: QueryList<ElementRef>;
  
  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    loop: true,
    scrollbar: { draggable: true },
    modules: [Autoplay],
  };

  constructor(private readonly templateService: TemplateService, injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.templateService.templateData$().pipe(takeUntil(this.destroy$)).subscribe((template) => {
      if (template && template?.carousel?.items.length) {
        this.slides = template.carousel.items?.sort((a, b) => a.order - b.order)?.map((item) => {
          item.isVideo = item.contentType.includes('video');

          return item;
        });
      
        setTimeout(() => {
          this.muteVideos();
        });
        
        const delay = parseInt(template.carousel.slideInterval);

        if (delay > 0) {
          this.config.autoplay = {
            delay: delay * 1000
          };
        }
      }
    })
  }

  private muteVideos(): void {
    this.videoElements?.forEach((videoElement: ElementRef) => {
      const video = videoElement.nativeElement as HTMLVideoElement;
      video.muted = true;
    });
  }
}
