<ng-container *ngIf="mainTemplate$ | async; let mainTemplate">
  <ng-container *ngIf="mainTemplate.template?.pageLinks?.length">
    <a class="menu-item" *ngFor="let link of mainTemplate.template.pageLinks; let i = index" (click)="goToPage(i)"
      [ngStyle]="
        styleService.getStyleRule(
          undefined,
          [
            link.pageLinkContainerSettings?.configurations?.background,
            mainTemplate.components.pageLinks?.background,
            mainTemplate.globalVariables?.backgroundSecondary
          ],
          [
            link.pageLinkContainerSettings?.configurations?.border,
            mainTemplate.components.pageLinks?.border,
            mainTemplate.globalVariables?.borders
          ]
        )
      ">
      <mat-icon aria-hidden="false"
        *ngIf="link.pageLinkContainerSettings?.iconName && link.pageLinkContainerSettings.iconName.length"
        [fontIcon]="link.pageLinkContainerSettings.iconName" [ngStyle]="
          styleService.getIconStyleRule(
            [
              link.pageLinkContainerSettings.iconSize,
              mainTemplate.components.pageLinks?.icon?.iconSize
            ],
            [
              link.pageLinkContainerSettings.iconColor,
              mainTemplate.components.pageLinks?.icon?.iconColor
            ]
          )
        "></mat-icon>
      <span class="menu-title" [ngStyle]="
          styleService.getStyleRule([
            link.pageLinkContainerSettings?.configurations?.title,
            mainTemplate.components?.pageLinks?.title,
            'heading3'
          ])
        ">{{
        link.pageLinkContainerSettings.title
        | conditionalUppercase
        : styleService.getUppercaseValue(
        link.pageLinkContainerSettings?.configurations?.title,
        mainTemplate.components?.pageLinks?.title
        )
        }}</span>
    </a>
  </ng-container>
</ng-container>