import { Component, Injector } from '@angular/core';
import { ViewModalComponent } from 'projects/kiosk-app/src/app/components';

@Component({
  selector: 'app-view-pin',
  templateUrl: './view-pin.component.html',
  styleUrls: ['./view-pin.component.scss']
})
export class ViewPinComponent extends ViewModalComponent {
  constructor(injector: Injector) {
    super(injector);
  }
}
