<div
  class="content"
  [ngStyle]="
    styleService.getStyleRule(undefined, [
      tabData.configurations.main.background,
      mainTemplate.globalVariables.backgroundPrimary
    ])
  "
>
  <div class="header">
    <div class="header-block">
      <h1
        class="header-title"
        [ngStyle]="
          styleService.getStyleRule([
            currentPage.pageLinkContainerSettings.configurations.title,
            undefined,
            'heading3'
          ])
        "
      >
        <a class="header-title-back" (click)="closeModal()">
          <img
            src="/assets/images/last/back.svg"
            class="header-title-back-icon"
          />
        </a>
        {{
          "Name"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  currentPage.pageLinkContainerSettings.configurations.title
                )
        }}
      </h1>
    </div>
  </div>

  <div class="main">
    <div class="row">
      <div class="col-12">
        <label
          class="label"
          [ngStyle]="
            styleService.getStyleRule([
              tabData.configurations.inputs.title,
              undefined,
              'heading4'
            ])
          "
          >{{
            "First name"
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    tabData.configurations.inputs.title
                  )
          }}</label
        >
        <input
          type="text"
          [(ngModel)]="firstName"
          class="input"
          placeholder="Placeholder"
          kioskInput
        />
      </div>
      <div class="col-12">
        <label
          class="label"
          [ngStyle]="
            styleService.getStyleRule([
              tabData.configurations.inputs.title,
              undefined,
              'heading4'
            ])
          "
          >{{
            "Middle name"
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    tabData.configurations.inputs.title
                  )
          }}</label
        >
        <input
          type="text"
          [(ngModel)]="middleName"
          class="input"
          placeholder="Placeholder"
          kioskInput
        />
      </div>
      <div class="col-12">
        <label
          class="label"
          [ngStyle]="
            styleService.getStyleRule([
              tabData.configurations.inputs.title,
              undefined,
              'heading4'
            ])
          "
          >{{
            "Last name"
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    tabData.configurations.inputs.title
                  )
          }}</label
        >
        <input
          type="text"
          [(ngModel)]="lastName"
          class="input"
          placeholder="Placeholder"
          kioskInput
        />
      </div>
    </div>
    <kiosk-keyboard [isAlwaysShown]="true"></kiosk-keyboard>
  </div>

  <div class="footer">
    <button
      (click)="closeModal(true)"
      [ngStyle]="
        styleService.getStyleRule(
          [tabData.configurations.buttons.primaryButtons.text],
          [
            tabData.configurations.buttons.primaryButtons.background,
            { color: mainTemplate.globalVariables.buttonsColor.primaryColor }
          ],
          [tabData.configurations.buttons.primaryButtons.border]
        )
      "
    >
      {{
        "Confirm"
          | conditionalUppercase
            : styleService.getUppercaseValue(
                tabData.configurations.buttons.primaryButtons.text
              )
      }}
    </button>
    <button
      (click)="closeModal()"
      [ngStyle]="
        styleService.getStyleRule(
          [tabData.configurations.buttons.secondaryButtons.text],
          [
            tabData.configurations.buttons.secondaryButtons.background,
            { color: mainTemplate.globalVariables.buttonsColor.secondaryColor }
          ],
          [tabData.configurations.buttons.secondaryButtons.border]
        )
      "
    >
      {{
        "Cancel"
          | conditionalUppercase
            : styleService.getUppercaseValue(
                tabData.configurations.buttons.secondaryButtons.text
              )
      }}
    </button>
  </div>
</div>
