import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StyleService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { CmsOfferModalComponent } from '../cms-offer-modal/cms-offer-modal.component';
import { take } from 'rxjs';

@Component({
  selector: 'app-cms-offer-card',
  templateUrl: './cms-offer-card.component.html',
  styleUrls: ['./cms-offer-card.component.scss']
})
export class CmsOfferCardComponent implements OnInit {

  @Output() onDetailClose: EventEmitter<any> = new EventEmitter();


  @Input()
  public tab: any;

  @Input()
  public offer: any;

  @Input()
  public mainTemplate: any;

  @Input()
  public indexes: [any, any] = [] as any;

  public isLoading = false;

  public redeemedOfferDisplaySettings: any;

  public get isOfferRedeemed() {
    return this.offer.couponStatus === 'Redeemed';
  }

  constructor(
    public styleService: StyleService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.redeemedOfferDisplaySettings = this.tab.configurations?.main?.redeemedOfferDisplaySettings
  }

  public openDetail() {
    const dialogRef = this.dialog.open(CmsOfferModalComponent, {
      data: {
        tab: this.tab,
        offer: this.offer,
        mainTemplate: this.mainTemplate,
        indexes: this.indexes
      },
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      this.onDetailClose.emit();
    });
  }

}
