<header>
  <app-slider></app-slider>
  <app-status></app-status>
</header>
<router-outlet></router-outlet>

<div
  class="loader logout-loader"
  *ngIf="isLogoutRuning"
  [ngStyle]="styleService.getStyleRule([], [
  mainTemplate?.globalVariables?.backgroundPrimary
  ])"
>
  <mat-spinner
    *ngIf="!mainTemplate.globalVariables.loader?.imageLink?.length"
    [color]="mainTemplate.globalVariables.backgroundPrimary.color"
    [diameter]="styleService.isQt3Kiosk ? 100 : 200"
  ></mat-spinner>
  <img
    class="loader-image"
    *ngIf="mainTemplate.globalVariables.loader?.imageLink?.length"
    [src]="mainTemplate.globalVariables.loader.imageLink"
  />
</div>
