var TaskState = {
  Waiting: 0,
  Completed: 1,
  Canceled: 2,
};

class Task {
  constructor() {
    this.Callbacks = [];
    this.Arguments = null;
    this.State = TaskState.Waiting;
  }

  TaskComplete() {
    if (this.State != TaskState.Waiting) return;
    this.State = TaskState.Completed;
    this.Arguments = Array.from(arguments);
    for (var i = 0; i < this.Callbacks.length; i++) {
      var cb = this.Callbacks[i];
      var rc = cb.apply(null, this.Arguments);
      if (rc === false) {
        this.State = TaskState.Canceled;
        break;
      } else if (rc instanceof Array) this.Arguments = rc;
    }
  }

  Result(callback) {
    switch (this.State) {
      case TaskState.Waiting:
        this.Callbacks.push(callback);
        break;
      case TaskState.Completed:
        var rc = callback.apply(null, this.Arguments);
        if (rc === false) this.State = TaskState.Canceled;
        else if (rc instanceof Array) this.Arguments = rc;
        break;
    }

    return this;
  }

  static Delay(timeout) {
    var task = new Task();
    window.setTimeout(function () {
      task.TaskComplete();
    }, timeout);

    return task;
  }

  static WaitAll(tasks) {
    var state = {
      Args: new Array(tasks.length),
      Count: tasks.length,
    };

    var ret = new Task();

    for (var i = 0; i < tasks.length; i++) {
      WaitAllHelper(state, i, tasks[i], ret);
    }

    return ret;
  }

  static WhenAll() {
    return this.WaitAll(arguments);
  }
}

function WaitAllHelper(state, index, subject, ret) {
  subject.Result(function () {
    state.Args[index] = Array.from(arguments);
    if (--state.Count == 0) ret.TaskComplete(state.Args);
  });
}

export class TaskSequence {
  constructor() {
    this.Sequence = [];
    this.LoopIndex = 0;
    this.Finished = false;
    this.StopCallback = null;
  }

  Start(callback) {
    if (this.LoopIndex < this.Sequence.length)
      throw new Error("Sequence already started");

    this.Sequence.push(callback);
    this.LoopIndex = this.Sequence.length;
    return this;
  }

  Step(callback) {
    if (this.Finished) throw new Error("Sequence already ended");

    this.Sequence.push(callback);
    return this;
  }

  Stop(callback) {
    if (callback === undefined) callback = null;

    if (this.Sequence.length == 0) throw new Error("Sequence not started");

    if (this.Finished) throw new Error("Sequence already ended");

    this.StopCallback = callback;
    this.Finished = true;
    return this;
  }

  RunOnce() {
    if (!this.Finished) this.Stop();

    var self = this;
    var state = { Index: 0 };
    var wrapper = function () {
      var result = null;
      if (state.Index < self.Sequence.length) {
        var args = Array.from(arguments);
        var cb = self.Sequence[state.Index++];
        result = cb.apply(null, args);
      }

      if (result instanceof Task) {
        result.Result(wrapper);
      } else if (self.StopCallback != null) {
        var args = Array.from(arguments);
        self.StopCallback.apply(null, args);
      }
    };

    wrapper();
  }

  Run() {
    if (!this.Finished) this.Stop();

    var self = this;
    var state = { Index: 0 };
    var wrapper = function () {
      var result = null;
      if (state.Index >= self.Sequence.length) {
        state.Index = self.LoopIndex;
      }
      if (state.Index < self.Sequence.length) {
        var args = Array.from(arguments);
        var cb = self.Sequence[state.Index++];
        result = cb.apply(null, args);
      }

      if (result instanceof Task) {
        result.Result(wrapper);
      } else if (self.StopCallback != null) {
        var args = Array.from(arguments);
        self.StopCallback.apply(null, args);
      }
    };

    wrapper();
  }
}

class Rest {
  static Get(url, headers) {
    var request = new XMLHttpRequest();
    var task = new Task();
    request.onreadystatechange = function () {
      if (request.readyState != 4) return;
      if (request.status == 0) {
        //task.TaskComplete(false, "Failed to connect");
        return;
      } else if (request.status != 200) {
        task.TaskComplete(false, request.statusText);
        return;
      }

      try {
        var object = JSON.parse(request.responseText);
        task.TaskComplete(true, object);
      } catch (e) {
        task.TaskComplete(false, e.message);
      }
    };
    request.onerror = function (err) {
      task.TaskComplete(false, "Failed to connect");
    };

    request.open("GET", url, true);
    request.setRequestHeader("Accept", "application/json");

    if (headers != undefined) {
      for (var i in headers) {
        if (i == "Accept") continue;
        request.setRequestHeader(i, headers[i]);
      }
    }

    request.send();

    return task;
  }

  static Post(url, data, headers) {
    var request = new XMLHttpRequest();
    var task = new Task();
    request.onreadystatechange = function () {
      if (request.readyState != 4) return;
      if (request.status != 200) {
        task.TaskComplete(false, request.statusText);
        return;
      }

      try {
        var object = JSON.parse(request.responseText);
        task.TaskComplete(true, object);
      } catch (e) {
        task.TaskComplete(false, e.message);
      }
    };
    request.onerror = function () {
      task.TaskComplete(false, "Failed to connect");
    };

    request.open("POST", url, true);
    request.setRequestHeader("Accept", "application/json");
    request.setRequestHeader("Content-Type", "application/json");

    if (headers != undefined) {
      for (var i in headers) {
        if (i == "Accept" || i == "Content-Type") continue;
        request.setRequestHeader(i, headers[i]);
      }
    }

    request.send(JSON.stringify(data));

    return task;
  }

  static Put(url, data, headers) {
    var request = new XMLHttpRequest();
    var task = new Task();
    request.onreadystatechange = function () {
      if (request.readyState != 4) return;
      if (request.status != 200) {
        task.TaskComplete(false, request.statusText);
        return;
      }

      try {
        var object = JSON.parse(request.responseText);
        task.TaskComplete(true, object);
      } catch (e) {
        task.TaskComplete(false, e.message);
      }
    };
    request.onerror = function () {
      task.TaskComplete(false, "Failed to connect");
    };

    request.open("PUT", url, true);
    request.setRequestHeader("Accept", "application/json");
    request.setRequestHeader("Content-Type", "application/json");

    if (headers != undefined) {
      for (var i in headers) {
        if (i == "Accept" || i == "Content-Type") continue;
        request.setRequestHeader(i, headers[i]);
      }
    }

    request.send(JSON.stringify(data));

    return task;
  }

  static Delete(url, headers) {
    var request = new XMLHttpRequest();
    var task = new Task();
    request.onreadystatechange = function () {
      if (request.readyState != 4) return;
      if (request.status != 200) {
        task.TaskComplete(false, request.statusText);
        return;
      }

      try {
        var object = JSON.parse(request.responseText);
        task.TaskComplete(true, object);
      } catch (e) {
        task.TaskComplete(false, e.message);
      }
    };
    request.onerror = function () {
      task.TaskComplete(false, "Failed to connect");
    };

    request.open("DELETE", url, true);
    request.setRequestHeader("Accept", "application/json");

    if (headers != undefined) {
      for (var i in headers) {
        if (i == "Accept") continue;
        request.setRequestHeader(i, headers[i]);
      }
    }
    request.send();

    return task;
  }
}

var CHSRequestType = {
  GetDevices: 2,
  FindDevice: 3,
  AcceptItem: 4,
  EnableDevice: 5,
  RegisterSession: 6,
  EndSession: 7,
  PrintReceipt: 8,
  RejectItem: 9,
  PrintTicket: 10,
  SendEvent: 11,
  ClearEvents: 12,
  TritonTransaction: 13,
};

var CHSResponseType = {
  RequestComplete: 0,
  BillAcceptorEvent: 1,
  CardInserted: 2,
  CardRemoved: 3,

  DoorOpen: 4,
  DoorClosed: 5,

  HeadphoneInserted: 6,
  HeadphoneRemoved: 7,

  PinPadKeyPressed: 8,

  PrintReceipt: 9,

  KioskInService: 10,
  KioskOutOfService: 11,
  KioskConnectionEvent: 12,

  SessionStarted: 13,

  TicketingEvent: 14,
};

export const EventClass = {
  System: 0,
  Device: 1,
};

var ItemType = {
  Unknown: 0,
  Bill: 1,
  Coin: 2,
  Ticket: 3,
  Wager: 4,
  Card: 5,
  Handpay: 6,
  Blank: 7,
  Document: 8,
  Voucher: 9,
  Prepaid: 10,
  Receipt: 11,
};

var DeviceType = {
  Default: 0,
  BillAcceptor: 1,
  Candle: 2,
  Camera: 3,
  CardReader: 4,
  CashDispenser: 5,
  CoinDispenser: 6,
  DoorSensor: 7,
  HeadPhone: 8,
  Kiosk: 9,
  PinPad: 10,
  ReceiptPrinter: 11,
  TicketPrinter: 12,
  Scanner: 13,
  IDScanner: 14,
  ScanPrinter: 15,
  UPS: 16,
  CardPrinter: 17,
  Microphone: 18,
  TritonHost: 19,
};

var DeviceStatus = {
  Unknown: 0,
  Disabled: 1, //device is disabled according the license key.
  NotReady: 2, //device is not in service
  Ready: 3, //device is ready
  OutOfService: 4, //out of service mode (only for kiosk)
  Full: 5, //bin box full (Bill Validator)
  On: 6, //device on (SOP button pressed, headset plugged in)
  Off: 7, //device off (headset unplugged)
  Closed: 8, //Door closed
  Opened: 9, //Door opened
  Accepting: 10, //being accepting (bill, ticket)
  Inserted: 11, //already inside the device (bill, ticket, card)
  AtEscrow: 12, //item at escrow, (bill, ticket)
  Stacked: 13, //item inside bin box (bill, ticket)
  Presented: 14, //item is presented to patron (bill, ticket, receipt)
  AtGate: 15, //item at the gate (card)
  Removed: 16, //item is taken by patron (ticket, receipt)
  Jammed: 17, //item is jammed inside device.
  Empty: 18, //Device is empty
  Error: 19, //Device has error
  Busy: 20, //Device is busy
  Disconnected: 21, //Device is disconnected
  LinkConnected: 22, //CHS link connected
  LinkDisconnected: 23, //CHS link disconnected
  Low: 24, //Low Error Event
  SOP: 25, //Kiosk in SOP Event
};

var Language = {
  English: 0,
  French: 1,
  Spanish: 2,
};

var PinPadKey = {
  Zero: 0,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
  Seven: 7,
  Eight: 8,
  Nine: 9,
  L1: 10,
  L2: 11,
  L3: 12,
  L4: 13,
  R1: 14,
  R2: 15,
  R3: 16,
  R4: 17,
  Pound: 18,
  Star: 19,
  Blank: 20,
  Enter: 21,
  Cancel: 22,
  Correct: 23,
};

var MessageType = {
  Inform: 0,
  Warning: 1,
  Error: 2,
  Fatal: 0,
  SystemEvent: 0,
};

class NotifyEvent {
  constructor() {
    this.DeviceId = null;
    this.DeviceType = DeviceType.BillAcceptor;
    this.EventType = EventType.Inform;
    this.Description = null;
    this.EventTime = new Date();
  }
}

function ExpandMacros(text, macros) {
  function replace(match, macro) {
    return macros[macro];
  }

  return text.replace(/\{([a-zA-Z_]*)\}/g, replace);
}

export class WebLinkClient {
  constructor(host, port) {
    this.KioskUrl = "http://" + host + ":" + port + "/api/weblink/Id";
    this.ConnectUrl =
      "ws://" +
      host +
      ":" +
      port +
      "/api/weblink/Connect/{Serial}/{LicenseKey}";
    this.Socket = null;
    this.Messages = {};
    this.Events = [];
    this.NextMsgId = 0;
  }

  GetKioskId() {
    var task = Rest.Get(this.KioskUrl);
    task.Result(function (success, data) {
      if (!success) return [null, data];
      return [data.id];
    });

    return task;
  }

  Connect(serialNumber, licenseKey) {
    if (this.Socket != null) throw new Error("Already connected");
    var url = ExpandMacros(this.ConnectUrl, {
      Serial: serialNumber,
      LicenseKey: licenseKey,
    });

    var task = new Task();

    var self = this;

    this.Socket = new WebSocket(url);

    this.Socket.onclose = function () {
      self.Socket = null;
      self.CancelTasks();

      for (var i = 0; i < self.Events.length; i++) {
        var el = self.Events[i];
        el(EventClass.System, false);
      }
    };
    this.Socket.onerror = function () {
      task.TaskComplete(false);
    };

    this.Socket.onopen = function () {
      task.TaskComplete(true);
      for (var i = 0; i < self.Events.length; i++) {
        var el = self.Events[i];
        el(EventClass.System, true);
      }
    };

    self.Socket.onmessage = function (msg) {
      if (typeof msg.data != "string") {
        self.Socket.close();
        return;
      }
      var rsp = JSON.parse(msg.data);
      if (rsp.Type == CHSResponseType.RequestComplete) {
        if (self.Messages[rsp.Data.RequestId] == undefined) return;
        self.Messages[rsp.Data.RequestId].TaskComplete(true, rsp.Data);
        delete self.Messages[rsp.Data.RequestId];
        return;
      }

      for (var i = 0; i < self.Events.length; i++) {
        var el = self.Events[i];
        el(EventClass.Device, rsp);
      }
    };

    return task;
  }

  Close() {
    if (this.Socket == null) throw new Error("Not connected");

    this.Socket.close();
  }

  CancelTasks() {
    for (var i in this.Messages) {
      if (this.Messages.hasOwnProperty(i)) {
        var el = this.Messages[i];
        delete this.Messages[i];
        el.TaskComplete(false, null);
      }
    }
  }

  AddEventListener(event_callback) {
    if (this.Events.indexOf(event_callback) != -1) return;

    this.Events.push(event_callback);
  }

  RemoveEventListener(event_callback) {
    var index = this.Events.indexOf(event_callback);
    if (index == -1) return;

    this.Events.splice(index, 1);
  }

  GetDevices() {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.GetDevices,
      Data: {
        RequestId: this.NextMsgId++,
      },
    };

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return;
      if (!data.RequestStatus) return [false, null];
      return [true, data.Devices];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }

  EnableDevice(device_type, device_id, enable) {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.EnableDevice,

      Data: {
        RequestId: this.NextMsgId++,
        DeviceType: device_type,
        DeviceId: device_id,
        Enable: enable,
      },
    };

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return [false];
      if (!data.RequestStatus) return [false];
      return [true];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }

  FindDevice(device_id) {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.FindDevice,
      Data: {
        RequestId: this.NextMsgId++,
        DeviceId: device_id,
      },
    };

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return;
      if (!data.RequestStatus) return [false, null];
      return [true, data.Device];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }

  RegisterSession() {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.RegisterSession,
      Data: {
        RequestId: this.NextMsgId++,
      },
    };

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return;
      if (!data.RequestStatus) return [false, null];
      return [true, data.SessionId];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }

  EndSession() {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.EndSession,
      Data: {
        RequestId: this.NextMsgId++,
      },
    };

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return;
      if (!data.RequestStatus) return [false];
      return [true];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }

  AcceptItem(device_type, device_id, item_type, barcode, amnt) {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.AcceptItem,

      Data: {
        RequestId: this.NextMsgId++,
        DeviceType: device_type,
        DeviceId: device_id,
        ItemType: item_type,
        Barcode: barcode,
        Amount: amnt,
      },
    };

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return [false];
      if (!data.RequestStatus) return [false];
      return [true];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }

  SendEvent(event) {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.SendEvent,

      Data: {
        RequestId: this.NextMsgId++,
        Event: event,
      },
    };

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return [false];
      if (!data.RequestStatus) return [false];
      return [true];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }

  RejectItem(device_type, device_id, item_type, barcode, amnt) {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.RejectItem,

      Data: {
        RequestId: this.NextMsgId++,
        DeviceType: device_type,
        DeviceId: device_id,
        ItemType: item_type,
        Barcode: barcode,
        Amount: amnt,
      },
    };

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return [false];
      if (!data.RequestStatus) return [false];
      return [true];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }

  PrintReceipt(url) {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.PrintReceipt,

      Data: {
        RequestId: this.NextMsgId++,
        Url: url,
      },
    };

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return [false];
      if (!data.RequestStatus) return [false];
      return [true];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }

  HostTransaction(
    deviceName,
    transactionName,
    varContextIn,
    varContextOut,
    writeFields,
    readFields,
    clearFields
  ) {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.TritonTransaction,

      Data: {
        RequestId: this.NextMsgId++,
        DeviceName: deviceName,
        TransactionName: transactionName,
        VarContextIn: varContextIn,
        VarContextOut: varContextOut,
        WriteFields: writeFields,
        ReadFields: null,
      },
    };

    if (readFields instanceof Array) {
      msg.Data.ReadFields = [];

      for (let i = 0; i < readFields.length; i++) {
        const item = readFields[i];
        msg.Data.ReadFields.push({ Id: item });
        if (clearFields) msg.Data.WriteFields.push({ Id: item, Data: "" });
      }
    }

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return [false];
      if (!data.RequestStatus) return [false];
      return [true, data.ReadFields];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }

  SendEvent(url) {
    if (this.Socket == null) throw new Error("Not connected");

    var msg = {
      Type: CHSRequestType.PrintReceipt,

      Data: {
        RequestId: this.NextMsgId++,
        Url: url,
      },
    };

    var task = (this.Messages[msg.Data.RequestId] = new Task());
    task.Result(function (success, data) {
      if (!success) return [false];
      if (!data.RequestStatus) return [false];
      return [true];
    });

    this.Socket.send(JSON.stringify(msg));
    return task;
  }
}
