<ng-container *ngIf="!isLoading">
  <div [ngClass]="{ disabled: !isCmsDrawingDisplayModalView }">
    <div
      class="cmsDrawing-last__item"
      (click)="openDetail()"
      [ngStyle]="
        styleService.getStyleRule(
          undefined,
          [tab.configurations.cardDrawing.background],
          [
            tab.configurations.cardDrawing.border,
            mainTemplate.globalVariables.borders
          ]
        )
      "
    >
      <div class="cmsDrawing-last__item-img-wrapper">
        <img [src]="drawingCMS?.extendedDetails?.imageUrl" class="btn-ico" />
      </div>

      <div class="cmsDrawing-last__item-content">
        <h3
          class="cmsDrawing-last__item-name"
          [ngStyle]="
            styleService.getStyleRule([
              tab.configurations.cardDrawing.title,
              undefined,
              'heading4'
            ])
          "
          [innerHTML]="
            drawingCMS?.extendedDetails?.title
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    tab.configurations.cardDrawing.title
                  )
          "
        ></h3>

        <h3
          class="cmsDrawing-last__item-name"
          [ngStyle]="
            styleService.getStyleRule([
              tab.configurations.cardDrawing.balanceText,
              undefined,
              'heading4'
            ])
          "
        >
          {{
            tab.configurations.cardDrawing.balance
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    tab.configurations.cardDrawing.balanceText
                  )
          }}
          {{
            drawingCMS?.balance
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    tab.configurations.cardDrawing.balanceText
                  )
          }}
        </h3>

        <div
          *ngIf="isCmsDrawingDisplayModalView"
          class="cmsDrawing-last__item-content-bottom"
        >
          <div class="cmsDrawing-last__item-line"></div>

          <div
            class="cmsDrawing-last__item-btn-details"
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardDrawing.buttonDrawingDetails.text],
                [
                  tab.configurations.cardDrawing.buttonDrawingDetails.background
                ],
                [tab.configurations.cardDrawing.buttonDrawingDetails.border]
              )
            "
          >
            {{
              tab.configurations.cardDrawing.buttonDrawingDetailsText
                ? tab.configurations.cardDrawing.buttonDrawingDetailsText
                : ("Drawing detail"
                  | conditionalUppercase
                    : styleService.getUppercaseValue(
                        tab.configurations.cardDrawing.buttonDrawingDetails.text
                      ))
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
