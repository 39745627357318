import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent, NotFoundComponent, TemplateComponent } from './pages';
import { CarouselComponent } from './pages/carousel/carousel.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },

      {
        path: 'template/:pageLinkIndex',
        component: TemplateComponent,
      },
    ],
  },
  {
    path: 'carousel/:accountHash/:carouselType',
    component: CarouselComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
