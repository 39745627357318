import { Injectable } from "@angular/core";
import { Drawing } from "../models/drawing";
import { Balance } from "../models/balance";

@Injectable({
    providedIn: 'root',
})
export class BalanceService {
    constructor() {}

    private removeBalance(drawingId: string): void {
        const balances = this.getBalances();

        this.setBalances(balances.filter(x => x.drawingId !== drawingId));
    }

    private getBalances(): Balance[] {
        const balances = sessionStorage.getItem(BALANCE_KEY);

        return balances ? JSON.parse(balances) as Balance[] : [];
    }

    private setBalances(balance: Balance[]): void {
        sessionStorage.setItem(BALANCE_KEY, JSON.stringify(balance));
    }

    public addMultiplier(drawing: Drawing, multiplierRate: number): void {
        const balances = this.getBalances();
        const findBalance =  balances.find(x => x.drawingId === drawing.drawingId);

        if (findBalance) {
            findBalance.multipliers.push(multiplierRate);
        } else {
            balances.push({
                drawingId: drawing.drawingId,
                oldBalance: drawing.entriesAmount,
                multipliers: [multiplierRate]
            });
        }

        this.setBalances(balances);
    }

    public getBalance(drawing: Drawing) {
        let currentBallance = drawing.entriesAmount;
        const balance = this.getBalances();
        const findBalance =  balance.find(x => x.drawingId === drawing.drawingId);

        if (findBalance && findBalance.oldBalance === currentBallance) {
            currentBallance = findBalance.multipliers.reduce((xRate, currentX) => {
                return xRate * currentX;
            }, 1) * findBalance.oldBalance;
        } else {
            this.removeBalance(drawing.drawingId);
        }

        return currentBallance;
    }
}

const BALANCE_KEY = 'balances';