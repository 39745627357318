import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TemplateService } from '../../services';
import { KeyboardConfigurationsInterface } from '../../models/template';

@Directive({
  selector: '[keyBoardStyle]',
})
export class KeyboardStyleDirective implements OnInit {
  @Input('isSpecial') isSpecial: boolean = false;

  @Input('isSelected') isSelected: boolean = false;

  @HostListener('click') onMouseEnter() {
    this.elementRef.nativeElement.style.opacity = '0.5';
    setTimeout(() => {
      this.elementRef.nativeElement.style.opacity = '1';
    }, 150);
  }
  public keyBoardConfig!: KeyboardConfigurationsInterface;

  public nativeElementStyles = this.elementRef.nativeElement.style;

  constructor(
    templateService: TemplateService,
    private elementRef: ElementRef
  ) {
    this.keyBoardConfig = templateService.templateData.components.keyboard;
  }

  ngOnInit(): void {
    if (!this.isSpecial) {
      this.nativeElementStyles.background =
        this.keyBoardConfig?.buttonColors?.background;
    } else {
      this.nativeElementStyles.background =
        this.keyBoardConfig?.buttonColors?.accent;
    }
    this.nativeElementStyles.borderColor =
      this.keyBoardConfig?.buttonColors?.outline;
    this.applyFontStyles();
  }

  private applyFontStyles() {
    if (this.keyBoardConfig?.letters) {
      Object.keys(this.keyBoardConfig?.letters).forEach((key) => {
        if (key === 'fontColor') {
          this.nativeElementStyles.color =
            this.keyBoardConfig?.letters?.fontColor;
        }
        if (this.nativeElementStyles.hasOwnProperty(key)) {
          this.nativeElementStyles[key] = (this.keyBoardConfig?.letters as any)[
            key
          ];
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isSelected && this.isSpecial) {
      this.nativeElementStyles.background =
        this.keyBoardConfig?.buttonColors.accentActive;
    } else {
      this.nativeElementStyles.background =
        this.keyBoardConfig?.buttonColors.accent;
    }
  }
}
