import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LogService } from './logs.service';

@Injectable({
  providedIn: 'root',
})
export class PinService {
    private errorCount = 0;

    constructor(
      private readonly logService: LogService,
      private readonly router: Router) {}

    public checkPinCode(pinCode: string): Observable<boolean> {
      let isSuccess = true;

      console.log(pinCode);

      if (pinCode !== '1111') {
        isSuccess = false;
        this.errorCount += 1;

        if (this.errorCount === 3) {
          this.logService.writeLog('entered the wrong pin 3 times');
          this.router.navigate(['404']);
        }
      } else {
        this.errorCount = 0;
      }

      return of(isSuccess);
    }
}
