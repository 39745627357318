import { Component } from '@angular/core';
import { MainTemplate } from '../../models';
import { StyleService, TemplateService } from '../../services';
import { Observable } from 'rxjs';
import { PageLink } from '../../models/template';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  public mainTemplate$: Observable<MainTemplate>;

  constructor(
    public readonly styleService: StyleService,
    private readonly templateService: TemplateService,
    private readonly router: Router
  ) {
    this.mainTemplate$ = this.templateService.templateData$();
  }

  public goToPage(index: number): void {
    this.router.navigate(['/template/' + index]);
  }
}
