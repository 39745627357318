<div class="not-found-wrapper">
	<h1 class="not-found-title">Let's Try Again</h1>
	<button class="not-found-button" (click)="goExit()">GO</button>
</div>
<!-- <div class="not-found-wrapper">
	<div class="not-found-item not-found-item--grey">
		<img src="/assets/images/last/not-found.svg" class="not-found-img">
		<h1 class="not-found-title">PAGE NOT FOUND</h1>
	</div>
	<div class="not-found-item">
		<h3 class="not-found-subtitle">The requested page was not found</h3>
		<p class="not-found-text">Please try again or go to the log in page</p>
	</div>
</div> -->