<div
  class="pop-up-main-container"
  [ngStyle]="
    styleService.getStyleRule(
      undefined,
      [welcomePopUp?.background],
      [welcomePopUp?.border]
    )
  "
>
  <h1
    [ngStyle]="
      styleService.getStyleRule([
        welcomePopUp.messageStyle,
        undefined,
        'heading1'
      ])
    "
  >
    {{
      welcomePopUp?.message
        ? (welcomePopUp.message
          | conditionalUppercase
            : styleService.getUppercaseValue(welcomePopUp.messageStyle))
        : "PopUp message"
    }}
  </h1>
  <button
    (click)="closePopUp()"
    [ngStyle]="
      styleService.getStyleRule(
        [welcomePopUp?.button?.text],
        [welcomePopUp?.button?.background],
        [welcomePopUp?.button?.border]
      )
    "
  >
    {{
      welcomePopUp?.buttonText
        ? (welcomePopUp.buttonText
          | conditionalUppercase
            : styleService.getUppercaseValue(welcomePopUp.button.text))
        : "Close button"
    }}
  </button>
</div>
