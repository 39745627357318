import { Component, Inject, SkipSelf } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DrawingService, LogService, ProfileService, StyleService } from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cms-drawing-modal',
  templateUrl: './cms-drawing-modal.component.html',
  styleUrls: ['./cms-drawing-modal.component.scss']
})
export class CmsDrawingModalComponent {


  public isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<CmsDrawingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @SkipSelf() public styleService: StyleService,
  ) { }
  

  closeModal() {
    this.dialogRef.close();
    }

}
