import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OfferModalComponent } from '../offer-modal/offer-modal.component';
import { Campaign, DrawingEventStatus, KnownReward } from '../../models';
import {
  LogService,
  OfferService,
  StyleService,
  TemplateService,
} from '../../services';
import { Observable, of, take } from 'rxjs';
import { MainTemplate, TemplateSetting } from '../../models/template';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() public indexes: [any, any] = [] as any;
  @Input() public tab!: TemplateSetting;
  @Input() public accessRequestId: string = '';
  @Input() public lobbyToken: string = '';
  @Input() public campaign!: Campaign;

  @Output() onDetailClose: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteExpiredCampaign: EventEmitter<string> = new EventEmitter();

  public isLoading = false;
  public mainTemplate!: MainTemplate;
  public drawingEventStatus = DrawingEventStatus;
  public isLiveEarnPeriod = false;

  private isInteraction: boolean = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly offerService: OfferService,
    private readonly templateService: TemplateService,
    private readonly logService: LogService,
    public readonly styleService: StyleService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.isLoading = false;
        this.mainTemplate = template;
        this.isLiveEarnPeriod = this.offerService.isLiveEarnPeriod(
          this.campaign
        );
        this.isInteraction = this.offerService.isGameInteraction(this.campaign);
      });
  }

  public openDetail(): void {
    if (!this.campaign.knownReward) {
      if (this.isCampaignExpired()) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'error',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'error',
            message: 'Campaign has been expired',
          },
        });
        this.onDeleteExpiredCampaign.emit(this.campaign.id);
        return;
      }
    }

    let request: Observable<KnownReward> = of(this.campaign.knownReward);
    let isFirstRequest = this.campaign.knownReward === null;

    if (isFirstRequest){
      if (this.campaign.isOpening) return;
      this.campaign.isOpening = true;
    } 
    if (!this.isInteraction && isFirstRequest) {
      request = this.offerService.getCamapign(
        this.campaign.id,
        this.accessRequestId,
        this.campaign.schedule.schedulerId
      );
    }

    request.pipe(take(1)).subscribe({
      next: async (rewardData) => {
        this.offerService.setDefaultCustomProperties(rewardData);
        if (!this.isInteraction) {
          this.campaign.knownReward = rewardData;
        }

        const dialogRef = this.dialog.open(OfferModalComponent, {
          data: {
            indexesOffer: this.indexes,
            tab: this.tab,
            offer: this.campaign,
            accessRequestId: this.accessRequestId,
            lobbyToken: this.lobbyToken,
            reward: rewardData,
            isNeedToReedemOrUpdateBalance: !this.isInteraction  && isFirstRequest
          },
        });

        if (isFirstRequest) {
          dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
              this.onDetailClose.emit();
            });
        }
      },
      error: (err) => {
        this.logService.writeLog(
          JSON.stringify(`
        Open offer details: Campaign Id - ${this.campaign.id}
        Error:${JSON.stringify(err)}`)
        );
      },
    });
  }

  private isCampaignExpired(): boolean {
    return this.campaign.schedule.items.some((item) => {
      return new Date() >= new Date(item.expirationDate);
    });
  }
}
