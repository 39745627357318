import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { MainTemplate } from '../../models';
import { AuthService, StyleService, TemplateService } from '../../services';
import { BehaviorSubject, Subject, Subscription, debounceTime, delay, from, fromEvent, interval, mergeMap, of, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
})
export class SignOutComponent extends BaseComponent implements OnInit, OnDestroy {

  public mainTemplate!: MainTemplate;

  public logoutTimerConfig: any;

  public windowEventsSubscription: Subscription = new Subscription();

  public logoutInterval$: Subscription = new Subscription();

  public numberOfSeconds$: BehaviorSubject<number> = new BehaviorSubject(0);

  public countdown: number = 0;

  constructor(
    public readonly styleService: StyleService,
    private readonly templateService: TemplateService,
    private readonly authService: AuthService,
    injector: Injector
  ) {
    super(injector);

  }

  private setUserEventsListneres() {
    if (!this.logoutTimerConfig?.numberOfSeconds) {
      return;
    }
    const events = ['scroll', 'touchstart', 'click', 'mousemove'];
    this.windowEventsSubscription = from(events)
      .pipe(
        mergeMap(event => fromEvent(document, event)),
        switchMap(e => of(e)),
        debounceTime(200),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.startTimer();
      });
  }

  private startTimer() {
    if (this.logoutInterval$) {
      this.logoutInterval$?.unsubscribe();
      this.logoutInterval$ = new Subscription();
    }
    this.countdown = this.logoutTimerConfig.numberOfSeconds;
    this.numberOfSeconds$.next(this.countdown);
    this.logoutInterval$ = interval(1000).subscribe(() => {
      this.numberOfSeconds$.next(--this.countdown);
      if (this.countdown === 0) {
        this.logoutInterval$.unsubscribe();
        this.logout();
      }
      const progress = document.querySelector('.js-progress-bar');
      if (progress) {
        (progress as any).style.strokeDashoffset = ((this.logoutTimerConfig.numberOfSeconds - this.countdown) / this.logoutTimerConfig.numberOfSeconds * 100);
      }
    });
  }

  ngOnInit(): void {
    this.templateService.templateData$().pipe(
      takeUntil(this.destroy$)
    ).subscribe((template) => {
      this.mainTemplate = template;
      this.logoutTimerConfig = (template.template as any).logoutTimer;

      if (this.logoutTimerConfig === undefined) return;

      this.logoutTimerConfig.numberOfSeconds = Number(this.logoutTimerConfig?.numberOfSeconds);
      if (!!this.logoutTimerConfig.numberOfSeconds) {
        this.startTimer();
        this.setUserEventsListneres();
      }
    });
  }

  public logout(): void {
    this.authService.logout();
  }

  override ngOnDestroy(): void {
    this.windowEventsSubscription.unsubscribe();
    this.logoutInterval$.unsubscribe();
  }
}
