import { Component, Injector, OnInit } from '@angular/core';
import { LogService, StyleService, TemplateService } from '../../services';
import { take, takeUntil } from 'rxjs';
import { Background, MainTemplate } from '../../models';
import { BaseComponent } from '../../components';
import { ProfileService } from '../../services/profile.service';
import { DataPoint } from '../../models/template';
import { DataPointService } from '../../services/data-point.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit {
  public mainTemplate: MainTemplate;

  public getValueByDataPoint = ({ dataPoint, suffix, prefix }: any): string => `${prefix ?? ''}${this.dataPointService.getValueByDataPointProperty(dataPoint)}${ suffix ?? ''}`;

  constructor(
    public readonly styleService: StyleService,
    private readonly templateService: TemplateService,
    private readonly profileSerivce: ProfileService,
    private readonly logService: LogService,
    private dataPointService:DataPointService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {

    this.templateService
      .templateData$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (template) => {
          this.mainTemplate = template;
        }, error: (err) => {
          this.logService.writeLog(JSON.stringify(err));
        }
      });
  }

  public getTierBackground(): [any, any, any] {
    const activeTiers = this.templateService.getActiveTiers(this.mainTemplate);

    return [
      activeTiers?.activeTier?.additionalBackground,
      activeTiers?.activeTier?.background,
      { color: this.mainTemplate.globalVariables.backgroundSecondary.color }
    ];
  }
}
