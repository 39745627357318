<ng-container *ngIf="!isLoading">
  <div class="ksk-modal-wrapper" [ngClass]="{
    'ofc-draw-disabled': isPending
  }" *ngIf="type === 'Campaign'" [ngStyle]="
styleService.getStyleRule(
  undefined,
  [tab.configurations.drawingDetails.background]
)
">
    <div class="ksk-modal-super-overlay" (click)="closeModal()">
    </div>
    <div class="ksk-modal-close" (click)="closeModal()"></div>
    <div [ngStyle]="styleService.getCloseButtonStyles(tab.configurations.drawingDetails?.closeButton)" class="ksk-modal-close-button" (click)="closeModal()"></div>
    <div class="ksk-modal-content">
      <div class="carousel-container" [style.height.px]="itemHeight">
      <div class="carousel-slide" *ngFor="let event of drawing.drawingEvents; let i = index" [ngClass]="{
        'prev': activeSlide > i + 1,
        'next': activeSlide < i + 1,
        'active': activeSlide == i + 1,
      }">
          <div class="drawing-header">
            <div class="drawing-image-wrapper">
              <img [src]="event.imageUrl" class="drawing-image" [ngStyle]="
                styleService.getStyleRule(
                  undefined,
                  undefined,
                  [tab.configurations.drawingDetails.imageBorder, undefined, mainTemplate.globalVariables.borders]
                )
                ">
            </div>
            <div class="drawing-header-info" [ngStyle]="
                styleService.getStyleRule(
                  undefined,
                  [tab.configurations.drawingDetails.detailBackground],
                  [tab.configurations.drawingDetails.detailBorder, undefined, mainTemplate.globalVariables.borders]
                )
                ">
              <p class="drawing-header-count" [ngStyle]="
                    styleService.getStyleRule(
                      [tab.configurations.drawingDetails.detailEntry]
                    )
                    ">
                {{
                (drawing.entriesAmount  + (drawing.ucBalance ?? 0) | number )
                | conditionalUppercase
                : styleService.getUppercaseValue(tab.configurations.drawingDetails.detailEntry)
                }}
              </p>
              <p class="drawing-header-text" [ngStyle]="
                    styleService.getStyleRule(
                      [tab.configurations.drawingDetails.detailText, undefined, 'heading2']
                    )
                    ">{{
                'Drawing Entries'
                | conditionalUppercase
                : styleService.getUppercaseValue(tab.configurations.drawingDetails.detailText)
                }}</p>
              <button class="drawing-header-button"
                *ngIf="!event.isActivated && !!drawing.entriesAmount && event.entriesActivationType !== 'AllEntries'"
                [disabled]="event.status === 'LiveEarnPeriod' || (drawing.entriesAmount - (drawing.activatedEntriesAmount ?? 0)) <= 0"
                (click)="drawingActivate(event)" [ngStyle]="
                        styleService.getStyleRule(
                          [tab.configurations.drawingDetails.buttonActivate.text],
                          [tab.configurations.drawingDetails.buttonActivate.background],
                          [tab.configurations.drawingDetails.buttonActivate.border]
                        )
                      ">{{
                'Activate'
                | conditionalUppercase
                : styleService.getUppercaseValue(tab.configurations.drawingDetails.buttonActivate.text)
                }}</button>
              <button class="drawing-header-button drawing-header-button__activated" *ngIf="event.isActivated"
                [ngStyle]="
            styleService.getStyleRule(
              [tab.configurations.drawingDetails.buttonActivated.text],
              [tab.configurations.drawingDetails.buttonActivated.background],
              [tab.configurations.drawingDetails.buttonActivated.border]
            )
          ">
                {{
                'Activated!'
                | conditionalUppercase
                : styleService.getUppercaseValue(tab.configurations.drawingDetails.buttonActivated.text)
                }}
              </button>
            </div>
          </div>
          <div class="drawing-body">
            <h1 class="drawing-body-title" [ngStyle]="
                styleService.getStyleRule(
                  [tab.configurations.drawingDetails.title, undefined, 'heading2']
                )
                ">{{
              event.name
              | conditionalUppercase
              : styleService.getUppercaseValue(tab.configurations.drawingDetails.title)
              }}</h1>
            <h3 class="drawing-body-subtitle" [ngStyle]="
                styleService.getStyleRule(
                  [tab.configurations.drawingDetails.date, undefined, 'heading4']
                )
                ">{{
              event.drawingDate
              | conditionalUppercase
              : styleService.getUppercaseValue(tab.configurations.drawingDetails.date)
              }}</h3>
            <p class="drawing-body-text" [innerHtml]="sanitizeHtml(event.description) | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.drawingDetails.description)" [ngStyle]="
                styleService.getStyleRule(
                  [tab.configurations.drawingDetails.description, undefined, 'mainText']
                )
                "></p>
          </div>
      </div>
    </div>
      <div class="ksk-modal-content__swiper-footer">
        <button (click)="changeSlide(false)" [disabled]="activeSlide === 1"
          class="ksk-modal-content__swiper-footer--btn ksk-modal-content__swiper-footer--prev" [ngStyle]="
          activeSlide === 1 ? styleService.getStyleRule(
        [tab.configurations.drawingDetails.sliderButtonActivate.text],
        [tab.configurations.drawingDetails.sliderButtonActivate.background],
        [tab.configurations.drawingDetails.sliderButtonActivate.border]
      ) : styleService.getStyleRule(
        [tab.configurations.drawingDetails.sliderButtonInactive.text],
        [tab.configurations.drawingDetails.sliderButtonInactive.background],
        [tab.configurations.drawingDetails.sliderButtonInactive.border]
      )
    ">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <span class="ksk-modal-content__swiper-footer--text" [ngStyle]="styleService.getStyleRule(
        [tab.configurations.drawingDetails.sliderText]
      )">
          {{ activeSlide }}/{{ drawing.drawingEvents.length }}
        </span>
        <button (click)="changeSlide(true)" [disabled]="activeSlide === drawing.drawingEvents.length"
          class="ksk-modal-content__swiper-footer--btn ksk-modal-content__swiper-footer--next" [ngStyle]="
          activeSlide === drawing.drawingEvents.length ? styleService.getStyleRule(
        [tab.configurations.drawingDetails.sliderButtonActivate.text],
        [tab.configurations.drawingDetails.sliderButtonActivate.background],
        [tab.configurations.drawingDetails.sliderButtonActivate.border]
      ) : styleService.getStyleRule(
        [tab.configurations.drawingDetails.sliderButtonInactive.text],
        [tab.configurations.drawingDetails.sliderButtonInactive.background],
        [tab.configurations.drawingDetails.sliderButtonInactive.border]
      )
    ">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="ksk-modal-wrapper" *ngIf="type === 'FreeEntry'" [ngStyle]="
styleService.getStyleRule(
  undefined,
  [tab.configurations.freeEntryDetails.background]
)
">
    <div class="ksk-modal-super-overlay" (click)="closeModal()">
    </div>
    <div class="ksk-modal-close" (click)="closeModal()"></div>
    <div [ngStyle]="styleService.getCloseButtonStyles(tab.configurations.freeEntryDetails?.closeButton)" class="ksk-modal-close-button" (click)="closeModal()"></div>

    <div class="ksk-modal-content">
      <div class="drawing-header">
        <div class="drawing-image-wrapper">
          <img [src]="drawing.freeEntryImageUrl" class="drawing-image" [ngStyle]="
            styleService.getStyleRule(
              undefined,
              undefined,
              [tab.configurations.freeEntryDetails.imageBorder, undefined, mainTemplate.globalVariables.borders]
            )
            ">
        </div>
        <div class="drawing-header-info" [ngStyle]="
      styleService.getStyleRule(
        undefined,
        [tab.configurations.freeEntryDetails.detailBackground],
        [tab.configurations.freeEntryDetails.detailBorder, undefined, mainTemplate.globalVariables.borders]
      )
      ">
          <p class="drawing-header-count" [ngStyle]="
      styleService.getStyleRule(
        [tab.configurations.freeEntryDetails.detailEntry, undefined, 'heading2']
      )
      ">
            {{
            '1'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.freeEntryDetails.detailEntry)
            }}
          </p>
          <p class="drawing-header-text" [ngStyle]="
      styleService.getStyleRule(
        [tab.configurations.freeEntryDetails.detailText]
      )
      ">{{
            'Free Entry'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.freeEntryDetails.detailText)
            }}</p>
          <button class="drawing-header-button" *ngIf="!isActivated" (click)="freeEntryActivate()" [ngStyle]="
            styleService.getStyleRule(
              [tab.configurations.freeEntryDetails.buttonActivate.text],
              [tab.configurations.freeEntryDetails.buttonActivate.background],
              [tab.configurations.freeEntryDetails.buttonActivate.border]
            )
          ">{{
            'Activate'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.freeEntryDetails.buttonActivate.text)
            }}</button>
          <button class="drawing-header-button drawing-header-button__activated" *ngIf="isActivated" [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.freeEntryDetails.buttonActivated.text],
                [tab.configurations.freeEntryDetails.buttonActivated.background],
                [tab.configurations.freeEntryDetails.buttonActivated.border]
              )
            ">{{
            'Activated!'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.freeEntryDetails.buttonActivated.text)
            }}</button>
        </div>
      </div>
      <div class="drawing-body">
        <h1 class="drawing-body-title" [ngStyle]="
            styleService.getStyleRule(
              [tab.configurations.freeEntryDetails.title, undefined, 'heading2']
            )
            ">{{
          drawing.title
          | conditionalUppercase
          : styleService.getUppercaseValue(tab.configurations.freeEntryDetails.title)
          }}</h1>
        <p class="drawing-body-text" [innerHTML]="sanitizeHtml(drawing.freeEntryDescription)  | conditionalUppercase
        : styleService.getUppercaseValue(tab.configurations.freeEntryDetails.description)" [ngStyle]="
            styleService.getStyleRule(
              [tab.configurations.freeEntryDetails.description, undefined, 'mainText']
            )
            "></p>
      </div>
    </div>
  </div>

  <div class="ksk-modal-wrapper" *ngIf="type === 'Multiplier'" [ngStyle]="
styleService.getStyleRule(
  undefined,
  [tab.configurations.multiplierDetails.background]
)
">
    <div class="ksk-modal-super-overlay" (click)="closeModal()">
    </div>
    <div class="ksk-modal-close" (click)="closeModal()"></div>
    <div [ngStyle]="styleService.getCloseButtonStyles(tab.configurations.multiplierDetails?.closeButton)" class="ksk-modal-close-button" (click)="closeModal()"></div>

    <div class="ksk-modal-content">
      <div class="drawing-header">
        <div class="drawing-image-wrapper">
          <img [src]="multiplier.multiplierImageUri.value" class="drawing-image" [ngStyle]="
      styleService.getStyleRule(
        undefined,
        undefined,
        [tab.configurations.multiplierDetails.imageBorder, undefined, mainTemplate.globalVariables.borders]
      )
      ">
        </div>
        <div class="drawing-header-info" [ngStyle]="
styleService.getStyleRule(
  undefined,
  [tab.configurations.multiplierDetails.detailBackground],
  [tab.configurations.multiplierDetails.detailBorder, undefined, mainTemplate.globalVariables.borders]
)
">
          <p class="drawing-header-count" [ngStyle]="
        styleService.getStyleRule(
          [tab.configurations.multiplierDetails.detailEntry, undefined, 'heading2']
        )
        ">
            {{
            multiplier.rate + 'X'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.multiplierDetails.detailEntry)
            }}
          </p>
          <p class="drawing-header-text" [ngStyle]="
        styleService.getStyleRule(
          [tab.configurations.multiplierDetails.detailText]
        )
        ">{{
            'Drawing Multiplier'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.multiplierDetails.detailText)
            }}</p>
          <button class="drawing-header-button" *ngIf="!isActivated" (click)="multiplierActivate()" [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.multiplierDetails.buttonActivate.text],
                [tab.configurations.multiplierDetails.buttonActivate.background],
                [tab.configurations.multiplierDetails.buttonActivate.border]
              )
            ">{{
            'Activate'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.multiplierDetails.buttonActivate.text)
            }}</button>
          <button class="drawing-header-button drawing-header-button__activated" *ngIf="isActivated" [ngStyle]="
                styleService.getStyleRule(
                  [tab.configurations.multiplierDetails.buttonActivated.text],
                  [tab.configurations.multiplierDetails.buttonActivated.background],
                  [tab.configurations.multiplierDetails.buttonActivated.border]
                )
              ">{{
            'Activated!'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.multiplierDetails.buttonActivated.text)
            }}</button>
        </div>
      </div>
      <div class="drawing-body">
        <h1 class="drawing-body-title" [ngStyle]="
            styleService.getStyleRule(
              [tab.configurations.multiplierDetails.title, undefined, 'heading2']
            )
            ">{{
          drawing.title
          | conditionalUppercase
          : styleService.getUppercaseValue(tab.configurations.multiplierDetails.title)
          }}</h1>
        <p class="drawing-body-text" [innerHTML]="sanitizeHtml(multiplier.multiplierDescription) | conditionalUppercase
        : styleService.getUppercaseValue(tab.configurations.multiplierDetails.description)" [ngStyle]="
            styleService.getStyleRule(
              [tab.configurations.multiplierDetails.description, undefined, 'mainText']
            )
            "></p>
      </div>
    </div>
  </div>
  <div class="ksk-modal-wrapper" *ngIf="type === 'TierEntry'" [ngStyle]="
  styleService.getStyleRule(
    undefined,
    [tab.configurations?.tierEntryDetails?.background]
  )
  ">
    <div class="ksk-modal-super-overlay" (click)="closeModal()">
    </div>
    <div class="ksk-modal-close" (click)="closeModal()"></div>
    <div [ngStyle]="styleService.getCloseButtonStyles(tab.configurations.tierEntryDetails?.closeButton)" class="ksk-modal-close-button" (click)="closeModal()"></div>

    <div class="ksk-modal-content">
      <div class="drawing-header">
        <div class="drawing-image-wrapper">
          <img [src]="tierEntry.tierEntriesImageUri" class="drawing-image" [ngStyle]="
              styleService.getStyleRule(
                undefined,
                undefined,
                [tab.configurations.tierEntryDetails.imageBorder, undefined, mainTemplate.globalVariables.borders]
              )
              ">
        </div>
        <div class="drawing-header-info" [ngStyle]="
        styleService.getStyleRule(
          undefined,
          [tab.configurations.tierEntryDetails.detailBackground],
          [tab.configurations.tierEntryDetails.detailBorder, undefined, mainTemplate.globalVariables.borders]
        )
        ">
          <p class="drawing-header-count" [ngStyle]="
        styleService.getStyleRule(
          [tab.configurations.tierEntryDetails.detailEntry, undefined, 'heading2']
        )
        ">
            {{
            tierEntry?.amount
            | conditionalUppercase 
            : styleService.getUppercaseValue(tab.configurations.tierEntryDetails.detailEntry)
            }}
          </p>
          <p class="drawing-header-text" [ngStyle]="
        styleService.getStyleRule(
          [tab.configurations.tierEntryDetails.detailText]
        )
        ">{{
            'Drawing Tier Entries'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.tierEntryDetails.detailText)
            }}</p>
          <button class="drawing-header-button" *ngIf="!isActivated" (click)="tierEntryActivate()" [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.tierEntryDetails.buttonActivate.text],
                [tab.configurations.tierEntryDetails.buttonActivate.background],
                [tab.configurations.tierEntryDetails.buttonActivate.border]
              )
            ">{{
            'Activate'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.tierEntryDetails.buttonActivate.text)
            }}</button>
          <button class="drawing-header-button drawing-header-button__activated" *ngIf="isActivated" [ngStyle]="
                        styleService.getStyleRule(
                          [tab.configurations.tierEntryDetails.buttonActivated.text],
                          [tab.configurations.tierEntryDetails.buttonActivated.background],
                          [tab.configurations.tierEntryDetails.buttonActivated.border]
                        )
                      ">{{
            'Activated!'
            | conditionalUppercase
            : styleService.getUppercaseValue(tab.configurations.tierEntryDetails.buttonActivated.text)
            }}</button>
        </div>
      </div>
      <div class="drawing-body">
        <h1 class="drawing-body-title" [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.tierEntryDetails.title, undefined, 'heading2']
              )
              ">{{
          drawing.title
          | conditionalUppercase
          : styleService.getUppercaseValue(tab.configurations.tierEntryDetails.title)
          }}</h1>
        <p class="drawing-body-text" [innerHTML]="sanitizeHtml(tierEntry?.description ?? '')  | conditionalUppercase
          : styleService.getUppercaseValue(tab.configurations.tierEntryDetails.description)" [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.tierEntryDetails.description, undefined, 'mainText']
              )
              "></p>
      </div>
    </div>
  </div>
</ng-container>