import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "safeUrl" })
export class SafeUrl implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(content: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(content);
  }
}
