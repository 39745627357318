<ng-container>
  <div
    class="ksk-modal-wrapper"
    [ngStyle]="
      styleService.getStyleRule(
        undefined,
        [data.tab.configurations?.cardDrawingDetails?.background],
        undefined
      )
    "
  >
    <div class="ksk-modal-close" (click)="closeModal()"></div>
     <!-- <button 
        class="cmsDrawing-close-button" 
        tabindex="-1"
        (click)="closeModal()"
        [ngStyle]="
            styleService.getStyleRule(
              undefined,
              [
                data.tab.configurations?.cardDrawingDetails?.closedButtonSection
                  ?.background,
                data.mainTemplate.globalVariables.backgroundSecondary
              ],
              [
                data.tab.configurations?.cardDrawingDetails?.closedButtonSection
                  ?.border,
                data.mainTemplate.globalVariables.borders
              ]
            )
          "
          >
      <mat-icon>close</mat-icon>
    </button> -->

    <div class="ksk-modal-close-button" [ngStyle]="styleService.getCloseButtonStyles(data.tab.configurations.cardDrawingDetails?.closeButton)" (click)="closeModal()"></div>
    

    <div class="cmsDrawing">
      <div class="cmsDrawing-header">
        <div class="cmsDrawing-img-wrapper">
          <img
            [src]="data.drawing?.extendedDetails?.imageUrl"
            class="cmsDrawing-img"
            [ngStyle]="
              styleService.getStyleRule(undefined, undefined, [
                data.tab.configurations.cardDrawingDetails.imageBorder
              ])
            "
          />
        </div>

        <div
          class="cmsDrawing-info"
          [ngStyle]="
            styleService.getStyleRule(
              undefined,
              [
                data.tab.configurations?.cardDrawingDetails?.buttonSection
                  ?.background,
                data.mainTemplate.globalVariables.backgroundSecondary
              ],
              [
                data.tab.configurations?.cardDrawingDetails?.buttonSection
                  ?.border,
                data.mainTemplate.globalVariables.borders
              ]
            )
          "
        >
          <ng-container *ngIf="!isLoading; else loaderTemplate">
            <div *ngIf="data.drawing.extendedDetails.printDescription">
              <p
                class="cmsDrawing-qr-text"
                [ngStyle]="
                  styleService.getStyleRule([
                    data.tab.configurations.cardDrawingDetails.printDescription,
                    undefined,
                    'heading4'
                  ])
                "
                [innerHTML]="
                  data.drawing.extendedDetails.printDescription
                    | conditionalUppercase
                      : styleService.getUppercaseValue(
                          data.tab.configurations.cardDrawingDetails
                            .printDescription
                        )
                "
              ></p>
            </div>

            <p 
              class="cmsDrawing-qr-text"
              [ngStyle]="
                styleService.getStyleRule([
                  data.tab.configurations.cardDrawingDetails.balanceDetailText,
                  undefined,
                  'heading4'
                ])
              "
            >
              <span *ngIf="data.drawing.balance">
                {{
                  data.tab.configurations.cardDrawingDetails.balanceDetail
                    | conditionalUppercase
                      : styleService.getUppercaseValue(
                          data.tab.configurations.cardDrawingDetails
                            .balanceDetailText
                        )
                }}

                {{
                  data.drawing.balance
                    | conditionalUppercase
                      : styleService.getUppercaseValue(
                          data.tab.configurations.cardDrawingDetails
                            .balanceDetailText
                        )
                }}
              </span>
            </p>

            <div
              class="cmsDrawing-description"
              [ngStyle]="
                styleService.getStyleRule([
                  data.tab.configurations.cardDrawingDetails.text,
                  undefined,
                  'mainText'
                ])
              "
              [ngClass]="{
                uppercase: styleService.getUppercaseValue(
                  data.tab.configurations.cardDrawingDetails.text
                )
              }"
            >
              {{
                data.tab?.configurations?.cardDrawingDetails?.validTillPrefix
              }}
              {{ data.drawing.endDate | date }}
            </div>
          </ng-container>
        </div>
      </div>

      <h1
        class="cmsDrawing-name"
        [ngStyle]="
          styleService.getStyleRule([
            data.tab.configurations.cardDrawingDetails.title,
            undefined,
            'heading2'
          ])
        "

[innerHTML]="
(
  data.drawing.extendedDetails.title
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  data.tab.configurations.cardDrawingDetails.title
                )
      ) "

      >
      
      
      </h1>
      <div
        class="cmsDrawing-description"
        [ngStyle]="
          styleService.getStyleRule([
            data.tab.configurations.cardDrawingDetails.text,
            undefined,
            'mainText'
          ])
        "
        [ngClass]="{
          uppercase: styleService.getUppercaseValue(
            data.tab.configurations.cardDrawingDetails.text
          )
        }"
        [innerHtml]="data.drawing.extendedDetails.description"
      ></div>

      <div
        class="cmsDrawing-description"
        [ngStyle]="
          styleService.getStyleRule([
            data.tab.configurations.cardDrawingDetails.text,
            undefined,
            'mainText'
          ])
        "
        [ngClass]="{
          uppercase: styleService.getUppercaseValue(
            data.tab.configurations.cardDrawingDetails.text
          )
        }"
      >
        {{ data.tab?.configurations?.cardDrawingDetails?.validTillPrefix }}
        {{ data.drawing.endDate | date }}
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loaderTemplate>
  <div class="loader" *ngIf="isLoading">
    <mat-spinner
      *ngIf="!data.mainTemplate.globalVariables.loader?.imageLink?.length"
      [color]="data.mainTemplate.globalVariables.backgroundPrimary.color"
      [diameter]="styleService.isQt3Kiosk ? 100 : 200"
    ></mat-spinner>
    <img
      class="loader-image"
      *ngIf="data.mainTemplate.globalVariables.loader?.imageLink?.length"
      [src]="data.mainTemplate.globalVariables.loader.imageLink"
    />
  </div>
</ng-template>
