<div class="content">
  <!-- <div class="header">
    <div class="header-block">
      <h1 class="header-title">Confirm</h1>
    </div>
  </div> -->

  <div class="main">
    <div class="row">
      <div class="col-12">
        <p [ngStyle]="styleService.getStyleRule([data.tabData?.configurations?.popUp?.messageText,
        null,
        'heading3'
      ])">{{getConfirmMessage()}}</p>
      </div>
    </div>
  </div>

  <div class="footer">
    <button (click)="closeModal(true)" [ngStyle]="
      styleService.getStyleRule(
      [data.tabData?.configurations?.popUp?.buttonConfirm?.text],
      [
        data.tabData?.configurations?.popUp?.buttonConfirm?.background,
        { color: mainTemplate.globalVariables.buttonsColor.primaryColor }
      ],
      [data.tabData?.configurations?.popUp?.buttonConfirm?.border])">
      {{ "Confirm" | conditionalUppercase
      : styleService.getUppercaseValue(
      data.tabData?.configurations?.popUp?.buttonConfirm.text
      ) }}
    </button>
    <button (click)="closeModal(false)" [ngStyle]="
    styleService.getStyleRule(
    [data.tabData?.configurations?.popUp?.buttonCancel.text],
    [
      data.tabData?.configurations?.popUp?.buttonCancel?.background,
      { color: mainTemplate.globalVariables.buttonsColor.secondaryColor }
    ],
    [data.tabData?.configurations?.popUp?.buttonCancel?.border])"
      >
      {{ "Cancel" | conditionalUppercase
      : styleService.getUppercaseValue(
      data.tabData?.configurations?.popUp?.buttonCancel.text
      ) }}
    </button>
  </div>
</div>
