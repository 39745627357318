import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProfileService } from './profile.service';
import { Observable } from 'rxjs';
import { DataPoint } from '../models/data-point';
import { dataPointsValues } from '../../constants';
import { environment } from '../../environments/environment';
import { DecimalPipe } from '@angular/common';

interface MappedDatapoint {
  value: string,
  concatenationConfig: unknown[],
  matationFunction: string[],
  fallBackValue: string
}

@Injectable({
  providedIn: 'root',
})

export class DataPointService {

  public apiUrl = environment.apiUrl;

  public dataPointPaths: { [x: string]: MappedDatapoint; } = {};

  /**TODO: remove after migration old kiosks */
  public oldDataPointPaths: { [x: string]: any; } = {};

  public get playerProfile() {
    return this.profileService.playerProfile
  };

  public get playerBalances() {
    return this.profileService.playerBalances
  };

  public get playerActivity() {
    return this.profileService.playerActivity
  }

  public dataPointConfigurationId: string = '';

  public dataPointsList: DataPoint[] = [];

  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private decimalPipe: DecimalPipe
  ) {
  };

  public getDataPointsConfiguration(): Observable<DataPoint[]> {
    return this.http.get(`${this.apiUrl}/api/DataPoint/all`) as Observable<DataPoint[]>;
  };

  public getValueByDataPointProperty(datapointName: string): string {
    const isOldPath = !!this.oldDataPointPaths[datapointName];
    if (isOldPath) {
      return this.oldDpConfigFallback(datapointName);
    }
    let dataPoint: MappedDatapoint = this.dataPointPaths[datapointName];
    if (!dataPoint) {
      return 'N/A'
    }
    let evalValue: string = `this.${dataPoint.value}`;
    try {

      let resolvedValue = eval(evalValue);
      if (!resolvedValue && (resolvedValue != 0)) {
        return (dataPoint.fallBackValue ?? 'N/A');
      };

      if (dataPoint.matationFunction) {
        (dataPoint.matationFunction as string[]).forEach((f: string) => {
          resolvedValue = this.handleCalculatedFields(resolvedValue, f);
        });
      };
      if (dataPoint.concatenationConfig) {
        let concatValuesArray: string[] = [];
        dataPoint.concatenationConfig.forEach((concatValue: any) => {
          let concatEvalValue = `this.${concatValue.command}.${concatValue.path}`;
          let concatResolvedValue = eval(concatEvalValue);
          if (concatValue?.postFunction?.length) {
            concatValue.postFunction.forEach((f: any) => {
              concatResolvedValue = this.handleCalculatedFields(concatResolvedValue, f)
            });
          };
          concatValuesArray.push(concatValue.concotenator);
          concatValuesArray.push(concatResolvedValue);
        });

        return `${resolvedValue}${concatValuesArray?.join('')}`;
      };
      if (!resolvedValue) {
        return this.oldDpConfigFallback(datapointName);
      }
      return !!resolvedValue ? resolvedValue : (dataPoint.fallBackValue ?? 'N/A');
    } catch (error) {
      return dataPoint?.fallBackValue ?? 'N/A';
    }
  };

  public async mapDatapointPaths() {
    const parseJsonConfig = (configuration: string) => {
      const parsedConfig = JSON.parse(configuration);
      const config = parsedConfig.find((conf: any) => conf.connector === this.dataPointConfigurationId);
      return config;
    }
    this.dataPointPaths = this.dataPointsList.reduce((result: { [x: string]: MappedDatapoint; }, item: any) => {
      const config = parseJsonConfig(item.connectorConfigurationJson);
      result[item.id] = {
        value: `${config?.command}.${config?.path}`,
        concatenationConfig: config?.configForm,
        matationFunction: config?.postFunction,
        fallBackValue: item?.fallback
      };
      return result;
    }, {});
    console.log(this.dataPointPaths)
  };

  public mapOldDatapointsPaths() {
    const { values } = dataPointsValues;
    this.oldDataPointPaths = values.reduce((result: { [x: string]: any; }, item: any) => {
      result[item.name] = {
        value: this.profileService.isUcSecondVersion ? item.valueV2 : item.valueV1,
        options: item?.options ?? null
      };
      return result;
    }, {});
  };

  private handleCalculatedFields(value: string, mutationFinction: string) {
    switch (mutationFinction) {
      case 'BirthDateToAge':
        return this.calculateAge(value);
      case 'NumberToLocaleString':
        return this.formatNumberToLocaleString(value);
      case 'BirthDateToMonth':
        return this.calculateBirthMonth(value);
      case 'NumberToLocaleString+TwoDecimalPlaces':
        return this.formatNumberToLocaleNumber(value)
    };
  };

  private calculateAge(value: string) {
    try {
      const birthDate = new Date(value);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear() -
        (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate()) as any);
      return age.toString();
    } catch (error) {
      return 'N/A'
    };
  };

  private formatNumberToLocaleString(value: string) {
    if (!value) return 0;
    return Number(value).toLocaleString('en-US');
  };

  private formatNumberToLocaleNumber(value: string | number) {
    value = value.toString();
    const thousandsFormat = value.split('.')[0].length;
    return this.decimalPipe.transform(value, `${thousandsFormat}.2-2`);
  };

  private calculateBirthMonth(value: string) {
    const date = new Date(value);
    const month = date.toLocaleString('default', { month: 'long' });
    return month;
  };

  private oldDpConfigFallback(dataPoint: string) {
    const { values } = dataPointsValues;
    const dataPointObj = values.find((v: any) => v.name === dataPoint);
    if (!dataPointObj) {
      return;
    };
    const path = this.profileService.isUcSecondVersion ? dataPointObj.valueV2 : dataPointObj.valueV1;
    const evalPath = `this.${path}`;
    try {
      let evaluatedValue = eval(evalPath);
      if (dataPointObj?.options) {
        dataPointObj?.options.forEach((option: string) => {
          evaluatedValue = this.handleCalculatedFields(evaluatedValue, option);
        });
      }
      return evaluatedValue;
    } catch (error) {
      return 'N/A';
    }
  };
}
