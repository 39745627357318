import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { StyleService, TemplateService } from '../../services';
import { Snackbar, Snackbars } from '../../models';
import { take } from 'rxjs';

@Component({
  selector: 'app-snackbar',
  styleUrls: ['./snackbar.component.scss'],
  templateUrl: './snackbar.component.html',
})
export class SnackbarComponent {
  public isLoading = true;
  public snackBar: Snackbar;
  private snackBarsConfig: Snackbars;

  constructor(
    private readonly templateService: TemplateService,
    public readonly styleService: StyleService,
    private readonly snackBarRef: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.snackBarsConfig = template?.components?.snackbars;
        this.snackBar = (this.snackBarsConfig as any)?.[this.data.type] as Snackbar;

        this.isLoading = false;
      });
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
