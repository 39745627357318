<ng-container *ngIf="mainTemplate">
  <div class="content" [ngStyle]="
      styleService.getStyleRule(undefined, [
        mainTemplate.globalVariables.backgroundPrimary
      ])
    ">
    <div class="header">
      <h1 *ngIf="mainTemplate.template.welcomeMessage" class="header-title" [ngStyle]="
          styleService.getStyleRule([
            mainTemplate.template.welcomeMessage.configurations,
            mainTemplate.components.welcomeMessage,
            'heading1'
          ])
        ">
        {{
        (mainTemplate.template.welcomeMessage.messagePrefix || '') + " "+
        getValueByDataPoint(mainTemplate.template.welcomeMessage)
        | conditionalUppercase
        : styleService.getUppercaseValue(
        mainTemplate.template.welcomeMessage.configurations,
        mainTemplate.components.welcomeMessage
        )
        }}
      </h1>

      <app-sign-out></app-sign-out>
    </div>

    <div class="wrapper-cards">
      <div class="card-item" *ngFor="let point of mainTemplate.template.dataPoints" [ngStyle]="
          styleService.getStyleRule(
            undefined,
            [
              point?.configurations?.background,
              mainTemplate.components.dataPoints?.configurations?.background,
              'undefined'
            ],
            [
              point?.configurations?.border,
              mainTemplate.components.dataPoints?.configurations?.border,
              mainTemplate.globalVariables.borders
            ]
          )
        ">
        <mat-icon aria-hidden="false" *ngIf="point.iconName && point.iconName.length" [fontIcon]="point.iconName"
          [ngStyle]="
        styleService.getIconStyleRule(
          [
          point.iconSize,
            mainTemplate.components?.dataPoints?.configurations?.icons?.size
          ],
          [
            point.iconColor,
              mainTemplate.components?.dataPoints?.configurations?.icons?.color
          ]
        )
      "></mat-icon>
        <h5 class="card-title" [ngStyle]="
            styleService.getStyleRule([
              point.configurations.title,
              mainTemplate.components.dataPoints?.configurations?.title,
              'heading5'
            ])
          ">
          {{
          point.title
          | conditionalUppercase
          : styleService.getUppercaseValue(
          point.configurations.title,
          mainTemplate.components.dataPoints?.configurations?.title
          )
          }}
        </h5>
        <p class="card-balance" [ngStyle]="
            styleService.getStyleRule([
              point.configurations.value,
              mainTemplate.components.dataPoints?.configurations?.value,
              'heading6'
            ])
          ">
          {{
          getValueByDataPoint(point)
          | conditionalUppercase
          : styleService.getUppercaseValue(
          point.configurations.value,
          mainTemplate.components.dataPoints?.configurations?.value
          )
          }}
        </p>
      </div>
    </div>

    <div class="profile">
      <div class="profile-card" *ngIf="!mainTemplate.template.tierLevelCard"></div>
      <div class="profile-card" *ngIf="mainTemplate.template.tierLevelCard" [ngStyle]="
          styleService.getStyleRule(
            undefined,
            mainTemplate.template.tierLevelCard.isUsedCurrentTierLvlImg
              ? getTierBackground()
              : [
                  mainTemplate.template.tierLevelCard.background,
                  mainTemplate.components?.tierLevelCard?.configurations?.background,
                  mainTemplate.globalVariables.backgroundSecondary
                ]
          )
        ">
        <div class="profile-card-item" *ngFor="let sheet of mainTemplate.template.tierLevelCard?.dataPoints">
          <h4 class="profile-card-item-title" *ngIf="sheet.title && sheet?.title?.length" [ngStyle]="
              styleService.getStyleRule([
                sheet?.configurations?.title,
                mainTemplate.components.tierLevelCard?.configurations?.title,
                'heading6'
              ])
            " [innerText]="
              sheet.title
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      sheet?.configurations?.title,
                      mainTemplate.components.tierLevelCard?.configurations?.title
                    )
            "></h4>
          <p class="profile-card-item-content" *ngIf="
              sheet.dataPoint &&
              sheet.dataPoint.length &&
              sheet.dataPoint !== 'image'
            " [ngStyle]="
              styleService.getStyleRule([
                sheet?.configurations?.value,
                mainTemplate.components.tierLevelCard?.configurations?.value,
                'heading3'
              ])
            " [innerText]="
              getValueByDataPoint(sheet)
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      sheet?.configurations?.value,
                      mainTemplate.components.tierLevelCard?.configurations?.value
                    )
            "></p>
          <img class="profile-card-item-content" *ngIf="sheet.image.imageLink && sheet.image.imageLink.length"
            [width]="sheet.image.width" [src]="sheet.image.imageLink" />
        </div>
      </div>

      <div class="profile-content">
        <div class="profile-content-item" *ngFor="let info of mainTemplate.template.additionalInfo" [ngStyle]="
            styleService.getStyleRule(
              undefined,
              [
                info.configurations.background,
                mainTemplate.components?.additionalInformation?.background,
                'undefined'
              ],
              [
                info.configurations.border,
                mainTemplate.components?.additionalInformation?.border,
                mainTemplate.globalVariables.borders
              ]
            )
          ">
          <h3 class="profile-content-title" *ngIf="info?.title?.length" [ngStyle]="
              styleService.getStyleRule([
                info.configurations.title,
                mainTemplate.components?.additionalInformation?.title,
                'heading5'
              ])
            ">
            {{
            info.title
            | conditionalUppercase
            : styleService.getUppercaseValue(
            info.configurations.title,
            mainTemplate.components?.additionalInformation?.title
            )
            }}
          </h3>
          <p class="profile-content-value" *ngIf="info.dataPoint.length" [ngStyle]="
              styleService.getStyleRule([
                info.configurations.dataPoint,
                mainTemplate.components?.additionalInformation?.dataPoint,
                'heading5'
              ])
            ">
            {{
            getValueByDataPoint(info)
            | conditionalUppercase
            : styleService.getUppercaseValue(
            info.configurations.dataPoint,
            mainTemplate.components?.additionalInformation?.dataPoint
            )
            }}
          </p>
          <p class="profile-content-description" *ngIf="info.comment?.length" [ngStyle]="
              styleService.getStyleRule([
                info.configurations.comment,
                mainTemplate.components?.additionalInformation?.comment,
                'mainText'
              ])
            " [innerHtml]="info.comment
            | conditionalUppercase
            : styleService.getUppercaseValue(
            info.configurations.comment,
            mainTemplate.components?.additionalInformation?.comment
            )">
          </p>
        </div>
      </div>
    </div>

    <app-menu></app-menu>
  </div>
</ng-container>