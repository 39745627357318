import { ErrorHandler, Injectable } from '@angular/core';
import { InsightsLogsService } from './insights-logs.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {

    constructor(private logger: InsightsLogsService) {
        super();
    }

    override handleError(error: any) {
        this.logger.logException(error);
        super.handleError(error);
    }
}