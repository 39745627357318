import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  BalanceService,
  DrawingService,
  LogService,
  StyleService,
  TemplateService,
} from '../../services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Drawing } from '../../models';
import { MainTemplate, TemplateSetting } from '../../models/template';
import {
  DrawingEvent,
  DrawingMultiplierEntrie,
  DrawingTierEntry,
} from '../../models/drawing';
import { forkJoin, of, switchMap, take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-drawing-modal',
  templateUrl: './drawing-modal.component.html',
  styleUrls: ['./drawing-modal.component.scss'],
})
export class DrawingModalComponent implements OnInit {
  public type: 'Campaign' | 'FreeEntry' | 'Multiplier' | 'TierEntry';
  public drawing: Drawing;
  public tab: TemplateSetting;
  public multiplier: DrawingMultiplierEntrie;
  public tierEntry: DrawingTierEntry;
  public activeSlide = 1;

  public isPending = false;
  public isActivated = false;
  public isInactive = false;
  public isLoading = false;
  public mainTemplate!: MainTemplate;
  public itemHeight: number = 0;

  constructor(
    public readonly styleService: StyleService,
    public readonly balanceService: BalanceService,
    private readonly dialogRef: MatDialogRef<any>,
    private readonly drawingService: DrawingService,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackBar: MatSnackBar,
    private readonly logService: LogService,
    private readonly templateService: TemplateService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: 'Campaign' | 'FreeEntry' | 'Multiplier';
      tab: TemplateSetting;
      drawing: Drawing;
      multiplier: DrawingMultiplierEntrie;
      isActivated: boolean;
      isInactive: boolean;
      tierEntry: DrawingTierEntry;
    }
  ) {
    data.drawing.drawingEvents = data.drawing.drawingEvents.map(
      (value, index) => {
        if (value.isDateConverted) return value;

        value.drawingDate = new Date(value.drawingDate).toLocaleString();
        value.isDateConverted = true;

        return value;
      }
    );

    this.type = data.type;
    this.drawing = data.drawing;
    this.tierEntry = data.tierEntry;
    this.tab = data.tab;
    this.multiplier = data.multiplier;
    this.isActivated = data.isActivated;
    this.isInactive = data.isInactive;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.isLoading = false;
        this.mainTemplate = template;

        setTimeout(() => {
          this.itemHeight = this.getItemsHeight();
        }, 0);
      });
  }

  public sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public getItemsHeight(): number {
    const slides = document.querySelectorAll('.carousel-slide');

    let maxHeight = 0;

    slides.forEach((slide) => {
      const height = (slide as any).offsetHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    return maxHeight;
  }

  public changeSlide(isIncrement: boolean): void {
    const nextSlide =
      this.activeSlide === this.drawing.drawingEvents.length
        ? 1
        : this.activeSlide + 1;
    const prevSlide =
      this.activeSlide === 1
        ? this.drawing.drawingEvents.length
        : this.activeSlide - 1;

    this.activeSlide = isIncrement ? nextSlide : prevSlide;
  }

  public closeModal(): void {
    this.dialogRef.close({
      drawing: this.drawing,
      multiplier: this.multiplier,
      tierEntry: this.tierEntry,
      isActivated: this.isActivated,
    });
  }

  trackByEventId(index: number, event: any): any {
    return event.drawingEventId;
  }

  public drawingActivate(event: DrawingEvent): void {
    this.isPending = true;
    this.drawingService
      .activateAllEntriesToDrawingEvent(this.drawing, event.drawingEventId)
      .pipe(
        switchMap((res) => {
          return forkJoin([of(res), this.drawingService.getListLiveDrawings()]);
        }),
        take(1)
      )
      .subscribe({
        next: ([res, drawings]) => {
          const updatedDrawing: Drawing = drawings.find(
            (x) => x.drawingId === this.drawing.drawingId
          ) as Drawing;
          this.drawing = {
            ...this.drawing,
            ...updatedDrawing,
          };

          const newEvent = this.drawing.drawingEvents.reduce((cEvent, x) => {
            x.isActivated = x.activatedEntries > 0;

            if (x.drawingEventId === event.drawingEventId) {
              cEvent = x;
            }

            return cEvent;
          }, {}) as DrawingEvent;

          this.drawing.activatedEntriesAmount =
            (this.drawing.activatedEntriesAmount ?? 0) + res.activatedEntries;
          newEvent.activatedEntries = res.activatedEntries;
          newEvent.isActivated = true;
          this.isPending = false;

          this.snackBar.openFromComponent(SnackbarComponent, {
            panelClass: 'success',
            duration: 5000,
            horizontalPosition: 'end',
            data: {
              type: 'success',
              message: 'Your entry has been Activated',
            },
          });
        },
        error: (err) => {
          this.logService.writeLog(JSON.stringify(err));
          this.isPending = false;
        },
      });
  }

  public freeEntryActivate(): void {
    this.drawingService
      .activateFreeEntry(this.drawing.drawingId)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.isActivated = true;
          this.snackBar.openFromComponent(SnackbarComponent, {
            panelClass: 'success',
            duration: 5000,
            horizontalPosition: 'end',
            data: {
              type: 'success',
              message: 'Your entry has been Activated',
            },
          });
        },
        error: (err) => {
          this.logService.writeLog(JSON.stringify(err));
        },
      });
  }

  public multiplierActivate(): void {
    this.drawingService.activateDrawingMultiplier(this.drawing.drawingId, (this.multiplier as DrawingMultiplierEntrie).id).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.balanceService.addMultiplier(this.drawing, (this.multiplier as DrawingMultiplierEntrie).rate);
        this.isActivated = true;
        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'success',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'success',
            message: 'Your multiplier has been activated'
          },
        });
      }, error: (err) => {
        this.logService.writeLog(JSON.stringify(err));
      }
    });
  }

  public tierEntryActivate(): void {
    this.drawingService
      .activateTierFreeEntry(this.tierEntry)
      .pipe(take(1))
      .subscribe(
        () => {
          this.isActivated = true;
          this.drawingService.showSuccesPopup(
            'Your tier entries has been Activated'
          );
        },
        (err) => {
          this.logService.writeLog(JSON.stringify(err));
        }
      );
  }
}
