<div class="content" [ngStyle]="
    styleService.getStyleRule(undefined, [
      tabData.configurations.main.background,
      mainTemplate.globalVariables.backgroundPrimary
    ])
  ">
  <div class="header">
    <div class="header-block">
      <h1 class="header-title" [ngStyle]="
          styleService.getStyleRule([
            currentPage.pageLinkContainerSettings.configurations.title,
            undefined,
            'heading3'
          ])
        ">
        <a class="header-title-back" (click)="closeModal()">
          <img src="/assets/images/last/back.svg" class="header-title-back-icon" />
        </a>
        {{
        "Address"
        | conditionalUppercase
        : styleService.getUppercaseValue(
        currentPage.pageLinkContainerSettings.configurations.title
        )
        }}
      </h1>
    </div>
  </div>

  <div class="main">
    <ng-container *ngIf="!profileService.isUcSecondVersion;else addressEditVersionTwo">
      <div class="row" *ngFor="let item of addresses; let i = index"
        [ngClass]="{ 'has-border': i + 1 < addresses.length }">
        <div class="col-6">
          <label class="label" [ngStyle]="
            styleService.getStyleRule([
              tabData.configurations.inputs.title,
              undefined,
              'heading4'
            ])
          ">{{
            "Address 1"
            | conditionalUppercase
            : styleService.getUppercaseValue(
            tabData.configurations.inputs.title
            )
            }}</label>
          <input type="text" [(ngModel)]="item.address1" class="input" placeholder="Placeholder" kioskInput />
        </div>
        <div class="col-6">
          <label class="label" [ngStyle]="
            styleService.getStyleRule([
              tabData.configurations.inputs.title,
              undefined,
              'heading4'
            ])
          ">{{
            "Address 2"
            | conditionalUppercase
            : styleService.getUppercaseValue(
            tabData.configurations.inputs.title
            )
            }}</label>
          <input type="text" [(ngModel)]="item.address2" class="input" placeholder="Placeholder" kioskInput />
        </div>
        <div class="col-4">
          <label class="label" [ngStyle]="
            styleService.getStyleRule([
              tabData.configurations.inputs.title,
              undefined,
              'heading4'
            ])
          ">{{
            "City"
            | conditionalUppercase
            : styleService.getUppercaseValue(
            tabData.configurations.inputs.title
            )
            }}</label>
          <input type="text" [(ngModel)]="item.city" class="input" placeholder="Placeholder" kioskInput />
        </div>
        <div class="col-4">
          <label class="label" [ngStyle]="
            styleService.getStyleRule([
              tabData.configurations.inputs.title,
              undefined,
              'heading4'
            ])
          ">{{
            "State"
            | conditionalUppercase
            : styleService.getUppercaseValue(
            tabData.configurations.inputs.title
            )
            }}</label>
          <input type="text" [(ngModel)]="item.state" class="input" placeholder="Placeholder" kioskInput />
        </div>
        <div class="col-4">
          <label class="label" [ngStyle]="
            styleService.getStyleRule([
              tabData.configurations.inputs.title,
              undefined,
              'heading4'
            ])
          ">{{
            "Zip Code"
            | conditionalUppercase
            : styleService.getUppercaseValue(
            tabData.configurations.inputs.title
            )
            }}</label>
          <input type="text" [(ngModel)]="item.zipCode" class="input" placeholder="Placeholder" kioskInput />
        </div>
      </div>
    </ng-container>



    <kiosk-keyboard [isAlwaysShown]="true"></kiosk-keyboard>

    <div class="footer">
      <button (click)="closeModal(true)" [ngStyle]="
          styleService.getStyleRule(
            [tabData.configurations.buttons.primaryButtons.text],
            [
              tabData.configurations.buttons.primaryButtons.background,
              { color: mainTemplate.globalVariables.buttonsColor.primaryColor }
            ],
            [tabData.configurations.buttons.primaryButtons.border]
          )
        ">
        {{
        "Confirm"
        | conditionalUppercase
        : styleService.getUppercaseValue(
        tabData.configurations.buttons.primaryButtons.text
        )
        }}
      </button>
      <button (click)="closeModal()" [ngStyle]="
          styleService.getStyleRule(
            [tabData.configurations.buttons.secondaryButtons.text],
            [
              tabData.configurations.buttons.secondaryButtons.background,
              {
                color: mainTemplate.globalVariables.buttonsColor.secondaryColor
              }
            ],
            [tabData.configurations.buttons.secondaryButtons.border]
          )
        ">
        {{
        "Cancel"
        | conditionalUppercase
        : styleService.getUppercaseValue(
        tabData.configurations.buttons.secondaryButtons.text
        )
        }}
      </button>
    </div>
  </div>

</div>

<ng-template #addressEditVersionTwo>
  <div class="row" *ngFor="let item of addresses; let i = index" [ngClass]="{ 'has-border': i + 1 < addresses.length }">
    <div class="col-6">
      <label class="label" [ngStyle]="
        styleService.getStyleRule([
          tabData.configurations.inputs.title,
          undefined,
          'heading4'
        ])
      ">{{
        "Address 1"
        | conditionalUppercase
        : styleService.getUppercaseValue(
        tabData.configurations.inputs.title
        )
        }}</label>
      <input type="text" [(ngModel)]="item.lineOne" class="input" placeholder="Placeholder" kioskInput />
    </div>
    <div class="col-6">
      <label class="label" [ngStyle]="
        styleService.getStyleRule([
          tabData.configurations.inputs.title,
          undefined,
          'heading4'
        ])
      ">{{
        "Address 2"
        | conditionalUppercase
        : styleService.getUppercaseValue(
        tabData.configurations.inputs.title
        )
        }}</label>
      <input type="text" [(ngModel)]="item.lineTwo" class="input" placeholder="Placeholder" kioskInput />
    </div>
    <div class="col-4">
      <label class="label" [ngStyle]="
        styleService.getStyleRule([
          tabData.configurations.inputs.title,
          undefined,
          'heading4'
        ])
      ">{{
        "City"
        | conditionalUppercase
        : styleService.getUppercaseValue(
        tabData.configurations.inputs.title
        )
        }}</label>
      <input type="text" [(ngModel)]="item.city" class="input" placeholder="Placeholder" kioskInput />
    </div>
    <div class="col-4">
      <label class="label" [ngStyle]="
        styleService.getStyleRule([
          tabData.configurations.inputs.title,
          undefined,
          'heading4'
        ])
      ">{{
        "State"
        | conditionalUppercase
        : styleService.getUppercaseValue(
        tabData.configurations.inputs.title
        )
        }}</label>
      <input type="text" [(ngModel)]="item.stateProvinceCode" class="input" placeholder="Placeholder" kioskInput />
    </div>
    <div class="col-4">
      <label class="label" [ngStyle]="
        styleService.getStyleRule([
          tabData.configurations.inputs.title,
          undefined,
          'heading4'
        ])
      ">{{
        "Zip Code"
        | conditionalUppercase
        : styleService.getUppercaseValue(
        tabData.configurations.inputs.title
        )
        }}</label>
      <input type="text" [(ngModel)]="item.postCode" class="input" placeholder="Placeholder" kioskInput />
    </div>
    <div class="row opt-out" *ngIf="tabData.templateConfig?.addressOptOut">
      <div class="col-6">
        <div class="opt-out-container">
          <div class="checkbox-container" (click)="setOptOutValue(item)"
            [ngClass]="{'checked ':item?.mailTo === 'false'}"
            [ngStyle]="getCheckboxContainerStyles(item?.mailTo === 'false')">
            <div class="checkmark" [ngStyle]="{'border-color':optOutConfigurations?.checkmarkColor }"></div>
          </div>
          <div class="opt-out-text">
            <span [ngStyle]="
        styleService.getStyleRule([
        optOutConfigurations.text,
          undefined,
          'heading4'
        ])">{{'Opt-out of mail marketing' | conditionalUppercase
              : styleService.getUppercaseValue(
              optOutConfigurations.text
              )}} </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>