import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, StyleService, TemplateService } from '../../services';
import { take, takeUntil } from 'rxjs/operators';
import { MainTemplate } from '../../models';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  public isLogoutRuning = false;
  public mainTemplate!: MainTemplate;
  public destroy$ = new Subject<boolean>;

  constructor (
    public readonly styleService: StyleService,
    private readonly templateService: TemplateService,
    private readonly authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.templateService
    .templateData$()
    .pipe(take(1))
    .subscribe((template) => {
      this.mainTemplate = template;
    });

    this.authService.isLogoutStarting.pipe(
      takeUntil(this.destroy$)
    ).subscribe((isLogoutStaring) => {
      this.isLogoutRuning = isLogoutStaring;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
