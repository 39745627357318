import { Injectable, Injector } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MainTemplate, PageLink, TemplateSetting } from '../../models/template';
import { StyleService, TemplateService } from '../../services';
import { take } from 'rxjs';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ProfileService } from '../../services/profile.service';
import cloneDeep from 'lodash/cloneDeep';

type viewModalType = 'email' | 'address' | 'phone' | null;
@Injectable()
export abstract class ViewModalComponent
  extends BaseComponent {
  public styleService: StyleService;
  public mainTemplate: MainTemplate = {} as MainTemplate;
  public currentPage: PageLink;
  public tabData: TemplateSetting;
  public propertyName: string;
  public cloneProfile: any;
  public profileService: ProfileService;
  public optOutConfigurations: any;

  private dialogRef: MatDialogRef<any>;
  private templateService: TemplateService;
  private dialog: MatDialog;

  public currentViewModalType: viewModalType = null;

  constructor(injector: Injector) {
    super(injector);

    this.dialogRef = injector.get(MatDialogRef);
    this.templateService = injector.get(TemplateService);
    this.styleService = injector.get(StyleService);
    this.dialog = injector.get(MatDialog);
    this.profileService = injector.get(ProfileService);

    const matData = injector.get(MAT_DIALOG_DATA);

    this.currentPage = matData.currentPage;
    this.tabData = matData.tabData;
    this.propertyName = matData.propertyName;
    this.optOutConfigurations = (this.tabData?.configurations?.main as any)?.optOut;
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.mainTemplate = template;
        this.getStyles();
      });

    this.cloneProfile = cloneDeep(this.profileService.playerProfile);
  }

  updateFields(): void { }

  public closeModal(save?: boolean): void {
    if (save && (this.tabData.templateConfig as any)?.[this.propertyName]?.isNeedConfirmation === true) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        panelClass: 'profile-dialog',
        id: 'confirmModal',
        height: '18vh',
        data: {
          tabData: this.tabData,
          modalName: this.propertyName
        }
      });

      dialogRef.afterClosed().subscribe((isConfirm) => {
        if (isConfirm) {
          if (this.profileService.isUcSecondVersion) {
            this.sendDataOnModalCloseUcVerTwo();
            return;
          }
          this.updateFields();
          this.dialogRef.close(this.cloneProfile);
        } else {
          this.dialogRef.close(null);
        }
      });

      return;
    }

    if (save && !(this.tabData.templateConfig as any)?.[this.propertyName]?.isNeedConfirmation === true) {

      if (this.profileService.isUcSecondVersion) {
        this.sendDataOnModalCloseUcVerTwo();
        return;
      }

      this.updateFields();
      this.dialogRef.close(this.cloneProfile);
      return;
    }

    this.dialogRef.close(null);
  }

  public getCheckboxContainerStyles(isChecked:boolean) {
    const { backgroundColor, border } = this.optOutConfigurations;
    return {
      'background': isChecked ? backgroundColor?.checked : backgroundColor?.unchecked,
      'border-radius': border?.borderRadius + 'px',
      'border-color': border?.borderColor,
      'border-width': border?.borderWidth + 'px',
    }
  }

  private sendDataOnModalCloseUcVerTwo() {
    this.updateFields();
    this.dialogRef.close({ clonedProfile: this.cloneProfile, templateType: this.currentViewModalType });
  }

  private getStyles(): void {
    const inputStyles = document.getElementById('inputStyles');
    if (!!inputStyles) {
      inputStyles.remove();
    }
    const body = document.getElementsByClassName('profile-dialog')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'inputStyles';

    const inputConfig = this.tabData.configurations.inputs;

    const titleFontFamily = inputConfig?.title?.fontFamily ? `font-family: ${inputConfig.title.fontFamily};` : '';
    const titleFontSize = inputConfig?.title?.fontSize ? `font-size: ${inputConfig.title.fontSize}px;` : '';
    const titleFontWeight = inputConfig?.title?.fontWeight ? `font-weight: ${inputConfig.title.fontWeight};` : '';
    const titleFontColor = inputConfig?.title?.fontColor ? `color: ${inputConfig.title.fontColor};` : '';
    const titleFontUppercase = inputConfig?.title?.isUpperCaseEnabled ? `text-transform: uppercase;` : '';

    const textFontFamily = inputConfig?.text?.fontFamily ? `font-family: ${inputConfig.text.fontFamily};` : '';
    const textFontSize = inputConfig?.text?.fontSize ? `font-size: ${inputConfig.text.fontSize}px;` : '';
    const textFontWeight = inputConfig?.text?.fontWeight ? `font-weight: ${inputConfig.text.fontWeight};` : '';
    const textFontColor = inputConfig?.text?.fontColor ? `color: ${inputConfig.text.fontColor};` : '';
    const textFontUppercase = inputConfig?.text?.isUpperCaseEnabled ? `text-transform: uppercase;` : '';

    const placeholderFontFamily = inputConfig?.placeholder?.fontFamily ? `font-family: ${inputConfig.placeholder.fontFamily};` : '';
    const placeholderFontSize = inputConfig?.placeholder?.fontSize ? `font-size: ${inputConfig.placeholder.fontSize}px;` : '';
    const placeholderFontWeight = inputConfig?.placeholder?.fontWeight ? `font-weight: ${inputConfig.placeholder.fontWeight};` : '';
    const placeholderFontColor = inputConfig?.placeholder?.fontColor ? `color: ${inputConfig.placeholder.fontColor};` : '';
    const placeholderFontUppercase = inputConfig?.placeholder?.isUpperCaseEnabled ? `text-transform: uppercase;` : '';

    const inputBorderColor = inputConfig?.borderColor?.default ? `border-color: ${inputConfig.borderColor.default};` : '';
    const inputActiveBorderColor = inputConfig?.borderColor?.active ? `border-color: ${inputConfig.borderColor.active};` : '';
    const inputBorderRadius = inputConfig?.mainConfig?.borderRadius ? `border-radius: ${inputConfig.mainConfig.borderRadius}px;` : '';
    const inputBorderSize = inputConfig?.mainConfig?.borderWidth ? `border-width: ${inputConfig.mainConfig.borderWidth}px;` : '';

    const inputBackground = inputConfig?.backgroundColor?.default ? `background: ${inputConfig.backgroundColor.default};` : '';
    const inputActiveBackground = inputConfig?.backgroundColor?.active ? `background: ${inputConfig.backgroundColor.active};` : '';

    const inlineStyles = `
      .profile-dialog .main .label {
        ${titleFontFamily}
        ${titleFontSize}
        ${titleFontWeight}
        ${titleFontColor}
        ${titleFontUppercase}
      }

      .profile-dialog .main .input {
        ${textFontFamily}
        ${textFontSize}
        ${textFontWeight}
        ${textFontColor}
        ${textFontUppercase}
        ${inputBorderColor}
        ${inputBorderRadius}
        ${inputBorderSize}
        ${inputBackground}
      }

      .profile-dialog .main .input:focus {
        ${inputActiveBackground}
        ${inputActiveBorderColor}
      }

      .profile-dialog .main .input::placeholder {
        ${placeholderFontFamily}
        ${placeholderFontSize}
        ${placeholderFontWeight}
        ${placeholderFontColor}
        ${placeholderFontUppercase}
      }
    `;

    style.appendChild(document.createTextNode(inlineStyles));
    body.appendChild(style);
  }
}
