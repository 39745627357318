import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BalanceService, DrawingService, LogService, StyleService, TemplateService } from '../../services';
import { Drawing, DrawingEventStatus } from '../../models';
import { MainTemplate, TemplateSetting } from '../../models/template';
import { DrawingEvent, DrawingMultiplierEntrie, DrawingTierEntry } from '../../models/drawing';
import { DrawingModalComponent } from '../drawing-modal/drawing-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, of, switchMap, take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-drawing',
  templateUrl: './drawing.component.html',
  styleUrls: ['./drawing.component.scss'],
})
export class DrawingComponent implements OnInit {
  @Input() public type!: 'Campaign' | 'FreeEntry' | 'Multiplier' | 'TierEntry';
  @Input() public drawing!: Drawing;
  @Input() public tab!: TemplateSetting;
  @Input() public multiplier?: DrawingMultiplierEntrie;
  @Input() public tierEntry!: DrawingTierEntry;

  @Output() onActivate: EventEmitter<any> = new EventEmitter();

  public isPending = false;
  public isLoading = false;
  public mainTemplate!: MainTemplate;
  public isActivated = false;
  public isInactive = false;

  constructor(
    public readonly styleService: StyleService,
    private readonly dialog: MatDialog,
    private readonly drawingService: DrawingService,
    private readonly snackBar: MatSnackBar,
    private readonly logService: LogService,
    private readonly templateService: TemplateService,
    public readonly balanceService: BalanceService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.templateService.templateData$().pipe(
      take(1)
    ).subscribe((template) => {
      this.isLoading = false;
      this.mainTemplate = template;
    });

    switch (this.type) {
      case 'Campaign':
        this.drawing.drawingEvents = this.drawing.drawingEvents.map((val: DrawingEvent) => {
          val.isActivated = val.activatedEntries > 0;

          return val;
        });
        break;
      case 'FreeEntry':
        this.isActivated = this.drawing.isFreeEntryClaimed;
        break;
    }
  }

  public openDetail(): void {
    const dialogRef = this.dialog.open(DrawingModalComponent, {
      data: {
        type: this.type,
        tab: this.tab,
        drawing: this.drawing,
        multiplier: this.multiplier,
        tierEntry: this.tierEntry,
        isActivated: this.isActivated,
        isInactive: this.isInactive
      },
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
      const { drawing, multiplier, isActivated, tierEntry } = result;

      if (isActivated !== this.isActivated) {
        this.onActivate.emit(true);
      }

      this.drawing = drawing;
      this.multiplier = multiplier;
      this.isActivated = isActivated;
      this.tierEntry = tierEntry
    });
  }

  public haveOnlyOneActiveEvent(): { onlyOneTrue: boolean, indexActiveEvent: number } {
    let response = {
      onlyOneTrue: false,
      indexActiveEvent: NaN
    };
    let trueCount = 0;

    this.drawing.drawingEvents.forEach((event, i) => {
      if (
        event.status === DrawingEventStatus.LiveEarnAndActivatePeriod && 
        new Date(event.drawingDate).getTime() <= new Date().getTime()
      ) {
        trueCount++;
        response.indexActiveEvent = i;
      }
    });

    response.onlyOneTrue = trueCount === 1;

    return response;
  }

  public drawingActivate(): void {
    this.isPending = true;
    const activeEvent = this.haveOnlyOneActiveEvent();
    const drawingEventId = this.drawing.drawingEvents[activeEvent.indexActiveEvent].drawingEventId;
    this.drawingService.activateAllEntriesToDrawingEvent(this.drawing, drawingEventId).pipe(
      switchMap((res) => {
        return forkJoin([of(res), this.drawingService.getListLiveDrawings()]);
      }),
      take(1)
    ).subscribe({
      next: ([res, drawings]) => {
        const updatedDrawing: Drawing = drawings.find(x => x.drawingId === this.drawing.drawingId) as Drawing;
        this.drawing = {
          ...this.drawing,
          ...updatedDrawing
        };

        const newEvent = this.drawing.drawingEvents.reduce((cEvent, x) => {
          x.isActivated = x.activatedEntries > 0;

          if (x.drawingEventId === drawingEventId) {
            cEvent = x;
          }

          return cEvent;
        }, {}) as DrawingEvent;

        this.drawing.activatedEntriesAmount = (this.drawing.activatedEntriesAmount ?? 0) + res.activatedEntries;
        newEvent.activatedEntries = res.activatedEntries;
        newEvent.isActivated = true;
        this.isPending = false;

        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'success',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'success',
            message: 'Your entry has been Activated'
          },
        });
      }, error: (err) => {
        this.isPending = false;
        this.logService.writeLog(JSON.stringify(
          `User  tried to activate all entries for drawing ${this.drawing.drawingEvents[0].drawingEventId};
          Error:${err}
          `));
      }
    });
  }

  public freeEntryActivate(): void {
    this.drawingService.activateFreeEntry(this.drawing.drawingId).pipe(take(1)).subscribe(() => {
      this.isActivated = true;
      this.snackBar.openFromComponent(SnackbarComponent, {
        panelClass: 'success',
        duration: 5000,
        horizontalPosition: 'end',
        data: {
          type: 'success',
          message: 'Your entry has been Activated'
        },
      });
      this.onActivate.emit(true);
    });
  }

  public tierEntryActivate(): void {
    this.drawingService.activateTierFreeEntry(this.tierEntry).pipe(take(1)).subscribe(() => {
      this.isActivated = true;
      this.drawingService.showSuccesPopup('Your tier entries has been Activated')
      this.onActivate.emit(true);
    });
  }

  public multiplierActivate(): void {
    this.drawingService.activateDrawingMultiplier(this.drawing.drawingId, (this.multiplier as DrawingMultiplierEntrie).id).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.balanceService.addMultiplier(this.drawing, (this.multiplier as DrawingMultiplierEntrie).rate);
        this.isActivated = true;
        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'success',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'success',
            message: 'Your multiplier has been activated'
          },
        });
        this.onActivate.emit(true);
      }, error: (err) => {
        this.logService.writeLog(JSON.stringify(

          `User  tried to activate multiplayer for drawing ${this.drawing.drawingId};
          Multiplayer Id:${this.multiplier?.id}
          Error:${JSON.stringify(err)}
          `));
      }
    });
  }
}
