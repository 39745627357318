import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Autoplay, SwiperOptions } from 'swiper';
import { CarouselItem, Snackbar } from '../../models';
import { LogService, TemplateService } from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  public isLoading = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private templateService: TemplateService,
    private snackBar: MatSnackBar,
    private logService: LogService
  ) { }

  public slides: CarouselItem[] = [];
  @ViewChildren('video') videoElements!: QueryList<ElementRef>;

  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    loop: true,
    scrollbar: { draggable: false },
    modules: [Autoplay],
  };

  ngOnInit(): void {
    this.getImages().subscribe((carousel: { items: any[]; slideInterval: string; }) => {
      if (carousel) {
        this.slides = carousel.items?.sort((a, b) => a.order - b.order)?.map((item) => {
          item.isVideo = item.contentType.includes('video');

          return item;
        });

        setTimeout(() => {
          this.muteVideos();
        });

        const delay = parseInt(carousel.slideInterval);
        if (delay > 0) {
          this.config.autoplay = {
            delay: delay * 1000
          };
        }
      }
      this.isLoading = false;

    }, (error: { title: string; errors: string[]; }) => {
      this.logService.writeLog(`${error?.title}: ${error?.errors?.[0]}`);
      this.isLoading = false;
    })
  }

  private muteVideos(): void {
    this.videoElements?.forEach((videoElement: ElementRef) => {
      const video = videoElement.nativeElement as HTMLVideoElement;
      video.muted = true;
    });
  }

  private getImages(): any {
    const { accountHash, carouselType } = this.activatedRoute.snapshot.params;
    return this.templateService.getCarouselImages(encodeURIComponent(accountHash), carouselType)
  }

}
