import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { SwiperModule } from 'swiper/angular';
import { QRCodeModule } from 'angularx-qrcode';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor, TokenInterceptor } from './interceptors';
import { SafeUrl, ConditionalUppercasePipe } from './pipes';
import { KeyboardModule } from './modules';
import {
  CardComponent,
  ConfirmModalComponent,
  ConfirmRedeemModalComponent,
  DrawingComponent,
  DrawingModalComponent,
  MenuComponent,
  OfferModalComponent,
  PinModalComponent,
  SignOutComponent,
  SliderComponent,
  StatusComponent,
  SnackbarComponent,
  PopUpComponent
} from './components';
import {
  HomeComponent,
  NotFoundComponent,
  TemplateComponent,
  ViewAddressesComponent,
  ViewEmailComponent,
  ViewNameComponent,
  ViewNickNameComponent,
  ViewPhoneComponent,
  ViewPinComponent,
} from './pages';
import { InputStylesDirective } from './directives/input-styles.directive';
import { CustomErrorHandlerService } from './services';
import { CarouselComponent } from './pages/carousel/carousel.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { CmsOfferCardComponent } from './components/cms-offer-card/cms-offer-card.component';
import { CmsOfferModalComponent } from './components/cms-offer-modal/cms-offer-modal.component';
import { DecimalPipe } from '@angular/common';
import { CmsDrawingCardComponent } from './components/cms-drawing-card/cms-drawing-card.component';
import { CmsDrawingModalComponent } from './components/cms-drawing-modal/cms-drawing-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    SafeUrl,
    ConditionalUppercasePipe,
    ConfirmModalComponent,
    SliderComponent,
    CardComponent,
    DrawingComponent,
    StatusComponent,
    SnackbarComponent,
    PopUpComponent,
    PinModalComponent,
    MenuComponent,
    SignOutComponent,
    HomeComponent,
    TemplateComponent,
    OfferModalComponent,
    ViewNameComponent,
    ViewNickNameComponent,
    ViewPhoneComponent,
    ViewPinComponent,
    ViewEmailComponent,
    ViewAddressesComponent,
    NotFoundComponent,
    ConfirmRedeemModalComponent,
    DrawingModalComponent,
    InputStylesDirective,
    CarouselComponent,
    MainLayoutComponent,
    CmsOfferCardComponent,
    CmsOfferModalComponent,
    CmsDrawingCardComponent,
    CmsDrawingModalComponent,
  ],
  exports: [],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatTabsModule,
    MatIconModule,
    MatSnackBarModule,
    SwiperModule,
    MatProgressSpinnerModule,
    KeyboardModule,
    QRCodeModule,
    PdfViewerModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CustomErrorHandlerService },
    DecimalPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
