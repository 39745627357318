import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { TemplateService } from './template.service';
import { Drawing } from '../models';
import {
  DrawingTierEntry,
  UCBalance,
  UCDrawingBalance,
} from '../models/drawing';
import { SnackbarComponent } from '../components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileService } from './profile.service';
import { CmsDrawingMerged } from "../models/cmsDrawing";

@Injectable({
  providedIn: 'root',
})
export class DrawingService {
  private apiUrl = `${environment.apiCobraUrl}/api/ext/drawings`;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly templateService: TemplateService,
    private snackBar: MatSnackBar,
    private profileService: ProfileService
  ) {}

  public getUCDrawingBalance(playerId: string): Observable<UCDrawingBalance[]> {
    return this.http
      .get<UCBalance>(
        `${this.profileService.universalConnectorSettings.host}/api/Balance/drawing/igt/${playerId}`
      )
      .pipe(
        map((data) => {
          return data.drawings;
        })
      );
  }

  public getListLiveDrawings(): Observable<Drawing[]> {
    const { age, tierLevelId } = this.getDrawingParams();
    const url = `${this.apiUrl}/live-v2`;
    //const url = `${this.apiUrl}/live${this.authService.isDebugEnabled ? '-v2' : ''}`;
    let params = new HttpParams();
    params = params.append('externalId', this.authService.externalId as string);
    params = params.append(
      'api_key',
      this.templateService.templateData.advancedSettings.oc2ApiKey as string
    );
    params = params.append('patronAge', `${age || ''}`);
    params = params.append('patronExternalTierId', `${tierLevelId || ''}`);

        return this.http.get<Drawing[]>(url, {
            params
        }).pipe(map((res) => {
            return res.sort((a, b) => (new Date(b.earnEntriesFrom).getTime()) - (new Date(a.earnEntriesFrom).getTime()));
        }));
    }

    public getCmsDrawing(): Observable<any> {
        return this.profileService.universalConnector.getCmsDrawingList() as Observable<any>;
      }
    
      public getCmsDrawingDetails(body: any): Observable<CmsDrawingMerged[]> {
        return this.http.post<CmsDrawingMerged[]>(`${environment.apiUrl}/api/KioskBuilder/drawing-decorators`, body);
    }
    
    public activateFreeEntry(drawingId: string): Observable<any> {
        const url = `${this.apiUrl}/${drawingId}/activate/freeEntry`;
        let params = new HttpParams();
        params = params.append('externalId', this.authService.externalId as string);
        params = params.append('api_key', this.templateService.templateData.advancedSettings.oc2ApiKey as string);

        return this.http.post(url, {}, {
            params
        });
    }

  public activateTierFreeEntry(tierEntry: DrawingTierEntry): Observable<any> {
    const url = `${this.apiUrl}/${tierEntry.drawingId}/activate/awardTierEntries`;
    let params = new HttpParams();
    params = params.append('externalId', this.authService.externalId as string);
    params = params.append(
      'api_key',
      this.templateService.templateData.advancedSettings.oc2ApiKey as string
    );
    params = params.append(
      'awardTierEntriesExternalId',
      tierEntry.awardTierEntriesExternalId
    );

    return this.http.post(
      url,
      {},
      {
        params,
      }
    );
  }

  public activateAllEntriesToDrawingEvent(
    drawing: Drawing,
    drawingEventId: string
  ): Observable<any> {
    const url = `${this.apiUrl}/${drawing.drawingId}/event/${drawingEventId}/activate/entries`;
    let params = new HttpParams();
    params = params.append('externalId', this.authService.externalId as string);
    params = params.append(
      'api_key',
      this.templateService.templateData.advancedSettings.oc2ApiKey as string
    );

    return this.http.post(
      url,
      {
        CurrentEntriesAmount:
          drawing.entriesAmount - (drawing?.activatedEntriesAmount ?? 0),
      },
      {
        params,
      }
    );
  }

  public activateDrawingMultiplier(
    drawingId: string,
    drawingMultiplierId: string
  ): Observable<any> {
    const url = `${this.apiUrl}/${drawingId}/activate/multipliers/${drawingMultiplierId}`;
    let params = new HttpParams();
    params = params.append('externalId', this.authService.externalId as string);
    params = params.append(
      'api_key',
      this.templateService.templateData.advancedSettings.oc2ApiKey as string
    );

    return this.http.post(
      url,
      {},
      {
        params,
      }
    );
  }

  public showSuccesPopup(message: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      panelClass: 'success',
      duration: 5000,
      horizontalPosition: 'end',
      data: {
        type: 'success',
        message,
      },
    });
  }

  private getDrawingParams() {
    let age: number;
    let tierLevelId: string;
    const dateOfBirth = this.profileService.isUcSecondVersion
      ? this.profileService.playerProfile.playerProfile.dateOfBirthday
      : this.profileService.playerProfile.birthDate;
    tierLevelId = this.profileService.isUcSecondVersion
      ? this.profileService.playerProfile.club.currentClub.suffix
      : this.profileService.playerProfile.gamingInfo.tierLevel.id;
    try {
      const birthDate = new Date(dateOfBirth);
      const today = new Date();
      age =
        today.getFullYear() -
        birthDate.getFullYear() -
        (today.getMonth() < birthDate.getMonth() ||
          ((today.getMonth() === birthDate.getMonth() &&
            today.getDate() < birthDate.getDate()) as any));
    } catch (error) {
      age = 0;
    }
    return { age, tierLevelId };
  }
}
