import { Component, Injector, OnInit } from '@angular/core';
import { ViewModalComponent } from 'projects/kiosk-app/src/app/components';

@Component({
  selector: 'app-view-name',
  templateUrl: './view-name.component.html',
  styleUrls: ['./view-name.component.scss'],
})
export class ViewNameComponent extends ViewModalComponent implements OnInit {
  public firstName: string;
  public middleName: string;
  public lastName: string;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.firstName = this.profileService.playerProfile.name.first;
    this.middleName = this.profileService.playerProfile.name.middle;
    this.lastName = this.profileService.playerProfile.name.last;
  }
}
