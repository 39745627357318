import { Injectable } from "@angular/core";
import { PopUpSchedule, ScheduleDays, WelcomePopUp } from "../models/template";
import { MatDialog } from "@angular/material/dialog";
import { PopUpComponent } from "../components";
import { take } from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class PopUpService {
    private daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'] as ScheduleDays[];

    constructor(private readonly dialog: MatDialog) {}

    public checkPopUp(welcomePopUp: WelcomePopUp, indexPage: number, indexTab: number): void {
        if (
            this.checkIfEnabled(welcomePopUp) &&
            this.checkIfSchedule(welcomePopUp, indexPage, indexTab) &&
            this.checkIfWeekdaySchedule(welcomePopUp)
        ) {
            if (welcomePopUp.schedule !== PopUpSchedule.Every) {
                this.writeDataInStorage(welcomePopUp.schedule, indexPage, indexTab);
            }

            this.dialog.open(PopUpComponent, {
                panelClass: 'pop-up-dialog',
                disableClose: true,
                backdropClass: ['backDropClass', 'popUpClass'],
                data: welcomePopUp
            });
        }
    }

    private checkIfEnabled(welcomePopUp: WelcomePopUp): boolean {
        return !!welcomePopUp?.isEnabled;
    }

    private checkIfSchedule(welcomePopUp: WelcomePopUp, indexPage: number, indexTab: number): boolean {
        let isNeedToOpen = true;

        switch (welcomePopUp.schedule) {
            case PopUpSchedule.PerSession:
                const sessionStorageData = sessionStorage.getItem(LOCAL_STORAGE_NAME);

                if (sessionStorageData) {
                    const openPopUps = JSON.parse(sessionStorageData) as OpenPopUp[];
                    const currentPopUp = openPopUps?.find(x => x.pageIndex === indexPage && x.tabIndex === indexTab);

                    isNeedToOpen = currentPopUp === undefined;
                }
                
                break;
            case PopUpSchedule.PerDay:
                const localStorageData = localStorage.getItem(LOCAL_STORAGE_NAME);

                if (localStorageData) {
                    const openPopUps = JSON.parse(localStorageData) as OpenPopUp[];
                    const currentPopUp = openPopUps?.find(x => x.pageIndex === indexPage && x.tabIndex === indexTab);
                    
                    isNeedToOpen = currentPopUp ? currentPopUp?.openDate !== this.getCurrentDay : true;
                }
                
                break;
            default:
                isNeedToOpen = true;

                break;
        }

        return isNeedToOpen;
    }

    private checkIfWeekdaySchedule(welcomePopUp: WelcomePopUp): boolean {
        let isNeedToOpen = true;

        if (welcomePopUp.weekdaySchedule.isEnabled) {
            const nowDay = this.getCurrentScheduleDay();

            isNeedToOpen = welcomePopUp.weekdaySchedule.selectedDays.includes(nowDay);
        }

        return isNeedToOpen;
    }

    private writeDataInStorage(schedule: PopUpSchedule, indexPage: number, indexTab: number): void {
        if (schedule === PopUpSchedule.PerSession) {
            const sessionStorageData = sessionStorage.getItem(LOCAL_STORAGE_NAME);
            const openPopUps = sessionStorageData ? JSON.parse(sessionStorageData) as OpenPopUp[] : [];

            openPopUps.push({
                pageIndex: indexPage,
                tabIndex: indexTab,
                openDate: this.getCurrentDay
            })

            sessionStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(openPopUps));
        } else {
            const localStorageData = localStorage.getItem(LOCAL_STORAGE_NAME);
            const openPopUps = localStorageData ? JSON.parse(localStorageData) as OpenPopUp[] : [];
            const currentPopUpIndex = openPopUps.findIndex(x => x.pageIndex === indexPage && x.tabIndex === indexTab);
            const newPopUpData = {
                pageIndex: indexPage,
                tabIndex: indexTab,
                openDate: this.getCurrentDay
            };

            if (currentPopUpIndex !== -1) {
                openPopUps[currentPopUpIndex] = newPopUpData;
            } else {
                openPopUps.push(newPopUpData);
            }

            localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(openPopUps));
        }
    }

    private get getCurrentDay(): string {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        
        return `${day}-${month}-${year}`;
    }

    private getCurrentScheduleDay(): ScheduleDays {
        const today = new Date();
        const dayIndex = today.getDay();
        return this.daysOfWeek[dayIndex];
    }
}

interface OpenPopUp {
    pageIndex: number;
    tabIndex: number;
    openDate: string;
}

export const LOCAL_STORAGE_NAME = 'opened-pop-ups';