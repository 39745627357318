import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { WelcomePopUp } from "../../models/template";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StyleService } from "../../services";

@Component({
    selector: 'app-pop-up',
    templateUrl: './pop-up.component.html',
    styleUrls: ['./pop-up.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PopUpComponent {
    public welcomePopUp: WelcomePopUp;

    constructor(
        public readonly styleService: StyleService,
        private readonly matDialogRed: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) private data: WelcomePopUp
    ) {
        this.welcomePopUp = data;
    }

    public closePopUp(): void {
        this.matDialogRed.close();
    }
}