import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionalUppercase',
})
export class ConditionalUppercasePipe implements PipeTransform {
  transform(value: any, args: boolean): any {
    if (args) {
      return value?.toString()?.toUpperCase();
    }
    return value;
  }
}
