<div class="snk-flex mat-mdc-simple-snack-bar" *ngIf="!isLoading">
  <mat-icon
    *ngIf="snackBar?.icon?.iconName && snackBar?.icon?.iconName?.length"
    aria-hidden="false"
    [fontIcon]="snackBar.icon.iconName"
    [ngStyle]="
      styleService.getIconStyleRule([snackBar.icon.iconSize], [snackBar.icon.iconColor])
    "
  ></mat-icon>
  <div class="mat-mdc-snack-bar-label mdc-snackbar__label">
    {{ data.message }}
  </div>
</div>
