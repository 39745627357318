import { Component, OnInit } from '@angular/core';
import { AuthService, LogService } from '../../services';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private readonly authService: AuthService, private readonly logService: LogService) {}

  ngOnInit(): void {
    this.logService.writeLog('Init 404 page');
    
    setTimeout(() => {
      this.goExit();
    }, 5000);
  }

  public goExit(): void {
    this.authService.logout();
  }
}
