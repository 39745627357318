import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UniversalConnector } from '../../assets/UniversalConnector';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, catchError, from, map, of } from 'rxjs';
import { LogService } from './logs.service';
import { UniversalConnectorVersionTwoDirective } from '../directives/universal-connector-version-two.directive';
import { HttpClient } from '@angular/common/http';
import { UcVersion } from '../models/template';
import { dataPointsValues } from '../../constants';
import { SnackbarComponent } from '../components';

enum UpdateProfileTypes {
    ADDRES = 'addresses',
    EMAIL = 'emails',
    PHONE = 'phones'
}

enum UpdateProfileVersionOneTypes {
    ADDRES = 'addresses',
    EMAIL = 'emails',
    PHONE = 'phoneNumbers'
}

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    public updateProfile$ = new Subject<any>();

    public playerProfile: any = {};

    public playerBalances: any = {};

    public playerActivity: any = {};

    public universalConnector: any = null;

    public universalConnectorSettings: any = null;

    public ucVersion: string = '';

    public cmsName: string = '';

    public playerBalancesWinLoss: any = {}; 


    public get isUcSecondVersion() {
        return this.ucVersion === UcVersion.Version_2;
    }

    public get playerAccountPointBalance() {
        if (this.isUcSecondVersion) {
            return this.playerBalances?.a_Points.amount;
        }
        return this.playerProfile.gamingInfo.PlayerAccountBalance;
    }

    constructor(
        private readonly router: Router,
        private readonly snackBar: MatSnackBar,
        private readonly logsService: LogService,
        public http: HttpClient,
    ) {
    }

    public async doBalanceAdjustment(playerId: string, points: number | null, balanceType: string | null, adjustmentType: string | null, balanceToUpdate?: any) {
        
        
        const self = this;
        if (!balanceToUpdate) {
            balanceToUpdate = self.isUcSecondVersion ? self.playerBalances?.a_Points : null;
        };
        const apiCall: Promise<any> = self.isUcSecondVersion ? self.universalConnector.doBalanceAdjustment(
            playerId,
            points,
            balanceType,
            adjustmentType,
            balanceToUpdate
        ) :
            self.universalConnector.doBalanceAdjustment(
                playerId,
                balanceType,
                adjustmentType,
                points
            );

        const { status, result, errorDetails } = await apiCall;
        if (!status || errorDetails) {
            await this.getProfile(this.universalConnectorSettings.host, playerId);
        }

        if (status || errorDetails) {
            self.snackBar.openFromComponent(SnackbarComponent, {
                panelClass: 'error',
                duration: 5000,
                horizontalPosition: 'end',
                data: {
                    type: 'error',
                    message: status || errorDetails,
                },
            });
            self.logsService.writeLog(JSON.stringify(`
            Adjust balance from UC: endpoint:/api/PlayerBalanceAdjustment/;
            Date:${new Date()};
            PatronID:${playerId};
            Adjustment details:balance type${balanceType},adjustment type:${adjustmentType},value:${points};
            Errors:${errorDetails} ${status}
            `));
        }
        return { status, result, error: errorDetails };
    };

    public async getProfile(connectorUrl: string, patronId: string): Promise<void> {
        if (!connectorUrl) {
            this.logsService.writeLog(JSON.stringify(`${new Date()} Universal connector url was not configured `));
            this.router.navigate(['/404']);
            return;
        }
        const self = this;
        if (!this.universalConnector) {
            switch (this.ucVersion) {
                case UcVersion.Version_1:
                    this.universalConnector = new UniversalConnector(connectorUrl);
                    break;
                case UcVersion.Version_2:
                    this.universalConnector = new UniversalConnectorVersionTwoDirective(connectorUrl, self.cmsName, this.http, this.snackBar);
                    this.universalConnector.patronId = patronId;
                    break;
                default:
                    this.universalConnector = new UniversalConnector(connectorUrl);
                    break;
            }
        }
        const promises: Promise<any>[] = [this.universalConnector.getPatronProfile(patronId), this.getPlayerBalance(patronId)];
        try {
            const [profileResponse, balanceResponse]: [PromiseSettledResult<any>, PromiseSettledResult<any>] = await Promise.allSettled(promises) as any;
            this.handleResult([profileResponse, balanceResponse], patronId, connectorUrl);
        } catch (error) {
            this.logsService.writeLog(JSON.stringify(`
            Get profile from UC: url:${connectorUrl}/api/PlayerProfile/;
            Date:${new Date()};
            PatronID:${patronId};
            Errors:${JSON.stringify(error) ?? 'Getting playeer profile failed'}`));
            this.router.navigate(['/404']);
        }
    };

    public async getPlayerAcitivity(patronId: string) {
        try {
            const activityResult = await (this.universalConnector as UniversalConnectorVersionTwoDirective).getPlayerActivity(patronId) as any;
            if (activityResult?.result?.errors?.length) {
                this.logsService.writeLog(JSON.stringify(`
                Get player activity from UC: /api/Activity/get/;
                Date:${new Date()};
                PatronID:${patronId}; 
                Errors:${JSON.stringify(activityResult?.result?.errors[0]?.message)}`));
                return;
            }
            this.playerActivity = activityResult;
        } catch (error) {
            this.logsService.writeLog(JSON.stringify(`
            Get player activity from UC: /api/Activity/get/;
            Date:${new Date()};
            PatronID:${patronId};
            Errors:${!!error ? JSON.stringify(error) : 'Getting player activity failed'}`));
        }
    };

    public async getPlayerBalancesWinLoss(patronId: string, years: string, deliveryMethod: string): Promise<any> {
        try {
            // Call the method from universalConnector and handle the response
            const winLossResult = await this.universalConnector.getPlayerBalancesWinLoss(patronId, years, deliveryMethod);

            console.log('winLossResult', winLossResult);

            if (winLossResult?.result?.errors?.length) {
                this.logsService.writeLog(JSON.stringify(`
                Get player balances win/loss from UC failed: /api/Balance/winloss/;
                Date:${new Date()};
                PatronID:${patronId}; 
                Errors:${JSON.stringify(winLossResult?.result?.errors[0]?.message)}`));
                return;

            }
            
            this.playerBalancesWinLoss = winLossResult;
            console.log('this.playerBalancesWinLoss', this.playerBalancesWinLoss);

            return winLossResult;

        } catch (error) {
            this.logsService.writeLog(JSON.stringify(`
            Error fetching player balances win/loss from UC: /api/Balance/winloss/;
            Date:${new Date()};
            PatronID:${patronId}; 
            Errors:${!!error ? JSON.stringify(error) : 'Getting player Win/Loss Statement failed'}`));
            return error;
        }
    }


    public getPlayerBalancesWinLoss1(patronId: string, years: string, deliveryMethod: string): Observable<any> {

        return from(this.universalConnector.getPlayerBalancesWinLoss1(patronId, years, deliveryMethod)).pipe(
            map(winLossResult => {

                this.playerBalancesWinLoss = winLossResult;
                console.log('this.playerBalancesWinLoss', this.playerBalancesWinLoss);
                return winLossResult;
            }),
            catchError(error => {
                const errorLog = JSON.stringify(`
                    Error fetching player balances win/loss from UC: /api/Balance/winloss/;
                    Date:${new Date()};
                    PatronID:${patronId}; 
                    Errors:${!!error ? JSON.stringify(error) : 'Getting player Win/Loss Statement failed'}`);
                this.logsService.writeLog(errorLog);
                throw error;
            })
        );
    }

    public async getPlayerBalancesWinLossYears(patronId: string): Promise<any> {
        try {
           
            const yearsResult = await this.universalConnector.getPlayerBalancesWinLossYears(patronId);

            if (yearsResult?.result?.errors?.length) {
                this.logsService.writeLog(JSON.stringify(`
                Get player balances win/loss years from UC failed: /api/Balance/winlossyears;
                Date:${new Date()};
                PatronID:${patronId}; 
                Errors:${JSON.stringify(yearsResult?.result?.errors[0]?.message)}`));
                return;
            }
            return yearsResult;
        } catch (error) {
            this.logsService.writeLog(JSON.stringify(`
            Error fetching player balances win/loss years from UC: /api/Balance/winloss/;
            Date:${new Date()};
            PatronID:${patronId}; 
            Errors:${!!error ? JSON.stringify(error) : 'Getting player Win/Loss Statement failed'}`));

        }
    }

    public handleResult([profileResponse, balanceResponse]: [any, any], patronId: string, connectorUrl: string) {

        if (!this.isUcSecondVersion) {
            if (profileResponse.value?.errorDetails || !profileResponse.value?.result) {
                this.logsService.writeLog(`
                Get profile from UC: url:${connectorUrl}/api/PlayerProfile/;
                Date:${new Date()};
                PatronID:${patronId};
                Errors:${(profileResponse.value?.errorDetails || profileResponse.value?.status) ?? 'Getting playeer profile failed'}`);
                this.router.navigate(['/404']);
            }

            let result = {
                profile: profileResponse.value.result,
                balance: balanceResponse.value
            }

            const cardNumbers = result.profile?.cardNumbers.filter((card: any) => card.status === 'A')[0] || null;
            result.profile = {
                ...result.profile,
                freePlayBalance: this.playerBalances?.xtraCreditBalanceGlobal,
                cardNumbers
            }
            this.playerProfile = result.profile;
            return;
        };

        const hasErrorMessages = <T>(result: PromiseFulfilledResult<T>) => {
            const { value }: any = result;
            return (!value?.playerId && !value?.result?.playerId) || value?.result?.errors?.lenght || value?.message?.result
        }

        if (hasErrorMessages(profileResponse)) {
            throw new Error(`${profileResponse?.value?.result?.message} ${profileResponse?.value?.result?.errors?.length ? JSON.stringify(profileResponse?.value?.result?.errors) : ''}`);
        }

        if (profileResponse.status === 'rejected' || balanceResponse.status === 'rejected') {
            this.logsService.writeLog(`
            Get profile from UC: url:${connectorUrl}/api/PlayerProfile/;
            Date:${new Date()};
            PatronID:${patronId};
            Errors:${(profileResponse.value?.errorDetails || profileResponse.value?.status) ?? 'Getting playeer profile failed'}`);
            this.router.navigate(['/404']);
        };

        this.getPlayerAcitivity(patronId);
        let result = {
            profile: profileResponse.value,
            balance: balanceResponse.value
        };
        this.playerProfile = result.profile;
        this.updateProfile$.next(result.profile);
    }

    public async getPlayerBalance(playerId: string) {
        if (this.ucVersion === UcVersion.Version_2) {
            const result = await (this.universalConnector).getPlayerBalances(playerId);
            this.playerBalances = result;
            return result;
        }
        const { result, status, errorDetails } = await this.universalConnector.getPlayerBalances(playerId);
        if (result) {
            if (this.playerProfile?.gamingInfo && this.playerProfile.gamingInfo.PlayerAccountBalance) {
                this.playerProfile.gamingInfo.PlayerAccountBalance = result.pointBalance;
            }
            this.playerBalances = result;
            this.playerProfile.freePlayBalance = result.xtraCreditBalanceGlobal;
        }
        return result;
    }

    public async updateProfile(updateProfile: any, viewModalType?: string): Promise<Observable<boolean>> {
        await this.processProfileUpdate(updateProfile, viewModalType ?? '');
        return of(true);
    }

    private async processProfileUpdate(updateProfile: any, viewModalType: string) {
        let arrayToUpdate: any;
        let queryType: string = '';
        let promises: any[] = [];
        let changedIndexes: number[] = [];
        const self = this;
        const profile = updateProfile?.clonedProfile ?? updateProfile;
        if (!viewModalType && !profile) {
            return;
        }
        const generatePromises = (arrayPropertyName: string) => {
            arrayToUpdate = profile[arrayPropertyName];
            changedIndexes = this.findChangedObjectsIndex(arrayToUpdate, this.playerProfile[arrayPropertyName]);
            promises = changedIndexes.map((index: number) => {
                if (this.isUcSecondVersion) {
                    return {
                        promise: this.universalConnector.updatePlayerProfile(this.playerProfile.playerId, queryType, arrayToUpdate[index]),
                        chnagedIndex: index
                    }
                } else {
                    return updateFirstVersion(queryType, index);
                }
            });
        }

        const updateFirstVersion = (type: string, index: number) => {
            switch (type) {
                case 'email':
                    return {
                        promise: this.universalConnector.updatePlayerProfile(this.playerProfile.playerId, arrayToUpdate[index], null, null),
                        chnagedIndex: index
                    }
                case 'phone':
                    return {
                        promise: this.universalConnector.updatePlayerProfile(this.playerProfile.playerId, null, null, arrayToUpdate[index]),
                        chnagedIndex: index
                    }
                case 'address':
                    return {
                        promise: this.universalConnector.updatePlayerProfile(this.playerProfile.playerId, null, arrayToUpdate[index], null),
                        chnagedIndex: index
                    }
            }
        }

        const handleResponse = async (arrayPropertyName: string) => {
            if (promises.length) {
                const promisesArr = promises.map(v => v.promise)
                const res: PromiseSettledResult<any>[] = await Promise.allSettled(promisesArr);
                res.forEach((result, index) => {
                    if (result.status === "fulfilled" && !result.value?.errors?.length) {
                        const indexToUpdate = promises[index].chnagedIndex;
                        self.playerProfile[arrayPropertyName][indexToUpdate] = profile[arrayPropertyName][indexToUpdate];
                    } else if (result.status === "fulfilled" && result.value?.errors.length) {
                        result.value.errors.forEach(((er: any) => {
                            this.snackBar.openFromComponent(SnackbarComponent, {
                                panelClass: 'error',
                                duration: 5000,
                                horizontalPosition: 'end',
                                data: {
                                    type: 'error',
                                    message: er.message ?? 'Update rejected',
                                },
                            });
                            this.logsService.writeLog(JSON.stringify(
                                `Update profile throw UC: endpoint: /api/Player/${queryType}/put/;Date:${new Date()};PatronID:${profile.playerId};Errors:${JSON.stringify(er)}`
                            ));
                        }))
                    } else if (result.status === 'rejected') {
                        throw new Error("Update profile failed");
                    }
                });
            }
            if (this.isUcSecondVersion) {
                this.playerProfile = await this.universalConnector.getPatronProfile(this.playerProfile.playerId);
            }
        };
        const updateProfileType = this.isUcSecondVersion ? UpdateProfileTypes : UpdateProfileVersionOneTypes;

        switch (viewModalType) {
            case 'email':
                queryType = 'email';
                arrayToUpdate = profile[updateProfileType.EMAIL];
                changedIndexes = this.findChangedObjectsIndex(arrayToUpdate, this.playerProfile[updateProfileType.EMAIL]);
                generatePromises(updateProfileType.EMAIL);
                await handleResponse(updateProfileType.EMAIL);
                break;
            case 'phoneNumber':
                queryType = 'phone';
                arrayToUpdate = profile[updateProfileType.PHONE];
                changedIndexes = this.findChangedObjectsIndex(arrayToUpdate, this.playerProfile[updateProfileType.PHONE]);
                generatePromises(updateProfileType.PHONE);
                await handleResponse(updateProfileType.PHONE);
                break;
            case 'preferredAddress':
                queryType = 'address';
                arrayToUpdate = profile[updateProfileType.ADDRES];
                changedIndexes = this.findChangedObjectsIndex(arrayToUpdate, this.playerProfile[updateProfileType.ADDRES]);
                generatePromises(updateProfileType.ADDRES);
                await handleResponse(updateProfileType.ADDRES);
                break;
        }

    }

    private findChangedObjectsIndex(arr1: any, arr2: any) {
        return arr1.reduce((changedIndexes: any[], obj1: any, index: string | number) => {
            const obj2 = arr2[index];
            if (!this.compareObjects(obj1, obj2)) {
                changedIndexes.push(index);
            }
            return changedIndexes;
        }, []);
    }

    private compareObjects(obj1: any, obj2: any) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        };

        return keys1.every((key) => obj1[key] === obj2[key]);
    };

    public fallBackDataPointValues() {
        if (this.isUcSecondVersion) {
            const { playerProfile } = this.playerProfile;
            return {
                playerName: `${playerProfile.firstName} ${playerProfile.lastName}` ?? `${playerProfile.fullName}`,
                pointBalance: this.playerBalances?.a_Points?.amount ?? 0,
                tierLevelName: this.playerProfile?.club?.currentClub?.name ?? '',
                birthDate: playerProfile.dateOfBirthday
            }
        };
        return {
            playerName: `${this.playerProfile.name.first} ${this.playerProfile.name.last}`,
            pointBalance: this.playerProfile.gamingInfo.PlayerAccountBalance ?? 0,
            tierLevelName: this.playerProfile?.gamingInfo?.tierLevel?.name ?? '',
            birthDate: this.playerProfile.dateOfBirthday

        }
    };

    public getBirthDate() {
        const today = new Date();
        const birtdDate = new Date(this.fallBackDataPointValues().birthDate);
        const isBirthDate = today.getMonth() === birtdDate.getMonth() && today.getDate() === birtdDate.getDate();
        return isBirthDate;
    };

    public getIsBirthdayMonth() {
        const today = new Date();
        const birtdDate = new Date(this.fallBackDataPointValues().birthDate);
        return today.getMonth() === birtdDate.getMonth();
    }

    public getDaysFromEnrollment(enrollmentDate: string) {
        var startDate = new Date(enrollmentDate);
        var currentDate = new Date();
        var timeDifference = currentDate.getTime() - startDate.getTime();
        var daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        return daysDifference;
    }

}