import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { pgIconModule } from '@core/pages-ui.module/components/icon/menu-icon.module';
import { KioskInputDirective } from './kiosk-input.directive';
import { KeyboardDirective } from './keyboard.directive';
import { KeyboardService } from './keyboard.service';
import { KeyboardComponent } from './keyboard.component';
import { KeyboardStyleDirective } from './keyboard-style.directive';

@NgModule({
  declarations: [KeyboardComponent, KioskInputDirective, KeyboardDirective,KeyboardStyleDirective],
  entryComponents: [KeyboardComponent],
  exports: [KeyboardComponent, KioskInputDirective,KeyboardStyleDirective],
  providers: [KeyboardService],
  imports: [
    CommonModule,
    // pgIconModule
  ],
})
export class KeyboardModule {}
