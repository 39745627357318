import { Component, Injector, OnInit } from '@angular/core';
import { ViewModalComponent } from 'projects/kiosk-app/src/app/components';

@Component({
  selector: 'app-view-addresses',
  templateUrl: './view-addresses.component.html',
  styleUrls: ['./view-addresses.component.scss']
})
export class ViewAddressesComponent extends ViewModalComponent implements OnInit {
  public addresses: any[];

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.currentViewModalType = 'address';
    this.addresses = this.cloneProfile.addresses;
  }

  override updateFields(): void {
    this.cloneProfile.addresses = this.addresses;
  }

  public setOptOutValue(address: any) {
    if (!JSON.parse(address.mailTo)) {
      return;
    }
    address.mailTo = 'false';
  }
}
