import { TabType } from "./template";

export interface RedemptionTemplateModel {
    templateName: TabType;
    sectionTitle: string;
    isFacialValidationRequired: boolean;
    isPinRequired: boolean;
    templateConfig: RedemptionTemplateConfigModel,
    configurations: RedemptionsConfigModel
}

export enum RedemptionButtonState {
    Print = 'Print',
    Redeem = 'Redeem',
    Issue = 'Issue',
    None = 'None',
}

export interface RedemptionTemplateConfigModel {
    buttonsOption: ButtonOptions;
    conversionRate: 'tiered' | 'all';
    conversionType: ConversionType;
    rateFrom: number;
    rateTo: number;
    conversionTypeTitle: string;
    isPrintVoucherRequired: boolean;
    preSets: Array<{ [k: string]: string }>;
    sourceBucket: string;
    sourceBucketTitle: string;
    tiredConversionRate: Array<TierLevels>;
}

export interface RedemptionsConfigModel {
    main: {},
    buttons: {},
    freeEntry: {},
    preSets: {},
    popUp: {},
    dataPoints: {}
}

export interface TierLevels {
    guid:string;
    tierLevel: string;
    rateFrom: number;
    rateTo: number
}

export enum ButtonOptions {
    tenKeyEntry = "tenKeyEntry",
    preSets = "preSets"
}

export enum ConversionType {
    freePlay = 'freePlay',
    cash = 'cash',
    dining = 'dining',
    bevarage = 'bevarage',
    raceAndSports = 'raceAndSports'
}

