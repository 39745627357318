import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StyleService, TemplateService } from '../../services';
import { MainTemplate } from '../../models';
import { take } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  public mainTemplate: MainTemplate;

  constructor(
    private readonly dialogRef: MatDialogRef<any>,
    public readonly styleService: StyleService,
    private readonly templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.mainTemplate = template;
      });
  }

  ngAfterViewInit(): void {
    console.log(this.data)
    if (!this.data.tabData.configurations?.popUp) {
      return;
    }
    const popupStyles = this.data.tabData.configurations.popUp;
    let backDropStyles: CSSStyleDeclaration = (document.querySelectorAll('.backDropClass')[0] as any)?.style;
    backDropStyles.background = popupStyles.overlay || '#00000052';
    const dialogSurface = document.querySelectorAll('.mat-mdc-dialog-surface')[1] as HTMLElement;
    let contentStyles: CSSStyleDeclaration = (dialogSurface?.getElementsByClassName('content')[0] as HTMLElement).style;
    dialogSurface.style.setProperty('border-radius', `${popupStyles.border.borderRadius}px`, 'important');
    (dialogSurface as HTMLElement).style.borderColor = popupStyles.border.borderColor;
    (dialogSurface as HTMLElement).style.borderWidth = popupStyles.border.borderWidth + 'px';
    contentStyles.background = popupStyles.background.color;
    contentStyles.setProperty('background-image', `url(${popupStyles.background.imageLink})`, 'important');
  }

  public getConfirmMessage() {
    const tmplConfig = this.data.tabData.templateConfig;
    switch (this.data.modalName) {
      case 'phoneNumber':
        return tmplConfig?.phoneConfirmationText || 'Are you sure you want to change the data?';
      case 'email':
        return tmplConfig?.emailConfirmationText || 'Are you sure you want to change the data?';
      default:
        return 'Are you sure you want to change the data?';
    }
  }

  public closeModal(isConfirm: boolean): void {
    this.dialogRef.close(isConfirm);
  }
}
