<div
  class="content"
  [ngStyle]="
    styleService.getStyleRule(undefined, [
      mainTemplate.globalVariables.backgroundPrimary
    ])
  "
>
  <div class="header">
    <div class="header-block">
      <h1 class="header-title">
        Pin Validation
      </h1>
    </div>
  </div>

  <div class="main">
    <div class="row">
      <div class="col-12">
        <label class="label">Pin code</label>
        <input type="text" class="input" [(ngModel)]="pinCode" placeholder="Enter pin code" kioskInput />
      </div>
    </div>
    <kiosk-keyboard [isAlwaysShown]="true"></kiosk-keyboard>
  </div>


  <div class="footer">
    <button (click)="checkPin()">Confirm</button>
  </div>
</div>
