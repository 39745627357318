import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { take } from "rxjs";
import { environment } from '../../environments/environment';
import { Token } from "../models";

@Injectable({
    providedIn: 'root',
})
export class LogService {
    constructor(private readonly http: HttpClient) { }

    public writeLog(message: string): void {
        if (!this.kioskData?.access_token) return;

        const url = `${environment.apiUrl}/api/KioskLog`

        this.http.post<null>(url, {
            message
        }).pipe(take(1)).subscribe(() => {});
    }

    private get kioskData(): Token | null {
        const kioskData = localStorage.getItem('kioskData');
    
        if (kioskData) {
          return JSON.parse(kioskData);
        }
    
        return null;
      }
}