import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  Background,
  Border,
  Font,
  GlobalVariables
} from '../models';
import { TemplateService } from './template.service';
import {
  LocalResource,
  ModalWindowConfigurtationInterface,
  Snackbar,
  Snackbars,
} from '../models/template';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class StyleService {
  private renderer: Renderer2;
  private globalVariables: GlobalVariables = {} as GlobalVariables;

  public get isQt3Kiosk(): boolean {
    return window.innerWidth <= 1200;
  }

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private readonly templateService: TemplateService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.templateService.templateData$().subscribe((template) => {
      this.globalVariables = template?.globalVariables;
    });
  }

  public getStyleInput(
    bg?: any,
    border?: any,
    mainConfig?: any,
    text?: any,
    subpage?: any
  ): any {
    let cssRules: any = {};
  }

  public getStyleInputError(font?: any): any {
    return {};
  }

  public setHeaders(localResource: LocalResource): void {
    const isEnables = localResource?.isEnabled;
    let links = [
      { rel: 'stylesheet', type: 'googleIcon', font: 'materialIcons', href: 'https://fonts.googleapis.com/icon?family=Material+Icons' },
      { rel: 'stylesheet', type: 'googleFont', font: 'lato', href: 'Lato' },
      { rel: 'stylesheet', type: 'googleFont', font: 'merriweather', href: 'Merriweather' },
      { rel: 'stylesheet', type: 'googleFont', font: 'marcellus', href: 'Marcellus' },
      { rel: 'stylesheet', type: 'googleFont', font: 'poppins', href: 'Poppins' },
      { rel: 'stylesheet', type: 'googleFont', font: 'montserrat', href: 'Montserrat' },
      { rel: 'stylesheet', type: 'googleFont', font: 'sourceSansPro', href: 'Source+Sans+Pro' },
      { rel: 'stylesheet', type: 'googleFont', font: 'bebasNeue', href: 'Bebas+Neue' },
      { rel: 'stylesheet', type: 'googleFont', font: 'roboto', href: 'Roboto' },
      { rel: 'stylesheet', type: 'googleFont', font: 'openSans', href: 'Open+Sans' },
      { rel: 'stylesheet', font: 'proximaNova2', href: 'https://fonts.cdnfonts.com/css/proxima-nova-2' },
      { rel: 'stylesheet', font: 'garamond', href: 'https://fonts.cdnfonts.com/css/garamond' },
      { rel: 'stylesheet', font: 'avenir', href: 'https://fonts.cdnfonts.com/css/avenir' },
      { rel: 'stylesheet', font: 'graphik', href: 'https://fonts.cdnfonts.com/css/graphik' },
      { rel: 'stylesheet', font: 'campton', href: 'https://fonts.cdnfonts.com/css/campton' },
      { rel: 'stylesheet', font: 'dinCondensed', href: 'https://fonts.cdnfonts.com/css/din-condensed' },
      { rel: 'stylesheet', font: 'playfairDisplay', href: 'https://fonts.cdnfonts.com/css/playfair-display' }
    ];
    let googleFonts = 'https://fonts.googleapis.com/css2?';
    const newLinks: any[] = [];

    links.forEach((link) => {
      const key = link.font;
      const srcFont = localResource?.fonts?.[key];

      if (srcFont?.length) {
        link.href = srcFont;
        newLinks.push(link);
      } else if (link.type === 'googleFont') {
        googleFonts += `family=${link.href}&`;
      } else {
        newLinks.push(link);
      }
    });

    if (googleFonts !== 'https://fonts.googleapis.com/css2?' || newLinks.some(link => link.type === 'googleIcon')) {
      if (googleFonts !== 'https://fonts.googleapis.com/css2?') {
        newLinks.unshift({
          rel: 'stylesheet',
          href: `${googleFonts}display=swap`
        });
      }

      newLinks.unshift({
        rel: 'preconnect',
        href: 'https://fonts.gstatic.com'
      });
    }  

    newLinks.forEach(linkData => {
      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', linkData.rel);
      this.renderer.setAttribute(link, 'href', linkData.href);
      this.renderer.appendChild(this.document.head, link);
    });
  }

  public getStyleRule(font?: any, bg?: any, border?: any, shadow?: any): any {
    let cssRules: any = {};

    if (font) {
      const color = font[0]?.fontColor
        ? font[0]?.fontColor
        : font[1]?.fontColor;
      const family = font[0]?.fontFamily
        ? font[0]?.fontFamily
        : font[1]?.fontFamily?.length
          ? font[1]?.fontFamily
          : this.globalVariables?.textSettings?.fontFamily;
      const weidget = font[0]?.fontWeight
        ? font[0]?.fontWeight
        : !!font[1]?.fontWeight
          ? font[1]?.fontWeight
          : (this.globalVariables?.textSettings as any)?.[font?.[2]]?.fontWeight;
      const size = !!font[0]?.fontSize
        ? font[0]?.fontSize
        : !!font[1]?.fontSize
          ? font[1]?.fontSize
          : (this.globalVariables?.textSettings as any)?.[font?.[2]]?.fontSize;

      if (color && color.length) cssRules.color = color;
      if (family && family.length) cssRules['font-family'] = family;
      if (weidget && weidget.length) cssRules['font-weight'] = weidget;
      if (size) cssRules['font-size'] = size + 'px';
    }

    if (bg) {
      const bColor = bg[0]?.color?.length
        ? bg[0].color
        : bg[1]?.color?.length
          ? bg[1].color
          : bg[2]?.color;
      const bImg = bg[0]?.imageLink?.length
        ? bg[0].imageLink
        : bg[1]?.imageLink?.length
          ? bg[1].imageLink
          : bg[2]?.imageLink;

      if (bColor && bColor.length) cssRules['background-color'] = bColor;
      if (bImg && bImg.length) cssRules['background-image'] = `url('${bImg}')`;
    }

    if (border) {
      const colorName =  !!border[0]?.borderColor ? 'borderColor' : !!border[1]?.borderColor ? 'borderColor' :
       !!border[2]?.borderColor ? 'borderColor' : 'color';
      const bColor = border[0]?.[colorName]?.length
        ? border[0]?.[colorName]
        : border[1]?.[colorName]?.length
          ? border[1]?.[colorName]
          : border[2]?.[colorName];
      const bWidth = border[0]?.borderWidth !== undefined && border[0]?.borderWidth !== null
        ? border[0].borderWidth
        : border[1]?.borderWidth !== undefined && border[1]?.borderWidth !== null
          ? border[1].borderWidth
          : border[2]?.borderWidth;
      const bRadius = !!border[0]?.borderRadius
        ? border[0].borderRadius
        : !!border[1]?.borderRadius
          ? border[1].borderRadius
          : border[2]?.borderRadius;

      if (bColor && bColor.length) cssRules['border-color'] = bColor;
      if (bWidth !== undefined && bWidth !== null) cssRules['border-width'] = bWidth + 'px';
      if (bRadius && bRadius !== null)
        cssRules['border-radius'] = bRadius + 'px';
      
    }

    if (shadow) {
      cssRules[
        'box-shadow'
      ] = `${shadow[0].xPosition}px ${shadow[0].yPosition}px ${shadow[0].blurPosition}px ${shadow[0].color}`;
    }

    return cssRules;
  }

  public applyModalWindowStyles(config: ModalWindowConfigurtationInterface) {
    if (!config) return;

    const body = document.getElementsByTagName('body')[0];
    const dialogStyle = document.createElement('style');
    dialogStyle.type = 'text/css';
    dialogStyle.appendChild(
      document.createTextNode(this.createModalCss(config))
    );
    body.appendChild(dialogStyle);
  }

  public getCloseButtonStyles(config: any) {
    if (!config) {
      return {};
    };
    const buttonPadding = this.isQt3Kiosk ? 12.5 : 25;
    const { background, border, text } = config;
    return {
      'background-image': `url(${background.imageLink || ''})`,
      'background-color': `${background?.color || ''}`,
      'border-radius': `${border.borderRadius}px`,
      'border-color': `${border.borderColor}`,
      'border-width': `${border.borderWidth}px`,
      'font-size': `${text.fontSize}px`,
      'color': `${text.fontColor}`,
      'width': `${!!text.fontSize ? (parseFloat(text.fontSize) + parseFloat(buttonPadding.toString()) + 'px') : ''}`,
      'height': `${!!text.fontSize ? (parseFloat(text.fontSize) + parseFloat(buttonPadding.toString()) + 'px') : ''}`,
    };
  }

  private createModalCss(config: ModalWindowConfigurtationInterface) {
    const backgroundColorProp = () => {
      return config?.background?.color ? `.content{background-color:${config?.background?.color};` : '';
    };
    const backgroundImageProp = () => {
      return config?.background?.imageLink ? `.content{background-image:url(${config?.background?.color}) !important;` : '';
    };
    return `.mat-mdc-dialog-surface{
        ${config.border?.radius
        ? `border-radius: ${config.border.radius}px ${config.border.radius}px 0px 0px !important;`
        : ''
      }
        ${config.border?.color ? `border-color:${config?.border?.color};` : ''}
        ${config.border?.width ? `border-width:${config?.border?.width}px;` : ''}
        border-style: solid;
          ${backgroundColorProp()}
          ${backgroundImageProp()}
      }
      ${config?.overlayBackgroundColor ? `.backDropClass{background-color:'${config?.overlayBackgroundColor};}` : ''}
      `;
  }

  public getUppercaseValue(formConfig: any, componentConfig?: any) {
    return (
      !!formConfig?.isUpperCaseEnabled || !!componentConfig?.isUpperCaseEnabled
    );
  }

  public dividePixelStylesByTwo(styleSheet: string) {
    let regex = /(\b\d+(?:\.\d+)?)(px)\b/g;
    let adaptedStylesheet = styleSheet.replace(regex, this.replaceCallback);
    return adaptedStylesheet;
  }

  private replaceCallback(match: any, value: any, unit: any) {
    var numericValue = parseFloat(value);

    if (unit === 'px') {
      numericValue /= 2;
    }

    return `${numericValue}${unit}`;
  }

  public getIconStyleRule(size?: any, color?: any): any {
    const cssRules: any = {};

    if (size) {
      const selectSize = size[0]?.length ? size[0] : size[1];
      switch (selectSize) {
        case '48*48':
          cssRules['transform'] = this.isQt3Kiosk ? 'scale(1)' : 'scale(2)';
          break;
        case '72*72':
          cssRules['transform'] = this.isQt3Kiosk ? 'scale(1.5)' : 'scale(3)';
          break;
        case '96*96':
          cssRules['transform'] = this.isQt3Kiosk ? 'scale(2)' : 'scale(4)';
          break;
      }
    }

    if (color) {
      const selectedColor = color[0]?.length ? color[0] : color[1];
      if (selectedColor?.length) {
        cssRules['color'] = selectedColor;
      }
    }

    return cssRules;
  }

  public getTabsStyles(subpages: any, componentConfig?: any): any {
    const style = document.createElement('style');
    style.type = 'text/css';
    const unselectedTabs = subpages?.unselected;
    const selectedTabs = subpages?.selected;
    const componentUselectedTabs = componentConfig?.unselected;
    const componentSelectedTabs = componentConfig?.selected;
    const subpageFontFamily =
      unselectedTabs?.text?.fontFamily ||
        componentUselectedTabs?.text?.fontFamily
        ? `font-family: ${unselectedTabs.text.fontFamily ||
        componentUselectedTabs.text.fontFamily
        };`
        : '';
    const subpageFontSize =
      unselectedTabs?.text?.fontSize || componentUselectedTabs?.text?.fontSize
        ? `font-size: ${unselectedTabs.text.fontSize ||
        componentUselectedTabs?.text?.fontSize
        }px;`
        : '';
    const subpageFontWeight =
      unselectedTabs?.text?.fontWeight ||
        componentUselectedTabs?.text?.fontWeight
        ? `font-weight: ${unselectedTabs.text.fontWeight ||
        componentUselectedTabs?.text?.fontWeight
        };`
        : '';
    const subpageFontColor =
      unselectedTabs?.text?.fontColor || componentUselectedTabs?.text?.fontColor
        ? `color: ${unselectedTabs.text.fontColor ||
        componentUselectedTabs?.text?.fontColor
        } !important; `
        : '';
    const subpageFontUppercase =
      !!unselectedTabs?.text?.isUpperCaseEnabled ||
        !!componentUselectedTabs?.text?.isUpperCaseEnabled
        ? `text-transform: uppercase;`
        : '';

    const subpageBorderColor =
      unselectedTabs?.border?.color || componentUselectedTabs?.border?.color
        ? `border-color: ${unselectedTabs.border.color || componentUselectedTabs?.border?.color
        };`
        : '';
    const subpageBorderRadius =
      unselectedTabs?.border?.borderRadius ||
        componentUselectedTabs?.border?.borderRadius
        ? `border-radius: ${unselectedTabs.border.borderRadius ||
        componentUselectedTabs?.border?.borderRadius
        }px;`
        : '';
    const subpageBorderSize =
      unselectedTabs?.border?.borderWidth ||
        componentUselectedTabs?.border?.borderWidth
        ? `border-width: ${unselectedTabs.border.borderWidth ||
        componentUselectedTabs?.border?.borderWidth
        }px;`
        : '';

    const subpageSelectedFontFamily =
      selectedTabs?.text?.fontFamily || componentSelectedTabs?.text?.fontFamily
        ? `font-family: ${selectedTabs.text.fontFamily ||
        componentSelectedTabs?.text?.fontFamily
        };`
        : '';
    const subpageSelectedFontSize =
      selectedTabs?.text?.fontSize || componentSelectedTabs?.text?.fontSize
        ? `font-size: ${selectedTabs.text.fontSize || componentSelectedTabs?.text?.fontSize
        }px;`
        : '';
    const subpageSelectedFontWeight =
      selectedTabs?.text?.fontWeight || componentSelectedTabs?.text?.fontWeight
        ? `font-weight: ${selectedTabs.text.fontWeight ||
        componentSelectedTabs?.text?.fontWeight
        };`
        : '';
    const subpageSelectedFontColor =
      selectedTabs?.text?.fontColor || componentSelectedTabs?.text?.fontColor
        ? `color: ${selectedTabs.text.fontColor ||
        componentSelectedTabs?.text?.fontColor
        } !important;`
        : '';
    const subpageSelectedFontUppercase =
      !!selectedTabs?.text?.isUpperCaseEnabled ||
        !!componentSelectedTabs?.text?.isUpperCaseEnabled
        ? `text-transform: uppercase;`
        : '';

    const subpageSelectedBorderColor =
      selectedTabs?.border?.color || componentSelectedTabs?.border?.color
        ? `border-color: ${selectedTabs.border.color || componentSelectedTabs?.border?.color
        };`
        : '';
    const subpageSelectedBorderRadius =
      (!!selectedTabs?.border?.borderRadius ||
        !!componentSelectedTabs?.border?.borderRadius)
        ? `border-radius: ${selectedTabs?.border?.borderRadius ||
        componentSelectedTabs?.border?.borderRadius
        }px;`
        : '';
    const subpageSelectedBorderSize =
      selectedTabs?.border?.borderWidth || componentSelectedTabs?.border?.borderWidth
        ? `border-width: ${selectedTabs.border.borderWidth ||
        componentSelectedTabs?.border?.borderWidth
        }px;`
        : '';

    const inlineStyles = `
      app-template .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
        ${this.getInlineBackgroundStyle(
      this.getActualBackground(
        componentUselectedTabs?.background,
        unselectedTabs?.background
      )
    )}
        ${subpageBorderColor}
        ${subpageBorderRadius}
        ${subpageBorderSize}
      }

      app-template .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
        ${subpageFontFamily}
        ${subpageFontSize}
        ${subpageFontWeight}
        ${subpageFontColor}
        ${subpageFontUppercase}
      }

      app-template .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab.mdc-tab--active.mdc-tab-indicator--active {
        ${this.getInlineBackgroundStyle(
      this.getActualBackground(
        componentSelectedTabs?.background,
        selectedTabs?.background
      )
    )}
        ${subpageSelectedBorderColor}
        ${subpageSelectedBorderRadius}
        ${subpageSelectedBorderSize}
      }

      app-template .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab.mdc-tab--active.mdc-tab-indicator--active .mdc-tab__text-label {
        ${subpageSelectedFontFamily}
        ${subpageSelectedFontSize}
        ${subpageSelectedFontWeight}
        ${subpageSelectedFontColor}
        ${subpageSelectedFontUppercase}
      }
    `;

    style.appendChild(document.createTextNode(inlineStyles));

    return style;
  }

  private getActualBackground(
    componentsBackground: Background,
    pageLinkBackground: Background
  ) {
    return {
      color: pageLinkBackground?.color || componentsBackground?.color,
      imageLink:
        pageLinkBackground?.imageLink || componentsBackground?.imageLink,
      imageName:
        pageLinkBackground?.imageName || componentsBackground?.imageName,
    };
  }

  public getSnackBarStyles(snackbars: Snackbars): any {
    if (!snackbars) return;

    const body = document.getElementsByTagName('body')[0];
    const style = document.createElement('style');
    style.type = 'text/css';

    const snackBarKeys = ['info', 'success', 'warning', 'error'];

    let inlineStyles = `
    .cdk-overlay-container .mat-mdc-snack-bar-container {
      margin: 240px 60px 40px 0 !important;
      left: 0;
      position: absolute;
      top: calc(50%);
    }
    @media (max-width: 1200px) {
      .cdk-overlay-container .mat-mdc-snack-bar-container {
        margin: 120px 30px 20px 0 !important;
      }
    }`;
    
    switch (snackbars.snackbarPosition) {
      case 'right-top':
        const topPosition = this.isQt3Kiosk ? '20px' : '40px';
        inlineStyles += `.cdk-overlay-container .mat-mdc-snack-bar-container {top: calc(-100% + ${topPosition}); left: auto;}`;
        break;
      case 'right-bottom':
        inlineStyles += '.cdk-overlay-container .mat-mdc-snack-bar-container {position: static}';
        break;
      default: 
        break;
    }

    snackBarKeys.forEach((key) => {
      inlineStyles += `
      .mdc-snackbar.${key} .mdc-snackbar__surface {
        ${this.getInlineBackgroundStyle(
        ((snackbars as any)[key] as Snackbar).background
      )}
        ${this.getInlineBorderStyle(
        ((snackbars as any)[key] as Snackbar).border
      )}
      }
      .mdc-snackbar.${key} .mdc-snackbar__surface .mat-mdc-snack-bar-label {
        ${this.getInlineTextStyle(((snackbars as any)[key] as Snackbar).text)}
      }
      `;
    });

    style.appendChild(document.createTextNode(inlineStyles));
    body.appendChild(style);
  }

  private getInlineBackgroundStyle(background: Background): string {
    let backgroundStyle = '';
    if (!!background?.imageLink || !!background?.color) {
      backgroundStyle = 'background: ';
      if (!!background.imageLink) {
        backgroundStyle += `url(${background.imageLink}) !important;`;
      } else {
        backgroundStyle += `${background.color} !important;`;
      }
    }

    return backgroundStyle;
  }

  private getInlineBorderStyle(border: Border): string {
    let borderStyle = '';

    if (border?.borderColor?.length) {
      borderStyle += `border-color: ${border.borderColor} !important;`;
    }
    if (!!border?.borderRadius) {
      borderStyle += `border-radius: ${border.borderRadius}px !important;`;
    }
    if (!!border?.borderWidth) {
      borderStyle += `border-width: ${border.borderWidth}px !important;`;
    }

    return borderStyle;
  }

  private getInlineTextStyle(text: Font): string {
    let fontStyle = '';

    if (text?.fontFamily?.length) {
      fontStyle += `font-family: ${text.fontFamily} !important;`;
    }
    if (!!text?.fontSize) {
      fontStyle += `font-size: ${text.fontSize}px !important;`;
    }
    if (!!text?.fontWeight) {
      fontStyle += `font-weight: ${text.fontWeight} !important;`;
    }
    if (text?.fontColor?.length) {
      fontStyle += `color: ${text.fontColor} !important;`;
    }
    if (text?.isUpperCaseEnabled) {
      fontStyle += `text-transform: uppercase;`;
    }

    return fontStyle;
  }

  public mapInputStyles(inputConfig: any, param?: any) {
    return {
      inputBox: inputConfig.inputSettings,
      inputText: inputConfig.text,
      inputTitle: inputConfig.title,
    };
  }
}
