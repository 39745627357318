import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, switchMap, tap, throwError } from 'rxjs';
import { AuthService, LogService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService, private readonly logService: LogService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event) => { },
        (error) => {
          if (error.status === 401) {
            this.router.navigate(['/404']);
          }
        }
      )
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const kioskId = this.authService.kioskId;
    const deviceHash = this.authService.deviceHash;
    const patronId = this.authService.externalId;
    const deviceId = this.authService.deviceId;
    if (kioskId?.length && deviceHash?.length && patronId?.length) {
      return this.authService
        .getToken(kioskId, deviceHash, patronId, deviceId)
        .pipe(
          switchMap((newToken) => {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${newToken}`,
              },
            });
            return next.handle(request);
          }),
        );
    } else {
      return throwError('error');
    }
  }
}
