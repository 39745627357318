import { Component, Injector, OnInit } from '@angular/core';
import { MainTemplate, Tier } from '../../models';
import { ProfileService, StyleService, TemplateService } from '../../services';
import { BaseComponent } from '../base/base.component';
import { UcVersion } from '../../models/template';
import { DataPointService } from '../../services/data-point.service';
import { switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent extends BaseComponent implements OnInit {
  public mainTemplate: MainTemplate;
  public points: number = 0;
  public empty = NaN;

  public getValueByDataPoint = ({ dataPoint, suffix, prefix }: any): string =>
    `${prefix ?? ''} ${this.dataPointService.getValueByDataPointProperty(
      dataPoint
    )}${suffix ?? ''}`;

  constructor(
    public readonly styleService: StyleService,
    private readonly templateService: TemplateService,
    private readonly profileService: ProfileService,
    private dataPointService: DataPointService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.templateService
      .templateData$()
      .pipe(
        switchMap((template) => {
          if (template) {
            this.mainTemplate = template;
            this.points = this.mainTemplate.tierLevels?.cmsValue
              ? this.convertToNumber(
                  this.getValueByDataPointProperty(
                    this.mainTemplate.tierLevels.cmsValue
                  )
                )
              : 0;

            if (this.mainTemplate.tierLevels?.layout === 'Info tiers') {
              setTimeout(() => {
                const { activeTierIndex } = this.templateService.getActiveTiers(
                  this.mainTemplate
                );
                const tiersWrapperEl =
                  document.getElementById('scroll-wrapper');
                const activeTierEl = document.getElementById(
                  `tier-index-${activeTierIndex}`
                );

                if (tiersWrapperEl && activeTierEl) {
                  tiersWrapperEl.scrollLeft = activeTierEl.offsetLeft;
                }
              }, 100);
            }
          }

          return this.profileService.updateProfile$;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.points = this.profileService.playerAccountPointBalance;
      });
  }

  public getValueByDataPointProperty(dataPoint: string): string {
    return this.dataPointService.getValueByDataPointProperty(dataPoint);
  }

  private convertToNumber(input: string | number): number {
    if (typeof input === 'number') return input;

    if (typeof input === 'string') {
      const stringWithoutCommas = input.replace(/,/g, '');
      const result = Number(stringWithoutCommas);

      if (!isNaN(result)) {
        return result;
      }
    }

    console.error(`Failed to convert "${input}" to a number`);
    return 0;
  }

  public isActivePoint(index: number): boolean {
    let isActivePoint;

    if (this.mainTemplate.tierLevels.isCmsValueInUse && this.mainTemplate.tierLevels?.progressBarCalculationLogic !== "fromTheBeggining") {

      const activeTierIndex = this.templateService.getActiveTiers(
        this.mainTemplate
      ).activeTierIndex;

      if (
        Number.isNaN(this.mainTemplate.tierLevels.tiers[activeTierIndex].to) &&
        index === activeTierIndex
      ) {
        isActivePoint = true;
      } else {
        isActivePoint = activeTierIndex >= index;
      }
    } else {
      const tier = this.mainTemplate.tierLevels.tiers[index];

      isActivePoint = this.points >= tier.from;
    }

    return isActivePoint;
  }

  public getPointBackground(index: number): string {
    let activePoint = this.isActivePoint(index);
    const filledColor =
      this.mainTemplate.tierLevels?.configurations?.backgroundTiersColor;
    const emptyBackground = this.mainTemplate.tierLevels?.configurations
      ?.backgroundTierProgressColor
      ? {
          color:
            this.mainTemplate.tierLevels?.configurations
              ?.backgroundTierProgressColor,
        }
      : this.mainTemplate.components.tierLevelCard?.configurations?.background;

    return activePoint ? filledColor : emptyBackground?.color;
  }

  public hasData(dataPoint: any): boolean {
    return !!dataPoint;
  }

  public levelWidth(isLong: boolean): number {
    let maxWidth = isLong ? 300 : 150;
    if (this.mainTemplate.tierLevels.isCmsValueInUse && this.mainTemplate.tierLevels?.progressBarCalculationLogic === "fromTheBeggining") {
      return this.calculateTierProgress(maxWidth);
    }

    if (this.mainTemplate.tierLevels.isCmsValueInUse) {
      const { activeTierIndex, activeTier } =
        this.templateService.getActiveTiers(this.mainTemplate);
      const tierLevel = activeTierIndex * maxWidth;

      if (Number.isNaN(activeTier.to)) return tierLevel;

      if (this.points > activeTier.to) {
        return tierLevel + maxWidth;
      } else {
        const additionalLevel =
          ((this.points - activeTier.from) /
            (activeTier.to - activeTier.from)) *
          maxWidth;

        return additionalLevel >= 0 ? tierLevel + additionalLevel : tierLevel;
      }
    }

    return this.calculateTierProgress(maxWidth);
  }

  private calculateTierProgress(maxWidth: number) {
    return this.mainTemplate.tierLevels.tiers.reduce((level, tier) => {
      if (this.points < tier.from || Number.isNaN(tier.to)) return level;

      if (this.points >= tier.to) {
        level += maxWidth;
      } else {
        level += ((this.points - tier.from) / (tier.to - tier.from)) * maxWidth;
      }

      return level;
    }, 0);
  }

  public hasTwoTiers(): boolean {
    return this.mainTemplate.tierLevels.tiers.length > 1;
  }

  public getActiveTiers(): {
    tiers: [Tier, Tier];
    activeTier: Tier;
    activeTierIndex: number;
  } {
    return this.templateService.getActiveTiers(this.mainTemplate);
  }

  public getLoadingPercent(activeTier: Tier): number {
    if (Number.isNaN(activeTier.to)) return 0;

    if (this.points < activeTier.from) {
      return 0;
    } else {
      return (
        (100 * (this.points - activeTier.from)) /
        (activeTier.to - activeTier.from)
      );
    }
  }

  public get getTierStatus(): string {
    let tierLevelName = '';

    if (this.profileService.ucVersion === UcVersion.Version_2) {
      tierLevelName = this.profileService.playerProfile.club.currentClub.name;
    } else {
      tierLevelName =
        this.profileService.playerProfile.gamingInfo.tierLevel.name;
    }

    return tierLevelName;
  }
}
