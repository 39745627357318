<ng-container *ngIf="!isLoading">
  <div
    [ngClass]="{'grayscaled':isOfferRedeemed && redeemedOfferDisplaySettings?.isGrayscaled, 'disabled':!redeemedOfferDisplaySettings?.canOpenDetails}">
    <div class="offers-last__item" (click)="openDetail()" [ngStyle]="
  styleService.getStyleRule(
    undefined,
    [tab.configurations.cardOffer.background],
    [tab.configurations.cardOffer.border, mainTemplate.globalVariables.borders]
  )
">
      <div class="offers-last__item-img-wrapper">
        <img [src]="offer?.extendedDetails?.imageUrl" class="btn-ico" />
        <img *ngIf="isOfferRedeemed && redeemedOfferDisplaySettings?.isWatermarkOverOffer" class="redeemed-image" [src]="tab.configurations.main.redeemedOfferImage.imageLink" alt="">
      </div>
      <div class="offers-last__item-content">
        <h3 class="offers-last__item-name" [ngStyle]="
      styleService.getStyleRule([tab.configurations.cardOffer.title, undefined, 'heading4'])
    ">
          {{
          offer?.extendedDetails?.title
          | conditionalUppercase
          : styleService.getUppercaseValue(tab.configurations.cardOffer.title)
          }}
        </h3>
        <div class="offers-last__item-content-bottom">
          <div class="offers-last__item-line"></div>
          <div class="offers-last__item-btn-details" [ngStyle]="
        styleService.getStyleRule(
          [tab.configurations.cardOffer.buttonOfferDetails.text],
          [tab.configurations.cardOffer.buttonOfferDetails.background],
          [tab.configurations.cardOffer.buttonOfferDetails.border]
        )
      ">
            {{
            tab.configurations.cardOffer.buttonOfferDetailsText ? tab.configurations.cardOffer.buttonOfferDetailsText : "Offer detail"
            | conditionalUppercase
            : styleService.getUppercaseValue(
            tab.configurations.cardOffer.buttonOfferDetails.text
            )
            }}
          </div>
        </div>
      </div>
    </div>
  </div>


</ng-container>