import {
  Component,
  OnInit,
  HostListener,
  HostBinding,
  ElementRef,
  Input,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { KeyboardService } from './keyboard.service';

@Component({
  selector: 'kiosk-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
})
export class KeyboardComponent implements OnInit {
  @HostBinding('class.shown')
  @Input('isAlwaysShown')
  isAlwaysShown: boolean;

  private shown: boolean;

  private keyboardSubscription: Subscription;

  constructor(private el: ElementRef, public keyboard: KeyboardService) {}

  ngOnInit() {
    this.keyboardSubscription = this.keyboard.keyboardRequested.subscribe(
      (show) => {
        if (this.isAlwaysShown) {
          this.shown = true;
        } else if (show) {
          this.shown = true;
        } else {
          this.shown = false;
        }
      }
    );
  }

  ngOnDestroy() {
    this.keyboardSubscription?.unsubscribe();
  }

  onShift() {
    this.keyboard.shift = !this.keyboard.shift;
    this.keyboard.alt = false;
  }

  onAlt() {
    this.keyboard.alt = !this.keyboard.alt;
    this.keyboard.shift = false;
  }

  onBackspace() {
    this.keyboard.fireBackspacePressed();
  }

  onClear() {
    for (let i = 0; i <= 5000; i++) {
      if (i < 5000) {
        this.keyboard.fireBackspacePressed();
      }
      i++;
    }
  }

  onEnter() {
    this.keyboard.fireEnterPressed();
  }

  onSpace() {
    this.keyboard.fireKeyPressed(' ');
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('click', ['$event'])
  onMouseEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
