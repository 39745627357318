import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, catchError, distinctUntilChanged, fromEvent, lastValueFrom, map, switchMap, take, takeUntil, throwError } from 'rxjs';
import {
  AuthService,
  LogService,
  PrintService,
  StyleService,
  TemplateService,
} from './services';
import { Router } from '@angular/router';
import { BaseComponent, SnackbarComponent } from './components';
import { ProfileService } from './services/profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainTemplate } from './models';
import { availableCms } from '../constants';
import { DataPointService } from './services/data-point.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends BaseComponent implements OnInit {
  public isLoaded: boolean = false;
  public isShowLoader: boolean = false;
  public mainTemplate: MainTemplate = {} as MainTemplate;
  public windowResize$: Observable<Event>;
  public initialMainTemplate: string = '';

  constructor(
    public readonly styleService: StyleService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly templateService: TemplateService,
    private readonly profileService: ProfileService,
    private readonly snackBar: MatSnackBar,
    private readonly logService: LogService,
    private dataPointService: DataPointService,
    private printService: PrintService,
    injector: Injector
  ) {
    super(injector);
    this.windowResize$ = fromEvent(window, 'resize');
  }

  private divideWidthAndFontSize(tree: any, divisor: number): void {
    const measureProps = [
      'borderWidth',
      'width',
      'borderRadius',
      'radius',
      'fontSize',
    ];
    for (const key in tree) {
      if (typeof tree[key] === 'object') {
        if (Array.isArray(tree[key])) {
          for (const item of tree[key]) {
            this.divideWidthAndFontSize(item, divisor);
          }
        } else {
          this.divideWidthAndFontSize(tree[key], divisor);
        }
      } else if (measureProps.includes(key)) {
        if (Number(tree[key]) > 0) {
          tree[key] = String(Number(tree[key]) / divisor);
        }
      }
    }
  }

  async ngOnInit(): Promise<void> {
    if (window.location.href.includes('carousel')) {
      this.isLoaded = true;
      return;
    }

    const searchUrl = window.location.search;
    const serachParams = new URLSearchParams(searchUrl);
    let kioskId = serachParams.get('kioskId');
    let deviceHash = serachParams.get('h');
    let patronId = serachParams.get('patronId');
    let deviceId = serachParams.get('kioskDeviceId');
    const _this = this;

    this.windowResize$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        map((event: Event) => event)
        
      )
      .subscribe((event: Event) => {
        if (!_this.initialMainTemplate) return;

        let template = JSON.parse(_this.initialMainTemplate);
        let appendedCssOvverideStyles = document.getElementById('cssOvveride');
        if (!!appendedCssOvverideStyles) {
          appendedCssOvverideStyles?.remove();
        }
        if (_this.styleService.isQt3Kiosk) {
          _this.divideWidthAndFontSize(template, 2);
          _this.templateService.setTemplateData(template);
          _this.addCssOvverideStyles(template.globalVariables.cssOverride);
          template = null;
        } else {
          _this.templateService.setTemplateData(
            JSON.parse(_this.initialMainTemplate)
          );
          _this.addCssOvverideStyles(template.globalVariables.cssOverride);
        }
      });

    if (!kioskId) {
      kioskId = this.authService.kioskId;
    }
    if (!deviceHash) {
      deviceHash = this.authService.deviceHash;
    }
    if (!patronId) {
      patronId = this.authService.externalId;
    }
    if (!deviceId) {
      deviceId = this.authService.deviceId;
    } else {
      this.authService.setDeviceId(deviceId);
    }

    this.authService.removeExitKioskUrl();
    if (!kioskId || !deviceHash || !patronId) {
      this.logService.writeLog('missed values kioskId || deviceHasg || patronId');
      this.router.navigate(['404']);
      this.isLoaded = true;
    } else {
      this.authService.setExternalId(patronId);
      this.authService.setDeviceHash(deviceHash);
      this.authService.setKioskId(kioskId);
      this.authService
        .getToken(kioskId, deviceHash, patronId, deviceId)
        .pipe(
          switchMap((token) => {
            console.log(token);
            return this.templateService.getTemplate().pipe(
              catchError((templateError) => {
                this.isLoaded = true;
                this.logService.writeLog(
                  'Getting template is failed: ' + JSON.stringify(templateError)
                );
                this.snackBar.openFromComponent(SnackbarComponent, {
                  panelClass: 'error',
                  duration: 25000,
                  horizontalPosition: 'end',
                  data: {
                    type: 'error',
                    message: 'Getting template is failed. Please try again',
                  },
                });
                this.router.navigate(['404']);
                return throwError(templateError);
              })
            );
          }),
          take(1)
        )
        .subscribe(
          async (template) => {
            this.isShowLoader = true;
            this.mainTemplate = template;
            this.initialMainTemplate = JSON.stringify(template);
            this.authService.setExitKioskUrl(template.advancedSettings.exitKioskUrl);
            const universalConnectorSettings = template.advancedSettings.universalConnector;
            this.profileService.universalConnectorSettings = universalConnectorSettings;
            const universalConnectorHost = this.getUniversalConectorUrlBasedOnDebug(serachParams, template.advancedSettings);
         
            if (!!template.advancedSettings.debugMode &&
              !!template.advancedSettings?.debugMode?.debugModeEnabled) {
                this.authService.setUCHost(universalConnectorHost);
            }

            this.profileService.ucVersion = universalConnectorSettings.ucVersion;
            this.profileService.cmsName = template.advancedSettings.activeCMS;
            this.templateService.setTemplateData(template);
            localStorage.removeItem('isExecuteProcessing');
            await this.processDatapointConfigurations();
            if (patronId) {
              try {
                await this.profileService.getProfile(
                  universalConnectorHost,
                  patronId
                );
              } catch (getProfileError) {
                this.isLoaded = true;
                const errorMessage = `Getting profile failed. Host: ${universalConnectorHost}api/PlayerProfile/; PatronId: ${patronId} Error: ${JSON.stringify(
                  getProfileError
                )}`;
                this.logService.writeLog(errorMessage);

                this.snackBar.openFromComponent(SnackbarComponent, {
                  panelClass: 'error',
                  duration: 25000,
                  horizontalPosition: 'end',
                  data: {
                    type: 'error',
                    message: 'Getting profile is failed. Please try again',
                  },
                });
                this.router.navigate(['404']);
                return throwError(getProfileError);
              }
            } else {
              this.logService.writeLog('Patron id is undefined');
              throw new Error('Patron id is undefined');
            }
            this.isLoaded = true;
            console.log(template.template.additionalInfo);
            if (this.styleService.isQt3Kiosk) {
              this.divideWidthAndFontSize(template, 2);
            }
    
            console.log(template);
            this.styleService.setHeaders(template.advancedSettings.localResource);
            this.addCssOvverideStyles(template.globalVariables.cssOverride);
            this.styleService.applyModalWindowStyles(
              template.components.modalWindow
            );

            this.styleService.getSnackBarStyles(template.components.snackbars);
            this.templateService.setTemplateData(template);
          },
          (err) => {
            this.logService.writeLog(JSON.stringify(err));
            this.snackBar.openFromComponent(SnackbarComponent, {
              panelClass: 'error',
              duration: 25000,
              horizontalPosition: 'end',
              data: {
                type: 'error',
                message: 'Authentication has failed. Please try again',
              },
            });
            this.router.navigate(['404']);
            this.isLoaded = true;
          }
        );
    }
  };

  private async processDatapointConfigurations() {
    this.dataPointService.dataPointConfigurationId = availableCms.find(value => value.cms === this.profileService.cmsName && value.version === this.profileService.ucVersion)?.value ?? '';
    this.dataPointService.dataPointsList = await lastValueFrom(this.dataPointService.getDataPointsConfiguration());
    this.dataPointService.dataPointsList = this.dataPointService.dataPointsList.filter(dataPoint => dataPoint?.cms?.includes(this.dataPointService.dataPointConfigurationId));
    await this.dataPointService.mapDatapointPaths();
    this.dataPointService.mapOldDatapointsPaths();
  }

  private getUniversalConectorUrlBasedOnDebug(
    searchParams: URLSearchParams,
    advancedSettings: any
  ): string {
    if (
      !advancedSettings.debugMode ||
      !advancedSettings?.debugMode?.debugModeEnabled
    ) {
      return advancedSettings.universalConnector.host;
    }
    const { paramValue, paramName, hostName } = advancedSettings.debugMode;
    const param = searchParams.get(paramName);
    if (param && param == paramValue && hostName) {
      this.printService.isDebugModeEnabled = true;
      this.authService.isDebugEnabled = true;
      return hostName;
    }
    return advancedSettings.universalConnector.host;
  }

  private addCssOvverideStyles(styles: string): void {
    if (!styles?.length) return;

    const body = document.getElementsByTagName('body')[0];
    const style = document.createElement('style');
    style.id = 'cssOvveride';
    style.type = 'text/css';
    style.appendChild(document.createTextNode(styles));
    body.appendChild(style);
  }
}
